import React, { useEffect, useState } from "react";
import { Card, CardBody } from "./../../components/card/card.jsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../config/axios.js";
import { CardHeader } from "react-bootstrap";
import ModalInfo from "../../components/modal/modalInfo.jsx";
import ModalConfirm from "../../components/modal/modalConfirm.jsx";
import ModalEditSelect from "../../components/modal/modalEditSelect.jsx";
import ModalEditDate from "../../components/modal/modalEditDate.jsx";
import ModalEditText from "../../components/modal/modalEditText.jsx";
import ModalEditSoalMc from "../../components/modal/modalEditSoalMc.jsx";
import ModalNewSoalMc from "../../components/modal/modalNewSoalMc.jsx";
import ModalEditNumber from "../../components/modal/modalEditNumber.jsx";
import ModalEditSoalTf from "../../components/modal/modalEditSoalTf.jsx";
import ModalNewSoalTf from "../../components/modal/modalNewSoalTf.jsx";
import ModalNewText from "../../components/modal/modalNewText.jsx";
import ModalNewSoalMatch from "../../components/modal/modalNewSoalMatch.jsx";
import ModalEditSoalMatch from "../../components/modal/modalEditSoalMatch.jsx";
import ModalEditMatch1AnswerChoice from "../../components/modal/modalEditJawabanMatch1.jsx";
import ModalEditMatch1Main from "../../components/modal/modalEditMatch1Main.jsx";
import ModalNewSoalMatch2 from "../../components/modal/modalNewSoalMatch2.jsx";
import ModalEditSoalMatch2 from "../../components/modal/modalEditSoalMatch2.jsx";
import ModalEditImageMatch2 from "../../components/modal/modalEditImageMatch2.jsx";
import config from "../../config/config.js";
import ImageHandler from "./imageHandler.jsx";
import ModalGenerateEvent from "../../components/modal/modalGenerateEvent.jsx";
import ModalLoadingPage from "../../components/modal/loadingPage.jsx";
import ModalEventQr from "../../components/modal/modalEventQr.jsx";

const generalDataPreset = {
  data: [],
};

function QuestionDetail() {
  const param = useParams();
  const [generalData, setGeneralData] = useState(generalDataPreset);
  const [modalConfirmEventDelete, setModalConfirmEventDelete] = useState({ show: false, id: null });
  const [modalConfirmDeleteMc, setModalConfirmDeleteMc] = useState({ show: false, title: "Warning", text: "no text", id: null });
  const [modalConfirmDeleteTf, setModalConfirmDeleteTf] = useState({ show: false, title: "Warning", text: "no text", id: null });
  const [modalConfirmDeleteSoalMatch1, setModalConfirmDeleteSoalMatch1] = useState({ show: false, title: "Warning", text: "Are you sure want to delete this question ?", id: null });
  const [modalConfirmDeleteSoalMatch2, setModalConfirmDeleteSoalMatch2] = useState({ show: false, title: "Warning", text: "Are you sure want to delete this question ?", id: null });
  const [modalConfirmDeleteJawabanMatch1, setModalConfirmDeleteJawabanMatch1] = useState({ show: false, title: "Warning", text: "Are you sure want to delete this question ?", id: null });
  const [modalConfirmDeleteMatch1, setModalConfirmDeleteMatch1] = useState({ show: false, id: null });
  const [modalConfirmDeleteMatch2, setModalConfirmDeleteMatch2] = useState({ show: false, id: null });
  const [modalInfo, setModalInfo] = useState({ show: false, text: "no Message", title: "no title" });
  const [modalEditMain, setModalEditMain] = useState({ show: false, title: "Please Select", text: "blank", data: {}, headerFetch: {} });
  const [modalEditMainDate, setModalEditMainDate] = useState({ show: false, title: "Please Select", text: "blank", data: {}, headerFetch: {} });
  const [modalEditMainText, setModalEditMainText] = useState({ show: false, title: "Please Select", text: "blank", data: {}, headerFetch: {} });
  const [modalEditMainNumber, setModalEditMainNumber] = useState({ show: false, title: "Please Select", text: "blank", data: {}, headerFetch: {} });
  const [modalSoalMc, setModalSoalMc] = useState({ show: false, id: null });
  const [modalNewSoalMc, setModalNewSoalMc] = useState({ show: false, id: null });
  const [modalEditSoalTf, setModalEditSoalTf] = useState({ show: false, id: null });
  const [modalEditSoalMatch1, setModalEditSoalMatch1] = useState({ show: false, title: "New Multiple Choice Question", text: "Please create new multiple choice question", id: null, idMainMatch: null });
  const [modalEditSoalMatch2, setModalEditSoalMatch2] = useState({ show: false, id: null, idMainMatch: null });
  const [modalEditImageMatch2, setModalEditImageMatch2] = useState({ show: false, id: null });
  const [modalEditJawabanMatch1, setModalEditJawabanMatch1] = useState({ show: false, id: null, idMacthMain: null });
  const [modalEditMainMatch, setModalEditMainMatch] = useState({ show: false, id: null });
  const [modalNewSoalTf, setModalNewSoalTf] = useState({ show: false, id: null });
  const [soalMc, setSoalMc] = useState([]);
  const [soalTf, setSoalTf] = useState([]);
  const [soalMatch, setSoalMatch] = useState([]);
  const [modalNewJawabanMatch, setModalNewJawabanMatch] = useState({ show: false, title: "New", text: "Please insert answer choice", id: null });
  const [modalNewSoalMatch1, setModalNewSoalMatch1] = useState({ show: false, title: "New Multiple Choice Question", text: "Please create new multiple choice question", id: null });
  const [modalNewSoalMatch2, setModalNewSoalMatch2] = useState({ show: false, id: null });
  const [modalGenerateEvent, setModalGenerateEvent] = useState({ show: false, id: null });
  const [modalProcess, setModalProcess] = useState(false);
  const [modalEventFailed, setModalEventFailed] = useState({ show: false, text: "okay" });
  const [modalQr, setModalQr] = useState({ show: false, data: null, id: param.id });
  const navigate = useNavigate();
  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  // ---------------------------------------------------------Generate-------------------------------------------------------------------

  // ---------------------------------------------------------End Generate-------------------------------------------------------------------

  function dateFormat(d) {
    const date = new Date(d);
    return date.toDateString();
  }

  function editMain(e) {
    switch (e.modal) {
      case "select":
        setModalEditMain({ ...modalEditMain, show: true, data: { title: "Edit " + e.title, text: "Please select to edit " + e.title, data: { id: param.id, val: e.value, edit: e.key } } });
        break;
      case "date":
        setModalEditMainDate({ ...modalEditMainDate, show: true, data: { title: "Edit " + e.title, text: "Please edit training " + e.title, data: { id: param.id, val: e.value, edit: e.key } } });
        break;
      case "text":
        setModalEditMainText({ ...modalEditMainText, show: true, data: { title: "Edit " + e.title, text: "Please edit " + e.title, data: { id: param.id, val: e.value, edit: e.key } } });
        break;
      case "number":
        setModalEditMainText({ ...modalEditMainNumber, show: true, data: { title: "Edit " + e.title, text: "Please edit " + e.title, data: { id: param.id, val: e.value, edit: e.key } } });
        break;
    }
  }

  // ---------------------------------------------------------Edit Main--------------------------------------------------------------
  function submitEdit(val) {
    setModalEditMain({ ...modalEditMain, show: false });
    setModalEditMainDate({ ...modalEditMainDate, show: false });
    setModalEditMainText({ ...modalEditMainText, show: false });

    axiosInstance
      .put("question-edit/soal-main", val, getHeader())
      .then((res) => {
        setGeneralData(res.data.data.main);
        setSoalMc(res.data.data.soalMc);
      })
      .catch((e) => {});
  }
  // ---------------------------------------------------------End Edit Main--------------------------------------------------------------

  // ---------------------------------------------------------Add Participant--------------------------------------------------------------
  function handleAddMcQuestion() {
    setModalNewSoalMc({ show: true, id: null });
  }

  // ---------------------------------------------------------End Add Participant--------------------------------------------------------------

  // ---------------------------------------------------------Delete Question--------------------------------------------------------------
  function handleDeleteQuestion() {
    setModalConfirmEventDelete({ show: true, id: null });
  }

  function handleDeleteQuestionConfirmed() {
    setModalConfirmEventDelete({ show: false, id: null });
    axiosInstance
      .delete("/question-delete/main/" + param.id, getHeader())
      .then((res) => {
        if (res.data.data.status) {
          navigate("/question/data");
        }
      })
      .catch((e) => {});
  }
  // ---------------------------------------------------------End Delete Question--------------------------------------------------------------

  // ---------------------------------------------------------Edit Multiplel Choice-------------------------------------------------------------------
  function handleClickEditsoalMc(id) {
    setModalSoalMc({ show: true, id: id });
  }
  function handleEditMcSubmitClick(val) {
    axiosInstance
      .put("/question-edit/multiple-choice/" + param.id, val, getHeader())
      .then((res) => {
        setGeneralData(res.data.data.main);
        setSoalMc(res.data.data.soalMc);
        setModalSoalMc({ show: false, id: null });
      })
      .catch((e) => {});
  }
  // ---------------------------------------------------------End Edit Multiplel Choice----------------------------------------------------------------
  // ---------------------------------------------------------Delete Multiplel Choice----------------------------------------------------------------
  function handleClickDeletesoalMc(id) {
    setModalConfirmDeleteMc({ show: true, title: "Warning", text: "Are you sure want to delete this question ??", id: id });
  }

  function handleDeleteMcConfirmed(id) {
    setModalConfirmDeleteMc({ ...modalConfirmDeleteMc, show: false });
    axiosInstance
      .delete("/question-delete/mc/" + param.id + "/" + id, getHeader())
      .then((res) => {
        setGeneralData(res.data.data.main);
        setSoalMc(res.data.data.soalMc);
        setSoalTf(res.data.data.soalTf);
      })
      .catch((e) => {});
  }
  // ---------------------------------------------------------End Delete Multiplel Choice----------------------------------------------------------------
  // ---------------------------------------------------------Delete True False ----------------------------------------------------------------

  function handleClickDeletesoalTf(id) {
    setModalConfirmDeleteTf({ show: true, title: "Warning", text: "Do you want to delete this question ?", id: id });
  }
  function handleDeleteMcConfirmedTf(id) {
    setModalConfirmDeleteTf({ ...modalConfirmDeleteTf, show: false });
    axiosInstance
      .delete("/question-delete/tf/" + param.id + "/" + id, getHeader())
      .then((res) => {
        setGeneralData(res.data.data.main);
        setSoalMc(res.data.data.soalMc);
        setSoalTf(res.data.data.soalTf);
      })
      .catch((e) => {});
  }
  // ---------------------------------------------------------End Delete True False ----------------------------------------------------------------
  function handleNewMcSubmitClick(val) {
    setModalNewSoalMc({ show: false, id: null });
    axiosInstance
      .post("/question-new/new-mc/" + param.id, val, getHeader())
      .then((res) => {
        setGeneralData(res.data.data.main);
        setSoalMc(res.data.data.soalMc);
      })
      .catch((e) => {});
  }
  // ---------------------------------------------------------End New Multiplel Choice----------------------------------------------------------------

  function handleSoalStatusClick(val, id) {
    axiosInstance
      .put("/question-edit/soal-mc-status/" + param.id, { val: val, id: id }, getHeader())
      .then((res) => {
        setGeneralData(res.data.data.main);
        setSoalMc(res.data.data.soalMc);
      })
      .catch((e) => {});
  }
  function handleSoalStatusTfClick(val, id) {
    axiosInstance
      .put("/question-edit/soal-tf-status/" + param.id, { val: val, id: id }, getHeader())
      .then((res) => {
        setGeneralData(res.data.data.main);
        setSoalMc(res.data.data.soalMc);
      })
      .catch((e) => {});
  }

  // ---------------------------------------------------------Edit True False----------------------------------------------------------------
  function handleEditTfSubmitClick(val) {
    setModalEditSoalTf({ show: false, id: null });
    axiosInstance
      .put("/question-edit/true-false/" + param.id, val, getHeader())
      .then((res) => {
        setGeneralData(res.data.data.main);
        setSoalMc(res.data.data.soalMc);
        setSoalTf(res.data.data.soalTf);
      })
      .catch((e) => {});
  }

  function handleClickEditsoalTf(id) {
    setModalEditSoalTf({ show: true, id: id });
  }
  // ---------------------------------------------------------End Edit True False----------------------------------------------------------------

  // ---------------------------------------------------------New Add True False----------------------------------------------------------------
  function handleAddTfQuestion() {
    setModalNewSoalTf({ show: true, id: param.id });
  }

  function handleNewTfSubmitClick(val) {
    setModalNewSoalTf({ show: false, id: null });
    axiosInstance
      .post("/question-new/new-tf/" + param.id, val, getHeader())
      .then((res) => {
        setGeneralData(res.data.data.main);
        setSoalMc(res.data.data.soalMc);
        setSoalTf(res.data.data.soalTf);
      })
      .catch((e) => {});
  }
  // ---------------------------------------------------------End Add True True False----------------------------------------------------------------

  // ---------------------------------------------------------New Jawaban Matching----------------------------------------------------------------
  function handleAddJawabanMatchClick(id) {
    setModalNewJawabanMatch({ show: true, title: "New", text: "Please insert answer choice", id: id });
  }
  function handleNewMatchJawabanSubmitClick(val) {
    setModalNewJawabanMatch({ ...modalNewJawabanMatch, show: false });
    axiosInstance
      .post("/question-new/new-choice-match/" + param.id, val, getHeader())
      .then((res) => {
        if (res.data.data.message == "duplicate") {
          setModalInfo({ show: true, text: "Answer choice already exist", title: "Failed" });
        } else {
          refreshData(res.data.data.value);
        }
      })
      .catch((e) => {});
  }
  // ---------------------------------------------------------End New Jawaban Matching----------------------------------------------------------------

  // ---------------------------------------------------------New soal Matching1----------------------------------------------------------------
  function handleAddSoalMatchClick(id) {
    setModalNewSoalMatch1({ ...modalNewSoalMatch1, show: true, id: id });
  }

  function handleNewMatch1SoalSubmitClick(val) {
    setModalNewSoalMatch1({ ...modalNewSoalMatch1, show: false });
    axiosInstance
      .post("/question-new/soal-matching-1/" + param.id, val, getHeader())
      .then((res) => refreshData(res.data.data))
      .catch((e) => {});
  }
  // ---------------------------------------------------------End New soal Matching1----------------------------------------------------------------
  // ---------------------------------------------------------New soal Matching2----------------------------------------------------------------
  function handleAddSoalMatch2Click(id) {
    setModalNewSoalMatch2({ show: true, id: id });
  }
  function handleNewMatch2SoalSubmitClick(val) {
    setModalNewSoalMatch2({ ...modalNewSoalMatch2, show: false });
    axiosInstance
      .post("/question-new/soal-matching-2/" + param.id, val, getHeader())
      .then((res) => refreshData(res.data.data))
      .catch((e) => {});
  }
  // ---------------------------------------------------------End New soal Matching2----------------------------------------------------------------

  // ---------------------------------------------------------New Matching1----------------------------------------------------------------

  function handleAddMatchQuestion() {
    const val = {
      petunjuk: "BLANK",
    };
    axiosInstance
      .post("/question-new/soal-matching-main-1/" + param.id, val, getHeader())
      .then((res) => refreshData(res.data.data))
      .catch((e) => {});
  }
  // ---------------------------------------------------------End New Matching1----------------------------------------------------------------
  // ---------------------------------------------------------New Matching2----------------------------------------------------------------
  function handleAddMatchQuestionType2() {
    const val = {
      petunjuk: "BLANK",
    };
    axiosInstance
      .post("/question-new/soal-matching-main-2/" + param.id, val, getHeader())
      .then((res) => refreshData(res.data.data))
      .catch((e) => {});
  }
  // ---------------------------------------------------------End New Matching2----------------------------------------------------------------
  // ---------------------------------------------------------Edit Matching1----------------------------------------------------------------

  function handleEditSoalMatch1(soal) {
    setModalEditSoalMatch1({ ...modalNewSoalMatch1, show: true, id: soal.id, idMainMatch: soal.soal_matching_main_id });
  }
  function handleEditSoalMatch1SubmitClick(val) {
    setModalEditSoalMatch1({ ...modalNewSoalMatch1, show: false });
    axiosInstance
      .put("/question-edit/matching1-soal/" + param.id, val, getHeader())
      .then((res) => refreshData(res.data.data))
      .catch((e) => {});
  }
  // ---------------------------------------------------------End Edit Matching1----------------------------------------------------------------
  // ---------------------------------------------------------Edit Matching2----------------------------------------------------------------
  function handleEditSoalMatch2(soal) {
    setModalEditSoalMatch2({ ...modalNewSoalMatch2, show: true, id: soal.id, idMainMatch: soal.soal_matching_main_id });
  }
  function handleEditSoalMatch2SubmitClick(val) {
    setModalEditSoalMatch2({ ...modalNewSoalMatch2, show: false });
    axiosInstance
      .put("/question-edit/matching2-soal/" + param.id, val, getHeader())
      .then((res) => refreshData(res.data.data))
      .catch((e) => {});
  }
  // ---------------------------------------------------------End Edit Matching2----------------------------------------------------------------
  // ---------------------------------------------------------Delete Soal Matching1----------------------------------------------------------------

  function hanldeDeleSoalMatch1(id) {
    setModalConfirmDeleteSoalMatch1({ ...modalConfirmDeleteSoalMatch1, show: true, id: id });
  }
  function handleDeleteMatch1Confirmed(id) {
    setModalConfirmDeleteSoalMatch1({ ...modalConfirmDeleteSoalMatch1, show: false });
    axiosInstance
      .delete("/question-delete/match-soal/" + param.id + "/" + id, getHeader())
      .then((res) => refreshData(res.data.data))
      .catch((e) => {});
  }
  // ---------------------------------------------------------End Delete Soal Matching1----------------------------------------------------------------
  // ---------------------------------------------------------Delete Soal Matching2----------------------------------------------------------------
  function hanldeDeleSoalMatch2(id) {
    setModalConfirmDeleteSoalMatch2({ ...modalConfirmDeleteSoalMatch1, show: true, id: id });
  }
  function handleDeleteSoalMatch2Confirmed(id) {
    setModalConfirmDeleteSoalMatch2({ ...modalConfirmDeleteSoalMatch1, show: false });
    axiosInstance
      .delete("/question-delete/match-soal/" + param.id + "/" + id, getHeader())
      .then((res) => refreshData(res.data.data))
      .catch((e) => {});
  }
  // ---------------------------------------------------------End Delete Soal Matching2----------------------------------------------------------------

  // ---------------------------------------------------------Delete Jawaban Matching1----------------------------------------------------------------
  function handleClickDeleteJawabanMatch1(jawaban) {
    setModalConfirmDeleteJawabanMatch1({ ...modalConfirmDeleteSoalMatch1, show: true, id: jawaban.id });
  }

  function handleDeleteJawabanMatch1Confirmed(val) {
    setModalConfirmDeleteJawabanMatch1({ ...modalConfirmDeleteSoalMatch1, show: false });
    axiosInstance
      .delete("/question-delete/jawaban-match-1/" + param.id + "/" + val, getHeader())
      .then((res) => refreshData(res.data.data))
      .catch((e) => {});
  }
  // ---------------------------------------------------------End Delete Jawaban Matching1----------------------------------------------------------------

  function refreshData(data) {
    setGeneralData(data.main);
    setSoalMc(data.soalMc);
    setSoalTf(data.soalTf);
    setSoalMatch(data.soalMatch);
  }

  function handleClickJawabanMatch1Edit(jawaban) {
    setModalEditJawabanMatch1({ id: jawaban.id, idMacthMain: jawaban.soal_matching_main_id, show: true });
  }

  function handleEditAnswerMatch1SubmitClick(val) {
    setModalEditJawabanMatch1({ ...modalEditJawabanMatch1, show: false });
    axiosInstance
      .put("/question-edit/matching1-jawaban/" + param.id, val, getHeader())
      .then((res) => {
        if (res.data.data.message == "duplicate") {
          setModalInfo({ show: true, text: "Answer choice already exist", title: "Failed" });
        } else {
          refreshData(res.data.data.value);
        }
      })
      .catch((e) => {});
  }

  // ---------------------------------------------------------Edit Main Matching1----------------------------------------------------------------

  function handleClickMatchMainEdit(val) {
    setModalEditMainMatch({ show: true, id: val.id });
  }

  function handleSubmitEditMainMatchClick(val) {
    setModalEditMainMatch({ ...modalEditMainMatch, show: false });
    axiosInstance
      .put("/question-edit/matching-main/" + param.id, val, getHeader())
      .then((res) => refreshData(res.data.data))
      .catch((e) => {});
  }
  // ---------------------------------------------------------End Edit Main Matching1----------------------------------------------------------------
  // ---------------------------------------------------------Delete Matching2----------------------------------------------------------------
  function handleClickMatch2MainDelete(val) {
    setModalConfirmDeleteMatch2({ show: true, id: val.id });
  }
  function handleDeleteMatch2Confirmed(val) {
    setModalConfirmDeleteMatch2({ ...modalConfirmDeleteMatch1, show: false });
    axiosInstance
      .delete("/question-delete/match-2/" + param.id + "/" + val, getHeader())
      .then((res) => refreshData(res.data.data))
      .catch((e) => {});
  }
  // ---------------------------------------------------------End Delete Matching2----------------------------------------------------------------
  // ---------------------------------------------------------Delete Matching1----------------------------------------------------------------
  function handleClickMatch1MainDelete(val) {
    setModalConfirmDeleteMatch1({ show: true, id: val.id });
  }
  function handleDeleteMatch1Confirmed(val) {
    setModalConfirmDeleteMatch1({ ...modalConfirmDeleteMatch1, show: false });
    axiosInstance
      .delete("/question-delete/match-1/" + param.id + "/" + val, getHeader())
      .then((res) => refreshData(res.data.data))
      .catch((e) => {});
  }
  // ---------------------------------------------------------End Delete Matching1----------------------------------------------------------------
  // ---------------------------------------------------------Change Status Matching1----------------------------------------------------------------
  function handleStatusMatchChange(id, val) {
    axiosInstance
      .put("/question-edit/matching-status/" + param.id, { id: id, val: val }, getHeader())
      .then((res) => refreshData(res.data.data))
      .catch((e) => {});
  }
  // ---------------------------------------------------------End Change Status Matching1----------------------------------------------------------------
  // ---------------------------------------------------------Edit Change Image Matching2----------------------------------------------------------------
  function handleClickChangeImageMatch2(id) {
    setModalEditImageMatch2({ show: true, id: id });
  }
  function handleSubmitImageMatch2Edit(val) {
    setModalEditImageMatch2({ ...modalEditImageMatch2, show: false });
    axiosInstance
      .put("/question-edit/soal-matching-image/" + param.id, val, getHeader())
      .then((res) => refreshData(res.data.data))
      .catch((e) => {});
  }
  // ---------------------------------------------------------Generate Event----------------------------------------------------------------

  function handleGenerateEvent() {
    setModalGenerateEvent({ show: true, id: param.id });
  }

  function handleSubmitEvent(val) {
    setModalGenerateEvent({ show: false, id: param.id });
    setModalProcess(true);
    axiosInstance
      .post("/question-event-new/new/" + param.id, val, getHeader())
      .then((res) => {
        setModalProcess(false);
        if (!res.data.status) {
          setModalEventFailed({ show: true, text: res.data.data.message[0] });
        } else {
          setModalQr({ show: true, data: res.data.data.link, id: param.id });
        }
      })
      .catch((e) => {});
  }
  // ---------------------------------------------------------End Generate Event----------------------------------------------------------------
  useEffect(() => {
    axiosInstance
      .get("/question-get/one-set/" + param.id, getHeader())
      .then((res) => refreshData(res.data.data))
      .catch((e) => {});
  }, []);
  return (
    <>
      <div>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#/">TEST</a>
          </li>
          <li className="breadcrumb-item active">Detail</li>
        </ul>

        <h1 className="page-header">
          Question Detail <small></small>
        </h1>

        {/* <hr className="mb-4" /> */}
        <div className="mb-sm-4 mb-3 d-sm-flex">
          <div className="mt-sm-0 mt-2">
            <a href="#/" onClick={() => handleGenerateEvent()} className="text-inverse text-opacity-75 text-decoration-none">
              <i className="fas fa-wifi fa-fw me-1 text-theme"></i> Event
            </a>
          </div>
          <div className="ms-sm-4 mt-sm-0 mt-2">
            <a href="#/" onClick={() => handleDeleteQuestion()} className="text-danger text-opacity-75 text-decoration-none">
              <i className="fas fa-trash-alt fa-fw me-1 text-danger"></i> Delete
            </a>
          </div>
        </div>
        <div className="row gx-4">
          <div className="col-lg-8">
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">General</CardHeader>
              <CardBody>
                {generalData.length > 0 ? (
                  generalData.map((value, index) => (
                    <div className="list-group list-group-flush" key={index}>
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div className="text-theme">{value.title}</div>
                          <div className="text-inverse text-opacity-50">{value.value}</div>
                        </div>

                        {value.editBtn ? (
                          <div className="w-50px text-center">
                            <a href="#/" onClick={() => editMain(value)} className="btn btn-outline-default btn-sm">
                              <i className="fas fa-edit text-theme"></i>
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <p>no Data</p>
                )}
              </CardBody>
            </Card>
          </div>

          <div className="col-lg-4">
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center fw-400 bg-inverse bg-opacity-10">
                <div className="flex-1">
                  <div>Resume</div>
                </div>
              </CardHeader>
              <CardBody>------------</CardBody>
            </Card>
          </div>

          <div className="col-lg-12">
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">Multiple Choice</CardHeader>
              <CardBody>
                <div className="mb-2">
                  <div className="d-block d-lg-flex align-items-center">
                    <span onClick={() => handleAddMcQuestion()} className="btn btn-outline-theme me-2 mb-1">
                      <i className="fa fa-fw fa-plus"></i>
                      <span>Add</span>
                    </span>
                  </div>
                </div>

                {/* ----------------------------------------------------------------------------------------------------- */}
                {soalMc.length > 0 ? (
                  soalMc.map((soal, index) => (
                    <div key={index}>
                      <div className="list-group list-group-flush">
                        <div className="list-group-item d-flex px-3">
                          <div className="me-3">
                            <div className="fw-400">{index + 1 + "."}</div>
                          </div>
                          {/* ----------------------------------------------------------------------------------------------------- */}
                          <div className="flex-fill">
                            <div className="fw-400 mb-1">{soal.pertanyaan}</div>

                            {soal.img ? (
                              <div className="mb-3">
                                <ImageHandler url={soal.img} />
                              </div>
                            ) : (
                              ""
                            )}

                            {soal.jawaban.length > 0 ? (
                              soal.jawaban.map((jawaban, ind) => (
                                <div key={ind} className="form-group small">
                                  <div className="form-check">
                                    <input type="radio" className="form-check-input" checked={jawaban.trueChoice ? 1 : 0} disabled />
                                    <label className={jawaban.trueChoice ? "form-label text-theme" : "form-label"} htmlFor="customCheck1">
                                      {jawaban.choices}
                                    </label>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p>no Choice Answer</p>
                            )}

                            <div className="d-flex align-items-center mt-1   mb-2">
                              <div className="fw-400 me-1">
                                <div className="form-check form-switch">
                                  {soal.status ? (
                                    <input type="checkbox" onChange={(e) => handleSoalStatusClick(e.target.checked, soal.id)} className="form-check-input me-3" defaultChecked id="customSwitch1" />
                                  ) : (
                                    <input type="checkbox" onChange={(e) => handleSoalStatusClick(e.target.checked, soal.id)} className="form-check-input me-3" id="customSwitch1" />
                                  )}
                                  <a href="#/" className="me-3" onClick={() => handleClickEditsoalMc(soal.id)}>
                                    <i className="far fa-edit"></i>
                                  </a>
                                  <a href="#/" onClick={() => handleClickDeletesoalMc(soal.id)}>
                                    <i className="far fa-trash-alt text-danger"></i>
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex align-items-center">
                              <div className="small text-inverse text-opacity-50">Pre:1000 Res.</div>
                              <div className="progress progress-xs w-100px me-2 ms-auto" style={{ height: "6px" }}>
                                <div className="progress-bar progress-bar-striped bg-theme" style={{ width: "66%" }}></div>
                              </div>
                              <div className="fs-12px">66%</div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="small text-inverse text-opacity-50">Post:1000 Res.</div>
                              <div className="progress progress-xs w-100px me-2 ms-auto" style={{ height: "6px" }}>
                                <div className="progress-bar progress-bar-striped bg-theme" style={{ width: "66%" }}></div>
                              </div>
                              <div className="fs-12px">66%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="my-1" />
                    </div>
                  ))
                ) : (
                  <p>no Data</p>
                )}
                {soalMc.length > 1 ? (
                  <div className="mt-3">
                    <div className="d-block d-lg-flex align-items-center">
                      <span onClick={() => handleAddMcQuestion()} className="btn btn-outline-theme me-2 mb-1">
                        <i className="fa fa-fw fa-plus"></i>
                        <span>Add</span>
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* ----------------------------------------------------------------------------------------------------- */}
              </CardBody>
            </Card>
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">True or False</CardHeader>
              <CardBody>
                <div className="mb-2">
                  <div className="d-block d-lg-flex align-items-center">
                    <span onClick={() => handleAddTfQuestion()} className="btn btn-outline-theme me-2 mb-1">
                      <i className="fa fa-fw fa-plus"></i>
                      <span>Add</span>
                    </span>
                  </div>
                </div>

                {/* -----------------------------------------------------SOAL TRUE FALSE--------------------------------------------------- */}
                {soalTf.length > 0 ? (
                  soalTf.map((soal, index) => (
                    <div key={index}>
                      <div className="list-group list-group-flush">
                        <div className="list-group-item d-flex px-3">
                          <div className="me-3">
                            <div className="fw-400">{index + 1 + "."}</div>
                          </div>
                          {/* ----------------------------------------------------------------------------------------------------- */}
                          <div className="flex-fill">
                            <div className="fw-400 mb-1">{soal.pertanyaan}</div>

                            {soal.img ? (
                              <div className="mb-3">
                                <ImageHandler url={soal.img} />
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="form-group small">
                              <div className="form-check">
                                <input type="radio" className="form-check-input" disabled checked={soal.answer ? 1 : 0} />
                                <label className={soal.answer ? "form-label text-theme" : "form-label"}>TRUE</label>
                              </div>
                              <div className="form-check">
                                <input type="radio" className="form-check-input" disabled checked={soal.answer ? 0 : 1} />
                                <label className={!soal.answer ? "form-label text-theme" : "form-label"}>FALSE</label>
                              </div>
                            </div>

                            <div className="d-flex align-items-center mt-1   mb-2">
                              <div className="fw-400 me-1">
                                <div className="form-check form-switch">
                                  <input type="checkbox" onChange={(e) => handleSoalStatusTfClick(e.target.checked, soal.id)} className="form-check-input me-3" defaultChecked={soal.status ? 1 : 0} />
                                  <a href="#/" className="me-3" onClick={() => handleClickEditsoalTf(soal.id)}>
                                    <i className="far fa-edit"></i>
                                  </a>
                                  <a href="#/" onClick={() => handleClickDeletesoalTf(soal.id)}>
                                    <i className="far fa-trash-alt text-danger"></i>
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex align-items-center">
                              <div className="small text-inverse text-opacity-50">Pre:1000 Res.</div>
                              <div className="progress progress-xs w-100px me-2 ms-auto" style={{ height: "6px" }}>
                                <div className="progress-bar progress-bar-striped bg-theme" style={{ width: "66%" }}></div>
                              </div>
                              <div className="fs-12px">66%</div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="small text-inverse text-opacity-50">Post:1000 Res.</div>
                              <div className="progress progress-xs w-100px me-2 ms-auto" style={{ height: "6px" }}>
                                <div className="progress-bar progress-bar-striped bg-theme" style={{ width: "66%" }}></div>
                              </div>
                              <div className="fs-12px">66%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="my-1" />
                    </div>
                  ))
                ) : (
                  <p>no Data</p>
                )}

                {soalTf.length > 2 ? (
                  <div className="mb-2">
                    <div className="d-block d-lg-flex align-items-center">
                      <span onClick={() => handleAddTfQuestion()} className="btn btn-outline-theme me-2 mb-1">
                        <i className="fa fa-fw fa-plus"></i>
                        <span>Add</span>
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* -----------------------------------------------------END SOAL TRUE FALSE--------------------------------------------------- */}
              </CardBody>
            </Card>
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">Matching</CardHeader>
              <CardBody>
                <div className="mb-2">
                  <div className="d-block d-lg-flex align-items-center">
                    <span onClick={() => handleAddMatchQuestion()} className="btn btn-outline-theme me-2 mb-1">
                      <i className="fa fa-fw fa-plus"></i>
                      <span>Type 1</span>
                    </span>
                    <span onClick={() => handleAddMatchQuestionType2()} className="btn btn-outline-theme me-2 mb-1">
                      <i className="fa fa-fw fa-plus"></i>
                      <span>Type 2</span>
                    </span>
                  </div>
                </div>
                {soalMatch.length > 0 ? (
                  <>
                    {soalMatch.map((soalMain, index) => (
                      <div key={index}>
                        {
                          soalMain.img ? (
                            // {/* -------------------------------------------------------Soal 1---------------------------------------------------------- */}
                            <div className="mb-3">
                              <Card className="mb-3">
                                <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10">
                                  <span className="flex-grow-1 fw-400">{soalMain.petunjuk}</span>
                                  <a onClick={() => handleClickMatchMainEdit(soalMain)} href="#/" className="text-theme text-decoration-none me-3">
                                    <i className="far fa-edit"></i>
                                  </a>
                                  <div className="form-check form-switch">
                                    <input onChange={(e) => handleStatusMatchChange(soalMain.id, e.target.checked)} type="checkbox" className="form-check-input me-3" defaultChecked={soalMain.status ? 1 : 0} />
                                  </div>
                                  <a onClick={() => handleClickMatch2MainDelete(soalMain)} href="#/" className="text-danger text-decoration-none me-1">
                                    <i className="far fa-trash-alt"></i>
                                  </a>
                                </CardHeader>
                              </Card>

                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <span className="flex-grow-1 fw-400 text-theme">Image</span>
                                  </div>
                                  <div>
                                    <ImageHandler url={soalMain.img} />
                                  </div>
                                  <a onClick={() => handleClickChangeImageMatch2(soalMain.id)} className="me-1" href="#/">
                                    <i className="far fa-edit text-theme"></i>
                                  </a>
                                </div>

                                {/* ----------------------------------------------End Pertanyaan------------------------------------------------ */}
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <span className="flex-grow-1 fw-400 text-theme">Questions</span>
                                  </div>
                                  <div className="list-group list-group-flush">
                                    {soalMain.soal.map((soal, ind) => (
                                      <div key={ind} className="d-flex px-3 mb-1">
                                        <div className="me-3">
                                          <div className="fw-400">{ind + 1}</div>
                                        </div>
                                        <div className="flex-fill">
                                          <div className="fw-400">{soal.pertanyaan}</div>
                                          <div className="small text-inverse text-opacity-50">
                                            {soal.kunci}{" "}
                                            <a onClick={() => handleEditSoalMatch2(soal)} className="me-1" href="#/">
                                              <i className="far fa-edit text-theme"></i>
                                            </a>
                                            <a onClick={() => hanldeDeleSoalMatch2(soal.id)} href="#/" className="text-danger">
                                              <i className="far fa-trash-alt"></i>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <div className="px-3 mb-3">
                                    <a onClick={() => handleAddSoalMatch2Click(soalMain.id)} href="#/" className="text-theme">
                                      Add
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            // {/* ------------------------------------------------------- End Soal 1---------------------------------------------------------- */}
                            // {/* ------------------------------------------------------- Soal 2---------------------------------------------------------- */}
                            <div className="mb-3">
                              <Card className="mb-3">
                                <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10">
                                  <span className="flex-grow-1 fw-400">{soalMain.petunjuk}</span>
                                  <a onClick={() => handleClickMatchMainEdit(soalMain)} href="#/" className="text-theme text-decoration-none me-3">
                                    <i className="far fa-edit"></i>
                                  </a>
                                  <div className="form-check form-switch">
                                    <input onChange={(e) => handleStatusMatchChange(soalMain.id, e.target.checked)} type="checkbox" className="form-check-input me-3" defaultChecked={soalMain.status ? 1 : 0} />
                                  </div>
                                  <a onClick={() => handleClickMatch1MainDelete(soalMain)} href="#/" className="text-danger text-decoration-none me-1">
                                    <i className="far fa-trash-alt"></i>
                                  </a>
                                </CardHeader>
                              </Card>

                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <span className="flex-grow-1 fw-400 text-theme">Questions</span>
                                  </div>
                                  <div className="list-group list-group-flush">
                                    {soalMain.soal.map((soal, ind) => (
                                      <div key={ind} className="d-flex px-3 mb-1">
                                        <div className="me-3">
                                          <div className="fw-400">{ind + 1}</div>
                                        </div>
                                        <div className="flex-fill">
                                          <div className="fw-400">{soal.pertanyaan}</div>
                                          <div className="small text-inverse text-opacity-50">
                                            {soal.kunci}{" "}
                                            <a onClick={() => handleEditSoalMatch1(soal)} className="me-1" href="#/">
                                              <i className="far fa-edit text-theme"></i>
                                            </a>
                                            <a onClick={() => hanldeDeleSoalMatch1(soal.id)} href="#/" className="text-danger">
                                              <i className="far fa-trash-alt"></i>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <div className="px-3 mb-3">
                                    <a onClick={() => handleAddSoalMatchClick(soalMain.id)} href="#/" className="text-theme">
                                      Add
                                    </a>
                                  </div>
                                </div>

                                {/* ----------------------------------------------End Pertanyaan------------------------------------------------ */}
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <span className="flex-grow-1 fw-400 text-theme">Answer Choice</span>
                                  </div>
                                  <div className="list-group list-group-flush">
                                    {soalMain.jawaban.map((jawaban, ind) => (
                                      <div key={ind} className="d-flex px-3 mb-1">
                                        <div className="me-3">
                                          <div className="fw-400">-</div>
                                        </div>
                                        <div className="flex-fill">
                                          <div className="fw-400">
                                            {jawaban.jawaban}{" "}
                                            <a onClick={() => handleClickJawabanMatch1Edit(jawaban)} className="me-1" href="#/">
                                              <i className="far fa-edit text-theme"></i>
                                            </a>
                                            <a onClick={() => handleClickDeleteJawabanMatch1(jawaban)} href="#/" className="text-danger">
                                              <i className="far fa-trash-alt"></i>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <div className="px-3 mb-3">
                                    <a onClick={() => handleAddJawabanMatchClick(soalMain.id)} href="#/" className="text-theme">
                                      Add
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                          // {/* -------------------------------------------------------End Soal 2---------------------------------------------------------- */}
                        }
                      </div>
                    ))}
                  </>
                ) : (
                  <p>no data</p>
                )}
              </CardBody>
            </Card>
            <a onClick={() => handleDeleteQuestion()} href="#/" className="text-danger">
              Delete
            </a>
          </div>
        </div>
      </div>
      <ModalInfo show={modalInfo.show} clicked={() => setModalInfo({ ...modalInfo, show: false })} onHide={() => setModalInfo({ ...modalInfo, show: false })} theme={"danger"} title={modalInfo.title} text={modalInfo.text} />
      <ModalConfirm
        show={modalConfirmEventDelete.show}
        clicked={() => handleDeleteQuestionConfirmed()}
        onHide={() => setModalConfirmEventDelete({ ...modalConfirmEventDelete, show: false })}
        title={"Warning"}
        text={"Are you sure delete this question ?"}
        theme={"danger"}
      />
      <ModalEditSelect show={modalEditMain.show} data={modalEditMain.data} submitClick={(val) => submitEdit(val)} onHide={() => setModalEditMain({ ...modalEditMain, show: false })} />
      <ModalEditDate show={modalEditMainDate.show} data={modalEditMainDate.data} submitClick={(val) => submitEdit(val)} onHide={() => setModalEditMainDate({ ...modalEditMainDate, show: false })} />
      <ModalEditText show={modalEditMainText.show} data={modalEditMainText.data} submitClick={(val) => submitEdit(val)} onHide={() => setModalEditMainText({ ...modalEditMainText, show: false })} />
      <ModalEditNumber show={modalEditMainNumber.show} data={modalEditMainNumber.data} submitClick={(val) => submitEdit(val)} onHide={() => setModalEditMainNumber({ ...modalEditMainNumber, show: false })} />
      <ModalConfirm
        clicked={() => setModalEventFailed({ ...modalEventFailed, show: false })}
        theme={"danger"}
        show={modalEventFailed.show}
        onHide={() => setModalEventFailed({ ...modalEventFailed, show: false })}
        title={"Failed"}
        text={modalEventFailed.text}
      />
      <ModalConfirm
        show={modalConfirmDeleteMc.show}
        clicked={(id) => handleDeleteMcConfirmed(id)}
        onHide={() => setModalConfirmDeleteMc({ ...modalConfirmDeleteMc, show: false })}
        title={modalConfirmDeleteMc.title}
        text={modalConfirmDeleteMc.text}
        id={modalConfirmDeleteMc.id}
        theme={"danger"}
      />
      <ModalConfirm
        show={modalConfirmDeleteTf.show}
        clicked={(id) => handleDeleteMcConfirmedTf(id)}
        onHide={() => setModalConfirmDeleteTf({ ...modalConfirmDeleteTf, show: false })}
        title={modalConfirmDeleteTf.title}
        text={modalConfirmDeleteTf.text}
        id={modalConfirmDeleteTf.id}
        theme={"danger"}
      />
      <ModalConfirm
        show={modalConfirmDeleteSoalMatch1.show}
        clicked={(val) => handleDeleteMatch1Confirmed(val)}
        onHide={() => setModalConfirmDeleteSoalMatch1({ ...modalConfirmDeleteSoalMatch1, show: false })}
        title={modalConfirmDeleteSoalMatch1.title}
        text={modalConfirmDeleteSoalMatch1.text}
        id={modalConfirmDeleteSoalMatch1.id}
        theme={"danger"}
      />
      <ModalConfirm
        show={modalConfirmDeleteSoalMatch2.show}
        clicked={(val) => handleDeleteSoalMatch2Confirmed(val)}
        onHide={() => setModalConfirmDeleteSoalMatch2({ ...modalConfirmDeleteSoalMatch2, show: false })}
        title={modalConfirmDeleteSoalMatch2.title}
        text={modalConfirmDeleteSoalMatch2.text}
        id={modalConfirmDeleteSoalMatch2.id}
        theme={"danger"}
      />
      <ModalConfirm
        show={modalConfirmDeleteJawabanMatch1.show}
        clicked={(val) => handleDeleteJawabanMatch1Confirmed(val)}
        onHide={() => setModalConfirmDeleteJawabanMatch1({ ...modalConfirmDeleteJawabanMatch1, show: false })}
        title={modalConfirmDeleteJawabanMatch1.title}
        text={modalConfirmDeleteJawabanMatch1.text}
        id={modalConfirmDeleteJawabanMatch1.id}
        theme={"danger"}
      />
      <ModalConfirm
        show={modalConfirmDeleteMatch1.show}
        clicked={(val) => handleDeleteMatch1Confirmed(val)}
        onHide={() => setModalConfirmDeleteMatch1({ ...modalConfirmDeleteMatch1, show: false })}
        title={"Warning"}
        text={"Are you sure want to delete this question ??"}
        id={modalConfirmDeleteMatch1.id}
        theme={"danger"}
      />
      <ModalConfirm
        show={modalConfirmDeleteMatch2.show}
        clicked={(val) => handleDeleteMatch2Confirmed(val)}
        onHide={() => setModalConfirmDeleteMatch2({ ...modalConfirmDeleteMatch2, show: false })}
        title={"Warning"}
        text={"Are you sure want to delete this question ??"}
        id={modalConfirmDeleteMatch2.id}
        theme={"danger"}
      />

      <ModalEditSoalMc id={modalSoalMc.id} show={modalSoalMc.show} submitClick={(val) => handleEditMcSubmitClick(val)} onHide={() => setModalSoalMc({ ...modalSoalMc, show: false })} />
      <ModalNewSoalMc id={modalNewSoalMc.id} show={modalNewSoalMc.show} submitClick={(val) => handleNewMcSubmitClick(val)} onHide={() => setModalNewSoalMc({ ...ModalNewSoalMc, show: false })} />
      <ModalEditSoalTf id={modalEditSoalTf.id} show={modalEditSoalTf.show} submitClick={(val) => handleEditTfSubmitClick(val)} onHide={() => setModalEditSoalTf({ ...modalEditSoalTf, show: false })} />
      <ModalNewSoalTf id={modalNewSoalTf.id} show={modalNewSoalTf.show} submitClick={(val) => handleNewTfSubmitClick(val)} onHide={() => setModalNewSoalTf({ ...modalNewSoalTf, show: false })} />
      <ModalEditMatch1AnswerChoice
        id={modalEditJawabanMatch1.id}
        idMatchMain={modalEditJawabanMatch1.idMacthMain}
        show={modalEditJawabanMatch1.show}
        onHide={() => setModalEditJawabanMatch1({ ...modalEditJawabanMatch1, show: false })}
        submitClick={(val) => handleEditAnswerMatch1SubmitClick(val)}
      />
      <ModalEditSoalMatch
        id={modalEditSoalMatch1.id}
        idMainMatch={modalEditSoalMatch1.idMainMatch}
        show={modalEditSoalMatch1.show}
        onHide={() => setModalEditSoalMatch1({ ...modalEditSoalMatch1, show: false })}
        title={modalEditSoalMatch1.title}
        text={modalEditSoalMatch1.text}
        submitClick={(val) => handleEditSoalMatch1SubmitClick(val)}
      />
      <ModalEditMatch1Main show={modalEditMainMatch.show} id={modalEditMainMatch.id} submitClick={(val) => handleSubmitEditMainMatchClick(val)} onHide={() => setModalEditMainMatch({ ...modalEditMainMatch, show: false })} />
      <ModalNewSoalMatch
        id={modalNewSoalMatch1.id}
        show={modalNewSoalMatch1.show}
        submitClick={(val) => handleNewMatch1SoalSubmitClick(val)}
        onHide={() => setModalNewSoalMatch1({ ...modalNewSoalMatch1, show: false })}
        title={modalNewSoalMatch1.title}
        text={modalNewSoalMatch1.text}
      />

      <ModalNewText
        id={modalNewJawabanMatch.id}
        show={modalNewJawabanMatch.show}
        submitClick={(val) => handleNewMatchJawabanSubmitClick(val)}
        onHide={() => setModalNewJawabanMatch({ ...modalNewJawabanMatch, show: false })}
        title={modalNewJawabanMatch.title}
        text={modalNewJawabanMatch.text}
      />
      <ModalEditSoalMatch2
        show={modalEditSoalMatch2.show}
        id={modalEditSoalMatch2.id}
        submitClick={(val) => handleEditSoalMatch2SubmitClick(val)}
        onHide={() => {
          setModalEditSoalMatch2({ ...modalEditSoalMatch2, show: false });
        }}
      />
      <ModalNewSoalMatch2 show={modalNewSoalMatch2.show} id={modalNewSoalMatch2.id} submitClick={(val) => handleNewMatch2SoalSubmitClick(val)} onHide={() => setModalNewSoalMatch2({ ...modalNewSoalMatch2, show: false })} />
      <ModalEditImageMatch2 show={modalEditImageMatch2.show} id={modalEditImageMatch2.id} submitClick={(val) => handleSubmitImageMatch2Edit(val)} onHide={() => setModalEditImageMatch2({ ...modalEditImageMatch2, show: false })} />
      <ModalGenerateEvent show={modalGenerateEvent.show} id={modalGenerateEvent.id} onHide={() => setModalGenerateEvent({ ...modalGenerateEvent, show: false })} submitClick={(val) => handleSubmitEvent(val)} />
      <ModalLoadingPage show={modalProcess} />
      <ModalEventQr show={modalQr.show} data={modalQr.data} id={modalQr.id} onHide={() => setModalQr({ ...modalQr, show: false })} />
    </>
  );
}

export default QuestionDetail;
