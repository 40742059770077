import { useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import axiosInstance from "../../config/axios";
import { blobToBase64, fileToBase64 } from "file64";
const initialChoice = [];

function ModalNewSoalMc({ show, onHide, submitClick, id }) {
  const [qtyChoice, setQtyChoice] = useState(initialChoice);
  const [soal, setSoal] = useState("");
  const [img64, setImg64] = useState({ edit: false, base64: null });
  const [imgSrc, setImgSrc] = useState("");
  const [submitBtn, setSubmitBtn] = useState(false);
  const [warning, setWarning] = useState([]);
  const inputFile = useRef(null);

  function handleClickUploadFile() {
    inputFile.current.click();
  }
  function handleClickDeleteFile() {
    setSubmitBtn(true);
    inputFile.current.value = "";
    setImgSrc("");
    setImg64({
      edit: true,
      base64: "",
    });
  }

  function handleOnShow() {
    setSoal("");
    setImgSrc("");
    setWarning("");
    setSubmitBtn(false);
    setQtyChoice(initialChoice);
    setImg64({ edit: false, base64: null });
  }
  function checkRule(data) {
    let errors = [];
    const filtered = data.choices.filter((a) => a.key == 1);
    if (filtered.length < 1) {
      errors.push("Kunci jawaban belum ditentukan");
    }
    if (!data.soal) {
      errors.push("Soal masih kosong");
    }
    return errors;
  }
  function handleSubmit() {
    const data = {
      id: id,
      soal: soal,
      file: img64,
      choices: qtyChoice,
    };
    if (checkRule(data).length > 0) {
      setWarning(checkRule(data));
      return;
    }
    submitClick(data);
  }

  async function handleChangeImage(e) {
    setSubmitBtn(true);
    const file = e.target.files[0];
    setImgSrc(URL.createObjectURL(file));
    const base64 = await fileToBase64(file);
    setImg64({
      edit: true,
      base64: base64,
    });
  }

  function handleTrueAnswerChange(e, index) {
    setSubmitBtn(true);
    const choice = qtyChoice.map((c, i) => {
      if (i === index) {
        return { ...c, key: e.target.value == "on" ? 1 : 0 };
      } else {
        return { ...c, key: 0 };
      }
    });
    setQtyChoice(choice);
  }
  function handleChangeSoal(e) {
    setSubmitBtn(true);
    setSoal(e.target.value);
  }
  function handleChoiceChange(e, index) {
    setSubmitBtn(true);
    const choice = qtyChoice.map((c, i) => {
      if (i === index) {
        return { ...c, choice: e.target.value };
      } else {
        return c;
      }
    });
    setQtyChoice(choice);
  }
  function handleAddChoiceClick() {
    const l = qtyChoice.length;

    setQtyChoice([...qtyChoice, { id: null, idArr: l, key: 0, choice: "" }]);
  }

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onShow={() => handleOnShow()}
        onHide={() => {
          onHide();
          handleOnShow();
        }}
      >
        <ModalHeader closeButton>
          <h5 className="modal-title">New Multiple Choice Question</h5>
        </ModalHeader>
        <ModalBody>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="exampleFormControlInput1">
              Question
            </label>
            <textarea onChange={(e) => handleChangeSoal(e)} className="form-control mb-3" defaultValue={soal} placeholder="Type text question"></textarea>
            {imgSrc ? <img className="img-thumbnail" src={imgSrc}></img> : ""}
            <input ref={inputFile} onChange={(e) => handleChangeImage(e)} className="form-control" type="file" hidden />
            <div className="mt-sm-0 mb-3 mt-2">
              <a href="#/" onClick={() => handleClickUploadFile()} className="text-inverse me-3 text-opacity-75 text-decoration-none">
                <i className="fa fa-upload fa-fw text-theme"></i> image
              </a>
              {imgSrc ? (
                <a href="#/" onClick={() => handleClickDeleteFile()} className="text-inverse text-opacity-75 text-decoration-none">
                  <i className="fa fa-trash fa-fw text-danger"></i> Delete
                </a>
              ) : (
                ""
              )}
            </div>

            <label className="form-label" htmlFor="exampleFormControlInput1">
              Answer Choice
            </label>
            {qtyChoice.length > 0 ? (
              qtyChoice.map((choice, index) => (
                <div key={index} className="form-check">
                  <input type="radio" onChange={(e) => handleTrueAnswerChange(e, index)} name="radio" defaultChecked={choice.key} className="form-check-input" />
                  <textarea onChange={(e) => handleChoiceChange(e, index)} defaultValue={choice.choice} className="form-control mb-1" placeholder="Type text question"></textarea>
                </div>
              ))
            ) : (
              <p className="text-danger">No Choice Answer</p>
            )}

            <a href="#/" className="me-3" onClick={() => handleAddChoiceClick()}>
              Add Choice
            </a>
          </div>
          {warning.length > 0
            ? warning.map((w, index) => (
                <p key={index} className="text-danger">
                  {w}
                </p>
              ))
            : ""}
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={() => handleSubmit()} className={submitBtn ? "btn btn-outline-theme" : "btn btn-outline-theme disabled"}>
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalNewSoalMc;
