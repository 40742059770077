import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import axiosInstance from "../../config/axios";

const parameter = { id: null, idMatch1Main: null, val: "" };

function ModalEditMatch1AnswerChoice({ show, onHide, submitClick, id, idMatchMain }) {
  const [val, setVal] = useState(parameter);
  const [spinner, setSpinner] = useState(true);
  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }
  const handleChange = (e) => {
    setVal({ ...val, val: e.target.value });
  };

  function onSubmit(val) {
    setSpinner(true);
    setVal(parameter);
    submitClick(val);
  }
  function onHideModal() {
    setSpinner(true);
    setVal(parameter);
    onHide();
  }
  function onShow() {
    axiosInstance.get("/question-get/one-matching-jawaban-1/" + id, getHeader()).then((res) => {
      setSpinner(false);
      setVal({
        id: id,
        idMatch1Main: idMatchMain,
        val: res.data.data.jawaban,
      });
    });
  }
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHideModal();
        }}
        onShow={() => onShow()}
      >
        <ModalHeader closeButton>
          <h5 className="modal-title">Edit Answer Choice</h5>
        </ModalHeader>
        <ModalBody>
          {spinner ? (
            <div className="spinner-border"></div>
          ) : (
            <>
              <div className="mb-3">
                <textarea onChange={(e) => handleChange(e)} defaultValue={val.val} className="form-control" />
              </div>
              <div className="alert bg-inverse bg-opacity-15">Please edit answer choice</div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={() => onSubmit(val)} className={val.val ? "btn btn-outline-theme" : "btn btn-outline-theme disabled"}>
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalEditMatch1AnswerChoice;
