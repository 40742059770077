import React, { useState } from "react";
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import axiosInstance from "../../config/axios";

function currentDate() {
  const date = new Date();
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

function ModalEventQr({ show, onHide, data, id, submitClick }) {
  const [qrCode, setQrCode] = useState();
  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  function handleOnShow() {
    setQrCode(data);
  }

  function handleHide() {
    onHide();
  }

  return (
    <>
      <Modal show={show} onShow={() => handleOnShow()} onHide={() => handleHide()}>
        <ModalHeader closeButton>
          <h5 className="modal-title">Event Qr</h5>
        </ModalHeader>
        <ModalBody>
          <div className="text-center">
            <p>Please Scan qr code event or click link bellow</p>
          </div>
          <div style={{ height: "auto", margin: "0 auto", maxWidth: 256, width: "100%" }}>
            <QRCode size={256} style={{ height: "auto", maxWidth: "100%", width: "100%" }} value={data ? data : "-"} viewBox={`0 0 256 256`} />
          </div>
          <div className="text-center mt-3">
            <a href={data ? data : "#/"}>{data ? data : "-"} </a>
          </div>
        </ModalBody>
        <ModalFooter>
          <button onClick={() => submitClick()} type="button" className="btn btn-outline-theme">
            Got it
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalEventQr;
