import { useEffect, useReducer, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";

function reducer(state, action) {
  switch (action.type) {
    case "questionTitle":
      return { ...state, questionTitle: action.value };
    case "author":
      return { ...state, author: action.value };
  }
}
let initialValue = {
  questionTitle: "",
  author: "",
};

function ModalNewQuestion({ show, onHide, id, submitClick }) {
  const [values, dispatch] = useReducer(reducer, initialValue);

  return (
    <>
      <Modal show={show} onHide={() => onHide()}>
        <ModalHeader closeButton>
          <h5 className="modal-title">New Question</h5>
        </ModalHeader>
        <ModalBody>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="questionTitle">
              Question Title
            </label>
            <input onChange={(e) => dispatch({ type: "questionTitle", value: e.target.value })} type="text" className="form-control" placeholder="Product Knowledge Liebherr PC210-1000" id="questionTitle" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="author">
              Author
            </label>
            <input onChange={(e) => dispatch({ type: "author", value: e.target.value })} type="text" className="form-control" placeholder="Valentino Rizal" id="author" />
          </div>
        </ModalBody>
        <ModalFooter>
          <button onClick={() => submitClick(values)} type="button" className="btn btn-outline-theme">
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalNewQuestion;
