import React, { useEffect, useState } from "react";
import { Card, CardBody } from "./../../components/card/card.jsx";
import Pagination2 from "../../components/pagination/pagination2.jsx";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../config/axios.js";
import axiosInstance2 from "../../config/axios2.js";
import DateFormat from "../../components/dateFormater/dateFormat1.js";
import ModalNewQuestion from "../../components/modal/modalNewQuestion.jsx";
import DateTimeFormat from "../../components/dateFormater/dateFormat2.js";
import ModalEventQr from "../../components/modal/modalEventQr.jsx";
import ModalConfirm from "../../components/modal/modalConfirm.jsx";

const trainingPresetData = {
  data: { current_page: "", data: [], first_page_url: "", from: "", last_page: "", last_page_url: "", links: [], next_page_url: "", path: "", per_page: 3, prev_page_url: "", to: "", total: "" },
};

function EventData() {
  const [eventListData, setEventListData] = useState(trainingPresetData);
  const [search1Value, setSearch1Value] = useState("");
  const [miniResume, setMiniResume] = useState(null);
  const [modalNew, setModalNew] = useState(false);
  const [modalQrCode, setModalQrCode] = useState({ show: false, data: "" });
  const [modalDeleteEvent, setModalDeleteEvent] = useState({ show: false, id: null });
  const navigate = useNavigate();

  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  function handleClickNewQuestion() {
    setModalNew(true);
  }
  function handleSubmitNew(val) {
    setModalNew(false);
    axiosInstance
      .post("/question-new/main", val, getHeader())
      .then((res) => {
        if (res.data.status) {
          navigate("/question/detail/" + res.data.data);
        }
      })
      .catch((e) => {});
  }

  function nextClick1(val) {
    axiosInstance2
      .get(val + "&search=" + search1Value, getHeader())
      .then((res) => setEventListData(res.data))
      .catch((e) => {});
  }
  function prevClick1(val) {
    axiosInstance2
      .get(val + "&search=" + search1Value, getHeader())
      .then((res) => setEventListData(res.data))
      .catch((e) => {});
  }
  function submitSearch1(e) {
    e.preventDefault();
    axiosInstance
      .get("/question-event/event-list?search=" + search1Value, getHeader())
      .then((res) => setEventListData(res.data))
      .catch((e) => {});
  }

  function handleQrCodeClick(link) {
    setModalQrCode({ show: true, data: link });
  }

  function handleDeleteEventClick(id) {
    setModalDeleteEvent({ show: true, id: id });
  }
  function handleDeleteConfirmClick(id) {
    setModalDeleteEvent({ show: false, id: id });
    axiosInstance
      .delete("/question-event-delete/event-one/" + id + "?search=" + search1Value, getHeader())
      .then((res) => {
        setEventListData(res.data);
      })
      .catch((e) => {});
  }

  useEffect(() => {
    axiosInstance
      .get("/question-event/event-list", getHeader())
      .then((res) => {
        setEventListData(res.data);
      })
      .catch((e) => {});
  }, []);

  return (
    <>
      <div>
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#/">QUESTIONS</a>
              </li>
              <li className="breadcrumb-item active">Event</li>
            </ul>

            <h1 className="page-header">
              Event List <small></small>
            </h1>
          </div>
        </div>

        <hr className="mb-4" />
        {miniResume ? (
          <div className="mb-md-4 mb-3 d-md-flex">
            {/* <div className="mt-md-0 mt-2"><a href="#/" className="text-inverse text-opacity-75 text-decoration-none"><i className="fa fa-download fa-fw me-1 text-theme"></i> Export</a></div> */}
            <div className="ms-md-4 mt-md-0 mt-2">
              <i className="fas fa-user-plus fa-fw fa-lg me-1 text-theme"></i> {miniResume.monthly.new.qty + " Monthly"}
            </div>
            <div className="ms-md-4 mt-md-0 mt-2">
              <i className="fas fa-user-times user-times fa-lg me-1 text-danger"></i> {miniResume.monthly.out.qty + " Monthly"}
            </div>
            <div className="ms-md-4 mt-md-0 mt-2">
              <i className="fas fa-user-plus fa-fw fa-lg me-1 text-theme"></i> {miniResume.yearly.new.qty + " Yearly"}
            </div>
            <div className="ms-md-4 mt-md-0 mt-2">
              <i className="fas fa-user-times user-times fa-lg me-1 text-danger"></i> {miniResume.yearly.out.qty + " Yearly"}
            </div>
          </div>
        ) : (
          ""
        )}
        <Card>
          <div className="tab-content p-4">
            {/* ----------------All Tab------------------------------------------------------------------ */}
            <div className="tab-pane fade show active" id="allTab">
              <form onSubmit={(e) => submitSearch1(e)}>
                <div className="input-group mb-4">
                  <div className="d-flex justify-content-end">
                    <div className="input-group">
                      <input type="text" onChange={(e) => setSearch1Value(e.target.value)} className="form-control px-35px" placeholder="Search" />
                      <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{ zIndex: 1020 }}>
                        <i className="fa fa-search opacity-5"></i>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-outline-default" type="submit">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>

              {/* ----------------Table-------------- */}

              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th className="border-top-0 pt-0 pb-2">Title</th>
                      <th className="border-top-0 pt-0 pb-2">Creator</th>
                      <th className="border-top-0 pt-0 pb-2">Valid Until</th>
                      <th className="border-top-0 pt-0 pb-2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventListData.data.data.length > 0 ? (
                      eventListData.data.data.map((value, index) => (
                        <tr key={index}>
                          <td className="align-middle">
                            <Link to={"/question/event-detail/" + value.id}>{value.judul}</Link>
                          </td>
                          <td className="align-middle">{value.user.name}</td>
                          <td className="align-middle">{DateTimeFormat(value.validUntil)}</td>
                          <td className="align-middle">
                            <a onClick={() => handleQrCodeClick(eventListData.question_baseUrl + value.eventCode)} href="#/" className="btn btn-outline-default btn-sm mx-1">
                              <i className="fas fa-link text-theme"></i>
                            </a>
                            <a onClick={() => handleDeleteEventClick(value.id)} href="#/" className="btn btn-outline-default btn-sm">
                              <i className="fas fa-trash-alt text-danger"></i>
                            </a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="align-middle">no Data</td>
                        <td className="align-middle"></td>
                        <td className="align-middle"></td>
                        <td className="align-middle"></td>
                        <td className="align-middle"></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* ----------------End Table-------------- */}

              {eventListData.data.data.length > 0 ? <Pagination2 pageInfo={eventListData.data} nextClick={(val) => nextClick1(val)} prevClick={(val) => prevClick1(val)} /> : ""}
            </div>
            {/* ----------------End All Tab------------------------------------------------------------------ */}
          </div>
        </Card>
      </div>
      <ModalEventQr show={modalQrCode.show} data={modalQrCode.data} onHide={() => setModalQrCode({ ...modalQrCode, show: false })} submitClick={() => setModalQrCode({ ...modalQrCode, show: false })} />
      <ModalConfirm
        clicked={(id) => handleDeleteConfirmClick(id)}
        show={modalDeleteEvent.show}
        id={modalDeleteEvent.id}
        onHide={() => setModalDeleteEvent({ ...modalDeleteEvent, show: false })}
        theme={"danger"}
        text={"Are you sure want to delete this event ??"}
        title={"Warning"}
      />
    </>
  );
}

export default EventData;
