
function DonatOptions(){
    var themeColor = (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim();
    var themeColorRgb = (getComputedStyle(document.body).getPropertyValue('--bs-theme-rgb')).trim();
          return {
            colors: ['rgba('+ themeColorRgb + ', .15)',themeColor],
            chart: { sparkline: { enabled: true } },
            stroke: { show: false },
            tooltip: { x: { show: false }, y: { title: { formatter: function (seriesName) { return '' } } } },}

}

export default DonatOptions