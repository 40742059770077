import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.min.css";
import { Card, CardBody, CardExpandToggler } from "./../../components/card/card.jsx";
import Chart from "react-apexcharts";
import Api from "../../config/Api.jsx";
import ApexChart from "../../components/chart/apexChart.jsx";
import ModalLoadingPage from "../../components/modal/loadingPage.jsx";
import ApexChartOneData from "../../components/chart/apexChartOneData.jsx";
import PyramidChart from "../../components/chart/pyramidChart.jsx";

function Dashboard() {
  // server chart
  const [statsData, setStatsData] = useState([]);
  const [serverData, setServerData] = useState([]);
  const [countryData, setCountryData] = useState();
  const [sourceData, setSourceData] = useState();
  const [sourceChartData, setSourceChartData] = useState();
  const [productData, setProductData] = useState();
  const [activityLogData, setActivityLogData] = useState();
  const [chartOptions, setChartOptions] = useState(getChartOptions());
  // const [serverChartOptions, setServerChartOptions] = useState(getServerChartOptions([]));
  const [xAxisReadChart, setXaxisReadChart] = useState([]);
  const [readChartData, setReadChartData] = useState(false);

  const [pyramid, setPyramid] = useState(false);

  const [xAxixManpowerLogChart, setXAxixManpowerLogChart] = useState([]);
  const [logManpowerData, setLogManpowerData] = useState(false);

  const [loadingModal, setLoadingModal] = useState({ show: true, hide: false });

  function getChartOptions() {
    var themeColor = getComputedStyle(document.body).getPropertyValue("--bs-theme").trim();
    var themeColorRgb = getComputedStyle(document.body).getPropertyValue("--bs-theme-rgb").trim();

    return {
      bar: {
        colors: [themeColor],
        chart: { sparkline: { enabled: true } },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      line: {
        colors: [themeColor],
        chart: { sparkline: { enabled: true } },
        stroke: { curve: "straight", width: 2 },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      pie: {
        colors: ["rgba(" + themeColorRgb + ", 1)", "rgba(" + themeColorRgb + ", .75)", "rgba(" + themeColorRgb + ", .5)"],
        chart: { sparkline: { enabled: true } },
        stroke: { show: false },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      donut: {
        // colors: ['rgba('+ themeColorRgb + ', .15)', 'rgba('+ themeColorRgb + ', .35)', 'rgba('+ themeColorRgb + ', .55)', 'rgba('+ themeColorRgb + ', .75)', 'rgba('+ themeColorRgb + ', .95)'],
        colors: ["rgba(" + themeColorRgb + ", .15)", themeColor],
        chart: { sparkline: { enabled: true } },
        stroke: { show: false },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
    };
  }

  //   function getServerChartOptions(a) {
  //   	var borderColor = (getComputedStyle(document.body).getPropertyValue('--bs-border-color')).trim();
  //   	var bodyColor = (getComputedStyle(document.body).getPropertyValue('--bs-body-color')).trim();
  //   	var inverseRgb = (getComputedStyle(document.body).getPropertyValue('--bs-inverse-rgb')).trim();
  //   	var themeColor = (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim();
  // 	var themeFont = (getComputedStyle(document.body).getPropertyValue('--bs-body-font-family')).trim();

  //   	return {
  //     	chart: { toolbar: { show: false } },
  //    		plotOptions: { bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded' } },
  //     	dataLabels: { enabled: false },
  // 			grid: { show: true, borderColor: borderColor },
  // 			stroke: { show: false },
  //     	colors: ['rgba('+ inverseRgb +', .15)', themeColor],
  // 			legend: { fontFamily: themeFont, labels: { colors: bodyColor } },
  // 			xaxis: {
  // 				categories: a,
  // 				labels: { show: false },
  // 				axisBorder: { show: true, color: borderColor, height: 1, width: '100%', 	offsetX: 0, offsetY: -1 },
  // 				axisTicks: { show: true, borderType: 'solid', color: borderColor, height: 6, offsetX: 0, offsetY: 0 },
  // 			},
  // 			yaxis: {
  // 				labels: { style: { colors: bodyColor, fontSize: '12px', fontFamily: themeFont, fontWeight: 400, cssClass: 'apexcharts-xaxis-label' } }
  // 			},
  // 			fill: { opacity: .65 },
  // 			tooltip: { y: { formatter: function (val) { return val + " %" } } }
  //   	};
  //   }

  //   function renderMap() {
  // 		var inverse = (getComputedStyle(document.body).getPropertyValue('--bs-inverse')).trim();
  // 		var themeColor = (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim();
  // 		const map = document.getElementById('world-map');
  // 		const mapElm = document.querySelectorAll('.jvm-tooltip');

  // 		if (map) {
  // 			for (let i = 0; i < mapElm.length; i++) {
  // 				mapElm[i].remove();
  // 			}
  // 			map.innerHTML = '';

  // 			new jsVectorMap({
  // 				selector: '#world-map',
  // 				map: 'world',
  // 				zoomButtons: true,
  // 				normalizeFunction: 'polynomial',
  // 				hoverOpacity: 0.5,
  // 				hoverColor: false,
  // 				zoomOnScroll: false,
  // 				series: { regions: [{ normalizeFunction: 'polynomial' }] },
  // 				labels: { markers: { render: (marker) => marker.name } },
  // 				focusOn: { x: 0.5, y: 0.5, scale: 1 },
  // 				markerStyle: { initial: { fill: themeColor, stroke: 'none', r: 5 }, hover: { fill: themeColor } },
  // 				regionStyle: { initial: { fill: inverse, fillOpacity: 0.35, stroke: 'none', strokeWidth: 0.4, strokeOpacity: 1 }, hover: { fillOpacity: 0.5 } },
  // 				backgroundColor: 'transparent',
  // 			});
  // 		}
  //   }

  useEffect(() => {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
      validateStatus: false,
    };
    Api.get("/stats", headers)
      .then((res) => {
        setStatsData(res.data.data);
      })
      .catch(() => setStatsData([]));
    Api.get("/server", headers)
      .then((res) => {
        setReadChartData(res.data.series);
        setXaxisReadChart(res.data.category);
      })
      .catch(() => {
        setReadChartData([]);
        setXaxisReadChart([]);
      });
    Api.get("/resumeRead", headers)
      .then((res) => {
        setServerData(res.data.data);
        setLoadingModal({ show: false, hide: false });
      })
      .catch(() => {
        setServerData([]);
      });
    Api.get("/gradeqty", headers).then((res) => {
      setSourceData(res.data.data.listGrade);
      setPyramid(res.data.data.pyramid);
    });
    Api.get("/qtymanpowerlog", headers).then((res) => {
      setLogManpowerData(res.data.data.series);
      setXAxixManpowerLogChart(res.data.data.xAxis);
    });
    Api.get("/highlvl", headers).then((res) => {
      setCountryData(res.data.data);
    });
    setLoadingModal({ show: true, hide: false });
    // fetch('/assets/data/dashboard/stats.json').then(res => res.json()).then((result) => { setStatsData(result);});
    // fetch('/assets/data/dashboard/country.json').then(res => res.json()).then((result) => { setCountryData(result); });
    // fetch('/assets/data/dashboard/server.json').then(res => res.json()).then((result) => { setServerData(result); });
    // fetch('/assets/data/dashboard/source.json').then(res => res.json()).then((result) => { setSourceData(result); });
    fetch("/assets/data/dashboard/source-chart.json")
      .then((res) => res.json())
      .then((result) => {
        setSourceChartData(result);
      });
    fetch("/assets/data/dashboard/product.json")
      .then((res) => res.json())
      .then((result) => {
        setProductData(result);
      });
    fetch("/assets/data/dashboard/activity-log.json")
      .then((res) => res.json())
      .then((result) => {
        setActivityLogData(result);
      });
    // renderMap();
    document.addEventListener("theme-reload", () => {
      // setServerChartOptions(getServerChartOptions([]));
      setChartOptions(getChartOptions());
      // renderMap();
    });

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div>
        <div className="row">
          <p>
            Following are the results of mechanical development achievements in real time. data taken from the{" "}
            <a href="https://ppdmaco.com" target="_blank" rel="noreferrer">
              ppdmaco.com
            </a>{" "}
            database system. All calculations are outside of the apprentice mechanic / MPP.
          </p>
          {statsData &&
            statsData.length > 0 &&
            statsData.map((stat, index) => (
              <div className="col-xl-3 col-lg-6" key={index}>
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1">{stat.title}</span>
                      <CardExpandToggler />
                    </div>
                    <div className="row align-items-center mb-2">
                      <div className="col-7">
                        <h3 className="mb-0">{stat.total}</h3>
                      </div>
                      <div className="col-5">
                        <div className="mt-n2">
                          <Chart type={stat.chartType} height={stat.chartHeight} options={chartOptions[stat.chartType]} series={stat.chartData} />
                        </div>
                      </div>
                    </div>
                    <div className="small text-inverse text-opacity-50 text-truncate">
                      {stat.info.length > 0 &&
                        stat.info.map((info, index) => (
                          <div key={index}>
                            <i className={info.icon}></i> {info.text}
                          </div>
                        ))}
                    </div>
                  </CardBody>
                </Card>
              </div>
            ))}
        </div>
        <div className="row">
          <div className="col-xl-6">
            {" "}
            {/* Sebelumnya Nilainya col-xl-6 */}
            <Card className="mb-3">
              <CardBody>
                <div className="d-flex fw-bold small mb-3">
                  <span className="flex-grow-1">MECHANIC READINESS</span>
                  <CardExpandToggler />
                </div>
                <div className="ratio ratio-21x9 mb-3">
                  {/* <Chart type='bar' height="100%"  options={serverChartOptions} series={readChartData} /> */}

                  {readChartData ? <ApexChart type="bar" height="100%" series={readChartData} xLable={xAxisReadChart} /> : ""}
                </div>
                <div className="row">
                  {serverData && serverData.length > 0 ? (
                    serverData.map((server, index) => (
                      <div className="col-lg-6 mb-3 mb-lg-0" key={index}>
                        <div className="d-flex">
                          <div className="w-50px pt-3">
                            <Chart type={server.chartType} height={server.chartHeight} options={chartOptions[server.chartType]} series={server.chartData} />
                          </div>
                          <div className="ps-3 flex-1">
                            <div className="fs-10px fw-bold text-inverse text-opacity-50 mb-1">{server.name}</div>
                            <div className="mb-2 fs-5 text-truncate">{server.total}</div>
                            <div className="progress h-3px bg-inverse-transparent-2 mb-1">
                              <div className="progress-bar bg-theme" style={{ width: server.progress }}></div>
                            </div>
                            <div className="fs-11px text-inverse text-opacity-50 mb-4 text-truncate">{server.time}</div>
                            <div className="fs-11px text-danger text-opacity-50 mb-1 text-truncate">{server.status}</div>
                            {server.info.length > 0 ? (
                              server.info.map((info, index) => (
                                <div className="d-flex align-items-center small" key={index}>
                                  <i className={"bi bi-circle-fill fs-6px me-2 " + info.class}></i>
                                  <div className="flex-1">{info.title}</div>
                                  <div>{info.value}</div>
                                </div>
                              ))
                            ) : (
                              <div>No records found</div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-lg-12">No records found</div>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-xl-6">
            <Card className="mb-3">
              <CardBody>
                <div className="d-flex fw-bold small mb-3">
                  <span className="flex-grow-1">MECHANIC QUANTITY</span>
                  <CardExpandToggler />
                </div>
                <div className="ratio ratio-21x9 mb-3">
                  {/* <div id="world-map" className="jvectormap-without-padding"></div> */}

                  {logManpowerData ? <ApexChartOneData type="area" height="100%" series={logManpowerData} xLable={xAxixManpowerLogChart} /> : ""}
                </div>
                <div className="row gx-4">
                  <div className="col-xxl-6 mb-3 mb-lg-0">
                    <table className="w-100 small mb-0 text-truncate text-inverse text-opacity-60">
                      <thead>
                        <tr className="text-inverse text-opacity-75">
                          <th className="w-70">SUB. SECT.</th>
                          <th className="w-20 text-end">HI LVL.</th>
                          <th className="w-10 text-end">QTY.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {countryData && countryData.length > 0 ? (
                          countryData.map((country, index) => (
                            <tr key={index} className={country.class}>
                              <td>{country.name}</td>
                              <td className="text-end">{country.visits}</td>
                              <td className="text-end">{country.pct}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3">No records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-xxl-6">
                    <Card>
                      <CardBody className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="w-130px">
                            {/* <div className="col-lg-12"> */}
                            {/* { sourceChartData && sourceChartData.length > 0 && (
														<Chart type="donut" height="70" options={chartOptions['donut']} series={sourceChartData} />
													)} */}
                            {!pyramid ? "" : <PyramidChart type="bar" series={pyramid.series} height={125} xAxis={pyramid.xAxis} />}
                          </div>
                          <div className="flex-1 ps-2">
                            <table className="w-100 small mb-0 text-inverse text-opacity-60">
                              <tbody>
                                {sourceData && sourceData.length > 0 ? (
                                  sourceData.map((source, index) => (
                                    <tr key={index}>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <div className={"w-6px h-6px rounded-pill me-2 " + source.class}></div>
                                          {source.name}
                                        </div>
                                      </td>
                                      <td className="text-end">{source.percentage + "%"}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="2">No records found</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

          {/* <div className="col-xl-6">
					<Card className="mb-3">
						<CardBody>
							<div className="d-flex fw-bold small mb-3">
								<span className="flex-grow-1">TOP PRODUCTS</span>
								<CardExpandToggler />
							</div>
							<div className="table-responsive">
								<table className="w-100 mb-0 small align-middle text-nowrap">
									<tbody>
										{productData && productData.length > 0 ? (productData.map((product, index) => (
											<tr key={index}>
												<td>
													<div className="d-flex">
														<div className={'position-relative' + ((index < (productData.length - 1)) ? ' mb-2' : '')}>
															<div className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-60px" style={{ backgroundImage: 'url('+ product.img +')'}}></div>
															<div className="position-absolute top-0 start-0">
																<span className={'badge rounded-0 d-flex align-items-center justify-content-center w-20px h-20px' + ((index < 3) ? ' bg-theme text-theme-900' : ' bg-black bg-opacity-50')}>{index + 1}</span>
															</div>
														</div>
														<div className="flex-1 ps-3">
															<div className="mb-1"><small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">{product.sku}</small></div>
															<div className="fw-500 text-inverse">{product.title}</div>
															{product.price}
														</div>
													</div>
												</td>
												<td>
													<table className={((index < (productData.length - 1)) ? 'mb-2' : '')}>
														<tbody>
															<tr>
																<td className="pe-3">QTY:</td>
																<td className="text-inverse text-opacity-75 fw-500">{product.qty}</td>
															</tr>
															<tr>
																<td className="pe-3">REVENUE:</td>
																<td className="text-inverse text-opacity-75 fw-500">{product.revenue}</td>
															</tr>
															<tr>
																<td className="pe-3 text-nowrap">PROFIT:</td>
																<td className="text-inverse text-opacity-75 fw-500">{product.profit}</td>
															</tr>
														</tbody>
													</table>
												</td>
												<td><a href="#/" className="text-decoration-none text-inverse"><i className="bi bi-search"></i></a></td>
											</tr>
										))) : (
											<tr>
												<td colSpan="5">No records found</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</CardBody>
					</Card>
				</div> */}
        </div>
      </div>

      <ModalLoadingPage show={loadingModal.show} hide={loadingModal.hide} />
    </>
  );
}

export default Dashboard;
