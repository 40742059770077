import { Modal, ModalHeader } from'react-bootstrap';


function ModalLoading({show,onHide,title,text,value}){
    return (
        <>
            <Modal className='modal modal-cover' show={show} onHide={onHide} backdrop="static" keyboard={false}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <ModalHeader>
                        <h3 className='modal-title'>{title}</h3>
                    </ModalHeader>
                    <div className="modal-body">
                        <p className="mb-3">
                            {text}
                        </p>
                        <div className="progress">
                            <div className="progress-bar" style={{width:value+'%'}}>{value+'%'}</div>
                        </div>
                        <div className="pt-3">
                        </div>
                    </div>

                </div>
            </div>
            </Modal>
        </>
    )
}

export default ModalLoading