import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import axiosInstance from "../../config/axios";

const parameter = { id: "", val: "" };

function ModalEditMatch1Main({ show, onHide, submitClick, id }) {
  const [val, setVal] = useState(parameter);
  const [spinner, setSpinner] = useState(true);

  const handleChange = (e) => {
    setVal({ id: id, val: e.target.value });
  };
  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  function handleSubmit(val) {
    setSpinner(true);
    setVal(parameter);
    submitClick(val);
  }
  function onShowModal() {
    axiosInstance
      .get("/question-get/one-matching-main-1/" + id, getHeader())
      .then((res) => {
        setVal({ id: id, val: res.data.data.petunjuk });
        setSpinner(false);
      })
      .catch((e) => {});
  }
  function onHideModal() {
    setSpinner(true);
    setVal(parameter);
    onHide();
  }
  return (
    <>
      <Modal show={show} onHide={() => onHideModal()} onShow={() => onShowModal()}>
        <ModalHeader closeButton>
          <h5 className="modal-title">Edit Question Instruction</h5>
        </ModalHeader>
        <ModalBody>
          {spinner ? (
            <div className="spinner-border"></div>
          ) : (
            <>
              <div className="mb-3">
                <textarea onChange={(e) => handleChange(e)} className="form-control" defaultValue={val.val} />
              </div>
              <div className="alert bg-inverse bg-opacity-15">Please edit question instruction base on your question characteristic</div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={() => handleSubmit(val)} className={val.val ? "btn btn-outline-theme" : "btn btn-outline-theme disabled"}>
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalEditMatch1Main;
