import ReactApexChart from "react-apexcharts";

function PyramidChart({type,height,series,xAxis}){
    var themeColor = (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim();
      const state = {
            colors: [themeColor],
            chart: { sparkline: { enabled: true } },
            tooltip: { x: { show: false }, y: { title: { formatter: function (seriesName) { return '' } } } },
            dataLabels: {
                      enabled: true,
                      formatter: function (val, opt) {
                                return opt.w.globals.labels[opt.dataPointIndex] 
                              },
            },
            plotOptions: {
              bar: {
                borderRadius: 0,
                horizontal: true,
                distributed: true,
                barHeight: '80%',
                isFunnel: true,
              },
            },
            xaxis: {
              categories: xAxis,
            },
        //   tooltip: { x: { show: false }, y: { title: { formatter: function (seriesName) { return '' } } } },
        //   options: {
        //     chart: {
        //       type: 'bar',
        //       height: 350,
        //     },
        //     colors: [themeColor],
        //     // dataLabels: {
        //     //   enabled: true,
        //     //   formatter: function (val, opt) {
        //     //     return opt.w.globals.labels[opt.dataPointIndex] 
        //     //   },
        //     //   dropShadow: {
        //     //     enabled: true,
        //     //   },
        //     // },
        //     title: {
        //       text: 'Pyramid Chart',
        //       align: 'middle',
        //     },
        //     // legend: {
        //     //   show: false,
        //     // },
        //   },
        
        
        };

        // const series=[
        //     {
        //         name: "",
        //         data: [200, 330, 548, 740, 880, 990, 1100, 1380],
        //     },
        // ]

    return (
        <ReactApexChart options={state} series={series} type={type} height={height} />
    )
  }

  export default PyramidChart

