import { useEffect, useState } from "react";
import axiosInstance from "../../config/axios";

function ImageHandler({ url }) {
  const [blob, setBlob] = useState();
  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  useEffect(() => {
    axiosInstance
      .get(url, getHeader())
      .then((res) => {
        setBlob(res.data);
      })
      .catch((e) => {});
  }, [url]);

  return (
    <>
      <img src={blob} className="img-thumbnail" alt="..."></img>
    </>
  );
}
export default ImageHandler;
