import React, { useEffect, useState } from "react";
import { Card, CardBody } from "./../../components/card/card.jsx";
import { NavScrollTo } from "./../../components/nav-scroll-to/nav-scroll-to.jsx";
import ModalEditText from "../../components/modal/modalEditText.jsx";
import { useParams } from "react-router-dom";
import DiffDate from "../../composables/ageCounter.js";
import ModalEditSelect from "../../components/modal/modalEditSelect.jsx";
import ModalEditDate from "../../components/modal/modalEditDate.jsx";
import ModalEditFile from "../../components/modal/modalEditFile.jsx";
import ModalEditStatus from "../../components/modal/modalEditForStatusManpower.jsx";
import axiosInstance from "../../config/axios.js";

const dataModalEdit = {
  name: {
    title: "Edit Name",
    text: "Make sure the name is written correctly. there are several names that are often written incorrectly: Muhammad, Muchamad, Mochammad, Achmad, Akhmad, Ahmad",
    data: { id: "", val: "", edit: "name" },
  },
  nrp: {
    title: "Edit NRP",
    text: "Special NRP only for PT. SIS employees in 8 digit format. for non-PT. SIS employees can fill in the NRP freely",
    data: { id: "", val: "", edit: "nrp" },
  },
  mp: {
    title: "Mine Permit Number Edit",
    text: "singgihksdjflaksdjla ksdfsdfasdfasdfasdfa sdfasfqwefasdfwefweasdfasgqwefasdfasdfas",
    data: { id: "", val: "", edit: "mp" },
  },
  pob: {
    title: "Place Of Birth Edit",
    text: "singgihksdjflaksdjla ksdfsdfasdfasdfasdfa sdfasfqwefasdfwefweasdfasgqwefasdfasdfas",
    data: { id: "", val: "", edit: "pob" },
  },
  phone1: {
    title: "Phone Number 1 Edit",
    text: "singgihksdjflaksdjla ksdfsdfasdfasdfasdfa sdfasfqwefasdfwefweasdfasgqwefasdfasdfas",
    data: { id: "", val: "", edit: "phone1" },
  },
  phone2: {
    title: "Phone Number 2 Edit",
    text: "singgihksdjflaksdjla ksdfsdfasdfasdfasdfa sdfasfqwefasdfwefweasdfasgqwefasdfasdfas",
    data: { id: "", val: "", edit: "phone2" },
  },
  email: {
    title: "Email Edit",
    text: "singgihksdjflaksdjla ksdfsdfasdfasdfasdfa sdfasfqwefasdfwefweasdfasgqwefasdfasdfas",
    data: { id: "", val: "", edit: "email" },
  },
  grade: {
    title: "Grade Edit",
    text: "The grade must be filled in especially for PT. Saptaindra Sejati mechanic positions at Maco job site with a grade range of L1 to L11",
    data: { id: "", val: "", edit: "grade" },
  },
  remark: {
    title: "Remark Edit",
    text: "singgihksdjflaksdjla ksdfsdfasdfasdfasdfa sdfasfqwefasdfwefweasdfasgqwefasdfasdfas",
    data: { id: "", val: "", edit: "remark" },
  },
  specialist: {
    title: "Specialist",
    text: "Specialists need to be filled for Maco job site mechanics for development and career path purposes",
    data: { id: "", val: "", edit: "specialist" },
  },
  company: {
    title: "Company Edit",
    text: "Please choose company",
    data: { id: "", val: "", edit: "company" },
  },
  jobArea: {
    title: "Job Area Edit",
    text: "The Maco project consists of three areas, namely Mining, Hauling and Port",
    data: { id: "", val: "", edit: "jobArea" },
  },
  section: {
    title: "Section Edit",
    text: "Enter the section name",
    data: { id: "", val: "", edit: "section" },
  },
  subSection: {
    title: "Sub-Section Edit",
    text: "A subsection is part of a section. Sections are led by section heads, while subsections are led by unit heads",
    data: { id: "", val: "", edit: "subSection" },
  },
  structural: {
    title: "Structural Position Edit",
    text: "Structural position refers to the specific role or position within the formal structure of an organization",
    data: { id: "", val: "", edit: "structural" },
  },
  functional: {
    title: "Functional Position Edit",
    text: "Functional Position is a term used in the realm of human resource management and organizational behavior, referring to an employee’s role within the specific context of their job functions rather than hierarchical standing. It transcends titles and focuses on tasks, responsibilities, and skills associated with a particular role",
    data: { id: "", val: "", edit: "functional" },
  },
  status: {
    title: "Status Edit",
    text: "Adjust to the current employee status",
    data: { id: "", val: "", edit: "status" },
  },
  joinDate: {
    title: "Status Edit",
    text: "Enter the date corresponding to the agreement date when this person became an employee",
    data: { id: "", val: "", edit: "joinDate" },
  },
  joinMaco: {
    title: "Join To Maco Edit",
    text: "Enter the date this employee first entered the MACO area",
    data: { id: "", val: "", edit: "joinMaco" },
  },
  dob: {
    title: "Date of Birth Edit",
    text: "Enter date of birth",
    data: { id: "", val: "", edit: "dob" },
  },
  profileImage: {
    title: "Profile Image Change",
    text: "Please select the image you want. *.jpg, *.png image format supported ",
    data: { id: "", val: "", edit: "profileImage" },
  },
};

function EditManpower() {
  const [modalText, setModalText] = useState(false);
  const [modalSelect, setModalSelect] = useState(false);
  const [headerSelect, setHeaderSelect] = useState();
  const [modalDate, setModalDate] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalData, setModalData] = useState();
  const [modalFile, setModalFile] = useState();
  const [manpower, setManpower] = useState({});
  const params = useParams();

  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
      validateStatus: false,
    };
    return headers;
  }

  function fetchingDataText(e) {
    axiosInstance
      .get("/manpowerv2/oneData?id=" + params.id, getHeader())
      .then((res) => {
        let data = res.data;
        setModalData({ ...dataModalEdit[e], data: { id: data.id, val: data[e], edit: e } });
        setModalText(true);
      })
      .catch((e) => {});
  }
  function fetchingDataSelect(e) {
    axiosInstance
      .get("/manpowerv2/oneData?id=" + params.id, getHeader())
      .then((res) => {
        let data = res.data;
        setModalData({ ...dataModalEdit[e], data: { id: data.id, val: data[e], edit: e } });
        setHeaderSelect(getHeader());
        setModalSelect(true);
      })
      .catch((e) => {});
  }
  function fetchingDataDate(e) {
    axiosInstance
      .get("/manpowerv2/oneData?id=" + params.id, getHeader())
      .then((res) => {
        let data = res.data;
        setModalData({ ...dataModalEdit[e], data: { id: data.id, val: data[e], edit: e } });
        setModalDate(true);
      })
      .catch((e) => {});
  }
  function fetchingDataFile(e) {
    axiosInstance
      .get("/manpowerv2/oneData?id=" + params.id, getHeader())
      .then((res) => {
        let data = res.data;
        setModalData({ ...dataModalEdit[e], data: { id: data.id, val: data[e], edit: e } });
        setModalFile(true);
      })
      .catch((e) => {});
  }

  function updateData(e) {
    axiosInstance
      .put("/manpowerv2edit", e, getHeader())
      .then((res) => {
        if (res.data.status === "success") {
          axiosInstance.get("/manpowerv2/oneData?id=" + params.id, getHeader()).then((res) => setManpower(res.data));
        }
      })
      .catch((e) => {});
  }
  function updateFile(e) {
    axiosInstance
      .post("/postImage/profileImageEdit", e, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          axiosInstance.get("/manpowerv2/oneData?id=" + params.id, getHeader()).then((res) => setManpower(res.data));
        }
      })
      .catch((e) => {});
  }

  function handleEditStatus() {
    axiosInstance
      .get("/manpowerv2/oneData?id=" + params.id, getHeader())
      .then((res) => {
        let data = res.data;
        setModalData({ ...dataModalEdit["status"], data: { id: data.id, val: data["status"], edit: "status" } });
        setHeaderSelect(getHeader());
        setModalStatus(true);
      })
      .catch((e) => {});
  }

  function handleEdit(e) {
    switch (e) {
      case "name":
        fetchingDataText(e);
        break;
      case "nrp":
        fetchingDataText(e);
        break;
      case "mp":
        fetchingDataText(e);
        break;
      case "pob":
        fetchingDataText(e);
        break;
      case "phone1":
        fetchingDataText(e);
        break;
      case "phone2":
        fetchingDataText(e);
        break;
      case "email":
        fetchingDataText(e);
        break;
      case "specialist":
        fetchingDataText(e);
        break;
      case "grade":
        fetchingDataText(e);
        break;
      case "remark":
        fetchingDataText(e);
        break;
      case "company":
        fetchingDataSelect(e);
        break;
      case "jobArea":
        fetchingDataSelect(e);
        break;
      case "section":
        fetchingDataSelect(e);
        break;
      case "subSection":
        fetchingDataSelect(e);
        break;
      case "structural":
        fetchingDataSelect(e);
        break;
      case "functional":
        fetchingDataSelect(e);
        break;
      case "joinDate":
        fetchingDataDate(e);
        break;
      case "joinMaco":
        fetchingDataDate(e);
        break;
      case "dob":
        fetchingDataDate(e);
        break;
      case "profileImage":
        fetchingDataFile(e);
        break;

      default:
        break;
    }
  }

  function submitClick(data) {
    setModalText(false);
    setModalSelect(false);
    setModalDate(false);
    setModalStatus(false);
    updateData(data);
  }

  function submitClickFile(data) {
    setModalFile(false);
    updateFile(data);
  }

  function getUmur(val) {
    const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
    const dob = new Date(val);
    const age = DiffDate(val);
    const dobString = dob.toLocaleDateString("en-us", options);
    return `${dobString} (${age.year} years old, ${age.month} Month)`;
  }

  function getWp(val) {
    const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
    const dob = new Date(val);
    const age = DiffDate(val);
    const dobString = dob.toLocaleDateString("en-us", options);
    return `${dobString} (${age.year} years ${age.month} Month working period)`;
  }

  useEffect(() => {
    axiosInstance
      .get("/manpowerv2/oneData?id=" + params.id, getHeader())
      .then((res) => setManpower(res.data))
      .catch((e) => {});
  }, []);
  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="row">
              <div className="col-xl-9">
                <div id="basic" className="mb-5">
                  <h4>
                    <i className="far fa-user fa-fw text-theme"></i> Basic
                  </h4>
                  <p>Manpower minimum data requirement</p>
                  <Card>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Name</div>
                          <div className="text-inverse text-opacity-50">{manpower.name ? manpower.name : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("name")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>NRP</div>
                          <div className="text-inverse text-opacity-50">{manpower.nrp ? manpower.nrp : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("nrp")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Mine Permit Number</div>
                          <div className="text-inverse text-opacity-50">{manpower.mp ? manpower.mp : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("mp")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Company</div>
                          <div className="text-inverse text-opacity-50">{manpower.company ? manpower.company : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("company")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Job Area</div>
                          <div className="text-inverse text-opacity-50">{manpower.jobArea ? manpower.jobArea : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("jobArea")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Section</div>
                          <div className="text-inverse text-opacity-50">{manpower.section ? manpower.section : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("section")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Sub Section</div>
                          <div className="text-inverse text-opacity-50">{manpower.subSection ? manpower.subSection : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("subSection")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Structural Position</div>
                          <div className="text-inverse text-opacity-50">{manpower.structural ? manpower.structural : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("structural")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Functional Position</div>
                          <div className="text-inverse text-opacity-50">{manpower.functional ? manpower.functional : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("functional")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>

                <div id="history" className="mb-5">
                  <h4>
                    <i className="fas fa-map-signs fa-fw text-theme"></i> History
                  </h4>
                  <p>Manpower history</p>
                  <Card>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Join Date</div>
                          <div className="text-inverse text-opacity-50">{manpower.joinDate ? getWp(manpower.joinDate) : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("joinDate")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Join in Maco</div>
                          <div className="text-inverse text-opacity-50">{manpower.joinMaco ? manpower.joinMaco : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("joinMaco")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Place of Birth</div>
                          <div className="text-inverse text-opacity-50">{manpower.pob ? manpower.pob : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("pob")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Date of Birth</div>
                          <div className="text-inverse text-opacity-50">{manpower.dob ? getUmur(manpower.dob) : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("dob")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>

                <div id="contacts" className="mb-5">
                  <h4>
                    <i className="fas fa-phone fa-fw text-theme"></i> Contacts
                  </h4>
                  <p>Manpower contacts</p>
                  <Card>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Phone 1</div>
                          <div className="text-inverse text-opacity-50">{manpower.phone1 ? manpower.phone1 : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("phone1")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Phone 2</div>
                          <div className="text-inverse text-opacity-50">{manpower.phone2 ? manpower.phone2 : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("phone2")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Email</div>
                          <div className="text-inverse text-opacity-50">{manpower.email ? manpower.email : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("email")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>

                <div id="career" className="mb-5">
                  <h4>
                    <i className="fas fa-chart-line fa-fw text-theme"></i> Career and Development
                  </h4>
                  <p>This data for career needs</p>
                  <Card>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Specialist</div>
                          <div className="text-inverse text-opacity-50">{manpower.specialist ? manpower.specialist : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("specialist")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Grade</div>
                          <div className="text-inverse text-opacity-50">{manpower.grade ? manpower.grade : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("grade")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>

                <div id="additional" className="mb-5">
                  <h4>
                    <i className="far fa-paper-plane fa-fw text-theme"></i> Additional
                  </h4>
                  <p>Manpower additional data</p>
                  <Card>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Status</div>
                          <div className="text-inverse text-opacity-50">{manpower.status ? manpower.status : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEditStatus()} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div>Remark</div>
                          <div className="text-inverse text-opacity-50">{manpower.remark ? manpower.remark : "-"}</div>
                        </div>
                        <div className="w-100px">
                          <a href="#/" onClick={() => handleEdit("remark")} className="btn btn-outline-default w-100px">
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                  </Card>
                  <Card>
                    <CardBody>
                      <div className="row gx-0 align-items-center">
                        <div className="col-md-4">
                          <img src={manpower.profileImage ? manpower.profileImage : ""} alt="" className="card-img rounded-0" />
                        </div>
                        <div className="col-md-8">
                          <CardBody>
                            <h5 className="card-title">Profile Image</h5>
                            <p className="card-text">If you want to change your profile image, please click the edit button. Profile image format *.jpg, *.png</p>
                            {/* <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p> */}
                            <div className="w-100px">
                              <a href="#/" onClick={() => handleEdit("profileImage")} className="btn btn-outline-default w-100px">
                                Edit
                              </a>
                            </div>
                          </CardBody>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>

                <ModalEditText show={modalText} data={modalData} submitClick={(e) => submitClick(e)} onHide={() => setModalText(false)} />
                <ModalEditSelect headerFetch={headerSelect} show={modalSelect} data={modalData} submitClick={(e) => submitClick(e)} onHide={() => setModalSelect(false)} />
                <ModalEditDate show={modalDate} data={modalData} submitClick={(e) => submitClick(e)} onHide={() => setModalDate(false)} />
                <ModalEditFile show={modalFile} data={modalData} submitClick={(e) => submitClickFile(e)} onHide={() => setModalFile(false)} />
                <ModalEditStatus headerFetch={headerSelect} show={modalStatus} data={modalData} submitClick={(e) => submitClick(e)} onHide={() => setModalStatus(false)} />
              </div>

              <div className="col-xl-3">
                <NavScrollTo>
                  <nav className="nav">
                    <a className="nav-link" href="#basic" data-toggle="scroll-to">
                      Basic
                    </a>
                    <a className="nav-link" href="#history" data-toggle="scroll-to">
                      History
                    </a>
                    <a className="nav-link" href="#contacts" data-toggle="scroll-to">
                      Contacts
                    </a>
                    <a className="nav-link" href="#career" data-toggle="scroll-to">
                      Career and Development
                    </a>
                    <a className="nav-link" href="#additional" data-toggle="scroll-to">
                      Additional
                    </a>
                  </nav>
                </NavScrollTo>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditManpower;
