import { useState } from "react";
import Select from "react-select";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import axiosInstance from "../../config/axios";

const parameter = { id: null, question: "", key: "" };

function ModalEditSoalMatch({ show, onHide, submitClick, title, text, id, idMainMatch }) {
  const [val, setVal] = useState(parameter);
  const [options, setOptions] = useState([]);
  const [spinner, setSpinner] = useState(true);
  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  function handleChange(mode, v) {
    switch (mode) {
      case "question":
        setVal({ ...val, question: v });
        break;
      case "kunci":
        setVal({ ...val, key: v });
        break;
    }
  }

  function handleSubmit(v) {
    setSpinner(true);
    setVal(parameter);
    submitClick(v);
  }
  function onHideModal() {
    setSpinner(true);
    setVal(parameter);
    onHide();
  }

  function onShow() {
    setVal({ ...val, id: id });
    axiosInstance
      .get("/optionselector/choice-matching-question1/" + idMainMatch, getHeader())
      .then((res) => setOptions(res.data))
      .catch((e) => {});
    axiosInstance
      .get("/question-get/one-matching-soal-1/" + id, getHeader())
      .then((res) => {
        setSpinner(false);
        setVal({
          id: res.data.data.id,
          question: res.data.data.pertanyaan,
          key: res.data.data.kunci,
        });
      })
      .catch((e) => {});
  }
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHideModal();
        }}
        onShow={() => onShow()}
      >
        <ModalHeader closeButton>
          <h5 className="modal-title">{title ? title : "Blank Title"}</h5>
        </ModalHeader>
        <ModalBody>
          {spinner ? (
            <div className="spinner-border"></div>
          ) : (
            <>
              <div className="form-group mb-3">
                <label className="form-label">Question</label>
                <textarea onChange={(e) => handleChange("question", e.target.value)} defaultValue={val.question} className="form-control" />
                <label className="form-label">Key</label>
                <Select onChange={(e) => handleChange("kunci", e.value)} options={options} value={{ value: val.key, label: val.key }} classNamePrefix="react-select" />
              </div>
              <div className="alert bg-inverse bg-opacity-15">{text ? text : "Blank text"}</div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={() => handleSubmit(val)} className={val.question != "" && val.key != "" ? "btn btn-outline-theme" : "btn btn-outline-theme disabled"}>
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalEditSoalMatch;
