import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";

function ModalEditTextV2({ show, onHide, id, submitClick, pressetData, title, text }) {
  const [val, setVal] = useState({ data: pressetData ? pressetData : "", id: id ? id : null });
  const handleOnshow = () => {
    setVal({ data: "", id: id ? id : null });
  };
  const handleChange = (val) => {
    setVal({ data: val, id: id ? id : null });
  };
  return (
    <>
      <Modal
        show={show}
        onShow={() => handleOnshow()}
        onHide={() => {
          onHide();
        }}
      >
        <ModalHeader closeButton>
          <h5 className="modal-title">{title ? title : "Blank Title"}</h5>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <textarea onChange={(e) => handleChange(e.target.value)} className="form-control" defaultValue={pressetData ? pressetData : ""} />
          </div>
          <div className="alert bg-inverse bg-opacity-15">{text ? text : "Blank Text"}</div>
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={() => submitClick(val)} className={val.data ? "btn btn-outline-theme" : "btn btn-outline-theme disabled"}>
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalEditTextV2;
