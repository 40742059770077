import React, { useEffect, useState } from "react";
import { Card, CardBody } from "./../../components/card/card.jsx";
import Pagination2 from "../../components/pagination/pagination2.jsx";
import { Link } from "react-router-dom";
import axiosInstance from "../../config/axios.js";
import axiosInstance2 from "../../config/axios2.js";

const trainingPresetData = {
  current_page: "",
  data: [],
  first_page_url: "",
  from: "",
  last_page: "",
  last_page_url: "",
  links: [],
  next_page_url: "",
  path: "",
  per_page: 3,
  prev_page_url: "",
  to: "",
  total: "",
};

function TrainingPlanData() {
  const [allPlanTraining, setAllPlanTraining] = useState(trainingPresetData);
  const [search1Value, setSearch1Value] = useState("");
  const [miniResume, setMiniResume] = useState(null);

  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  function nextClick1(val) {
    axiosInstance2
      .get(val + "&search=" + search1Value, getHeader())
      .then((res) => setAllPlanTraining(res.data))
      .catch((e) => {});
  }
  function prevClick1(val) {
    axiosInstance2
      .get(val + "&search=" + search1Value, getHeader())
      .then((res) => setAllPlanTraining(res.data))
      .catch((e) => {});
  }
  function submitSearch1(e) {
    e.preventDefault();
    axiosInstance
      .get("/plan-training/all?search=" + search1Value, getHeader())
      .then((res) => setAllPlanTraining(res.data))
      .catch((e) => {});
  }

  useEffect(() => {
    axiosInstance
      .get("/plan-training/all", getHeader())
      .then((res) => setAllPlanTraining(res.data))
      .catch((e) => {});
  }, []);

  return (
    <>
      <div>
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#/">TRAINING</a>
              </li>
              <li className="breadcrumb-item active">Data</li>
            </ul>

            <h1 className="page-header">
              Training Plan <small></small>
            </h1>
          </div>

          <div className="ms-auto">
            <Link to="/training/plan/new" className="btn btn-outline-theme">
              <i className="fa fa-plus-circle fa-fw me-1"></i> New Plan
            </Link>
          </div>
        </div>

        <hr className="mb-4" />
        {miniResume ? (
          <div className="mb-md-4 mb-3 d-md-flex">
            {/* <div className="mt-md-0 mt-2"><a href="#/" className="text-inverse text-opacity-75 text-decoration-none"><i className="fa fa-download fa-fw me-1 text-theme"></i> Export</a></div> */}
            <div className="ms-md-4 mt-md-0 mt-2">
              <i className="fas fa-user-plus fa-fw fa-lg me-1 text-theme"></i> {miniResume.monthly.new.qty + " Monthly"}
            </div>
            <div className="ms-md-4 mt-md-0 mt-2">
              <i className="fas fa-user-times user-times fa-lg me-1 text-danger"></i> {miniResume.monthly.out.qty + " Monthly"}
            </div>
            <div className="ms-md-4 mt-md-0 mt-2">
              <i className="fas fa-user-plus fa-fw fa-lg me-1 text-theme"></i> {miniResume.yearly.new.qty + " Yearly"}
            </div>
            <div className="ms-md-4 mt-md-0 mt-2">
              <i className="fas fa-user-times user-times fa-lg me-1 text-danger"></i> {miniResume.yearly.out.qty + " Yearly"}
            </div>
          </div>
        ) : (
          ""
        )}
        <Card>
          <div className="tab-content p-4">
            {/* ----------------All Tab------------------------------------------------------------------ */}
            <div className="tab-pane fade show active" id="allTab">
              <form onSubmit={(e) => submitSearch1(e)}>
                <div className="input-group mb-4">
                  <div className="d-flex justify-content-end">
                    <div className="input-group">
                      <input type="text" onChange={(e) => setSearch1Value(e.target.value)} className="form-control px-35px" placeholder="Search" />
                      <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{ zIndex: 1020 }}>
                        <i className="fa fa-search opacity-5"></i>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-outline-default" type="submit">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>

              {/* ----------------Table-------------- */}

              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th className="border-top-0 pt-0 pb-2">Training Title</th>
                      <th className="border-top-0 pt-0 pb-2">Location</th>
                      <th className="border-top-0 pt-0 pb-2">Institution</th>
                      <th className="border-top-0 pt-0 pb-2">Instructor</th>
                      <th className="border-top-0 pt-0 pb-2">Plan Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allPlanTraining.data.length > 0 ? (
                      allPlanTraining.data.map((value, index) => (
                        <tr key={index}>
                          <td className="align-middle">
                            <Link to={"/training/plan/detail/" + value.id}>{value.training_title.judul}</Link>
                          </td>
                          <td className="align-middle">{value.location.lokasiTraining}</td>
                          <td className="align-middle">{value.institution.lembaga}</td>
                          <td className="align-middle">{value.instructor.namaInstructor}</td>
                          <td className="align-middle">{value.start}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="align-middle">no Data</td>
                        <td className="align-middle"></td>
                        <td className="align-middle"></td>
                        <td className="align-middle"></td>
                        <td className="align-middle"></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* ----------------End Table-------------- */}

              {allPlanTraining.data.length > 0 ? <Pagination2 pageInfo={allPlanTraining} nextClick={(val) => nextClick1(val)} prevClick={(val) => prevClick1(val)} /> : ""}
            </div>
            {/* ----------------End All Tab------------------------------------------------------------------ */}
          </div>
        </Card>
      </div>
    </>
  );
}

export default TrainingPlanData;
