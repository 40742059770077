import { Modal, ModalHeader } from "react-bootstrap";

function ModalConfirm({ show, onHide, title, text, theme, clicked, id }) {
  const dangerTheme = {
    button: "btn btn-outline-danger btn-lg btn-block",
    title: "modal-title text-danger",
  };
  const standardTheme = {
    button: "btn btn-outline-theme btn-lg btn-block",
    title: "modal-title",
  };
  const tema = theme == "danger" ? dangerTheme : standardTheme;
  return (
    <>
      <Modal className="modal modal-cover" show={show} onHide={onHide}>
        <div className="modal-dialog">
          <div className="modal-content">
            <ModalHeader closeButton>
              <h3 className={tema.title}>{title}</h3>
            </ModalHeader>
            <div className="modal-body">
              <p className="mb-3">{text}</p>
              <button type="button" onClick={() => clicked(id)} className={tema.button}>
                Continue
              </button>
              <div className="pt-3"></div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalConfirm;
