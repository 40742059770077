import React, { useEffect, useState } from "react";
import { Card, CardBody } from "./../../components/card/card.jsx";
import BadgeSuccess from "../../components/badgeBorder/successBadge.jsx";
import BadgeDanger from "../../components/badgeBorder/dangerBadge.jsx";
import Pagination1 from "../../components/pagination/pagination1.jsx";
import { Link } from "react-router-dom";
import axiosInstance from "../../config/axios.js";

const manpowerPresetData = {
  currentPage: 1,
  data: null,
  from: 1,
  path: null,
  to: 0,
  total: 0,
};

function Data() {
  const [manpowersAll, setManpowersAll] = useState(manpowerPresetData);
  const [manpowersPlant, setManpowersPlant] = useState(null);
  const [manpowersNotActive, setManpowersNotActive] = useState(null);
  const [search1Value, setSearch1Value] = useState("");
  const [search2Value, setSearch2Value] = useState("");
  const [search3Value, setSearch3Value] = useState("");
  const [miniResume, setMiniResume] = useState(null);
  const [top7, setTop7] = useState(null);

  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  function nextClick1() {
    const page = manpowersAll.currentPage + 1;
    axiosInstance
      .get("/manpowerv2?search=" + search1Value + "&page=" + page, getHeader())
      .then((res) => setManpowersAll(res.data))
      .catch((e) => {});
  }
  function prevClick1() {
    const page = manpowersAll.currentPage - 1;
    axiosInstance
      .get("/manpowerv2?search=" + search1Value + "&page=" + page, getHeader())
      .then((res) => setManpowersAll(res.data))
      .catch((e) => {});
  }
  function submitSearch1(e) {
    e.preventDefault();
    axiosInstance
      .get("/manpowerv2?search=" + search1Value, getHeader())
      .then((res) => setManpowersAll(res.data))
      .catch((e) => {});
  }

  function nextClick2() {
    const page = manpowersPlant.currentPage + 1;
    axiosInstance
      .get("/manpowerv2/plant?search=" + search2Value + "&page=" + page, getHeader())
      .then((res) => setManpowersPlant(res.data))
      .catch((e) => {});
  }
  function prevClick2() {
    const page = manpowersPlant.currentPage - 1;
    axiosInstance
      .get("/manpowerv2/plant?search=" + search2Value + "&page=" + page, getHeader())
      .then((res) => setManpowersPlant(res.data))
      .catch((e) => {});
  }
  function submitSearch2(e) {
    e.preventDefault();
    axiosInstance
      .get("/manpowerv2/plant?search=" + search2Value, getHeader())
      .then((res) => setManpowersPlant(res.data))
      .catch((e) => {});
  }

  function nextClick3() {
    const page = manpowersNotActive.currentPage + 1;
    axiosInstance
      .get("/manpowerv2/notActive?search=" + search3Value + "&page=" + page, getHeader())
      .then((res) => setManpowersNotActive(res.data))
      .catch((e) => {});
  }
  function prevClick3() {
    const page = manpowersNotActive.currentPage - 1;
    axiosInstance
      .get("/manpowerv2/notActive?search=" + search3Value + "&page=" + page, getHeader())
      .then((res) => setManpowersNotActive(res.data))
      .catch((e) => {});
  }
  function submitSearch3(e) {
    e.preventDefault();
    axiosInstance
      .get("/manpowerv2/notActive?search=" + search3Value, getHeader())
      .then((res) => setManpowersNotActive(res.data))
      .catch((e) => {});
  }

  useEffect(() => {
    axiosInstance
      .get("/manpowerv2", getHeader())
      .then((res) => setManpowersAll(res.data))
      .catch((e) => {});
    axiosInstance
      .get("/manpowerv2", getHeader())
      .then((res) => setManpowersAll(res.data))
      .catch((e) => {});
    axiosInstance
      .get("/manpowerv2/plant", getHeader())
      .then((res) => setManpowersPlant(res.data))
      .catch((e) => {});
    axiosInstance
      .get("/manpowerv2/notActive", getHeader())
      .then((res) => setManpowersNotActive(res.data))
      .catch((e) => {});
    axiosInstance
      .get("/manpowerv2/miniResume", getHeader())
      .then((res) => setMiniResume(res.data))
      .catch((e) => {});
    axiosInstance
      .get("/manpowerv2/top7", getHeader())
      .then((res) => setTop7(res.data))
      .catch((e) => {});
  }, []);

  return (
    <>
      <div>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#/">MANPOWER</a>
          </li>
          <li className="breadcrumb-item active">Data</li>
        </ul>

        <h1 className="page-header">
          Data Manpower <small></small>
        </h1>

        <hr className="mb-4" />
        {miniResume ? (
          <div className="mb-md-4 mb-3 d-md-flex">
            {/* <div className="mt-md-0 mt-2"><a href="#/" className="text-inverse text-opacity-75 text-decoration-none"><i className="fa fa-download fa-fw me-1 text-theme"></i> Export</a></div> */}
            <div className="ms-md-4 mt-md-0 mt-2">
              <i className="fas fa-user-plus fa-fw fa-lg me-1 text-theme"></i> {miniResume.monthly.new.qty + " Monthly"}
            </div>
            <div className="ms-md-4 mt-md-0 mt-2">
              <i className="fas fa-user-times user-times fa-lg me-1 text-danger"></i> {miniResume.monthly.out.qty + " Monthly"}
            </div>
            <div className="ms-md-4 mt-md-0 mt-2">
              <i className="fas fa-user-plus fa-fw fa-lg me-1 text-theme"></i> {miniResume.yearly.new.qty + " Yearly"}
            </div>
            <div className="ms-md-4 mt-md-0 mt-2">
              <i className="fas fa-user-times user-times fa-lg me-1 text-danger"></i> {miniResume.yearly.out.qty + " Yearly"}
            </div>
          </div>
        ) : (
          ""
        )}
        <Card>
          <ul className="nav nav-tabs nav-tabs-v2 px-4">
            <li className="nav-item me-3">
              <a href="#allTab" className="nav-link active px-2" data-bs-toggle="tab">
                All
              </a>
            </li>
            <li className="nav-item me-3">
              <a href="#plant" className="nav-link px-2" data-bs-toggle="tab">
                Plant
              </a>
            </li>
            <li className="nav-item me-3">
              <a href="#notActive" className="nav-link px-2 text-danger" data-bs-toggle="tab">
                Not Active
              </a>
            </li>
          </ul>

          <div className="tab-content p-4">
            {/* ----------------All Tab------------------------------------------------------------------ */}
            <div className="tab-pane fade show active" id="allTab">
              <form onSubmit={submitSearch1}>
                <div className="input-group mb-4">
                  <div className="d-flex justify-content-end">
                    <div className="input-group">
                      <input type="text" onChange={(e) => setSearch1Value(e.target.value)} className="form-control px-35px" placeholder="Search" />
                      <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{ zIndex: 1020 }}>
                        <i className="fa fa-search opacity-5"></i>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-outline-default" type="submit">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>

              {/* ----------------Table-------------- */}

              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th className="border-top-0 pt-0 pb-2">NRP</th>
                      <th className="border-top-0 pt-0 pb-2">Name</th>
                      <th className="border-top-0 pt-0 pb-2">Company</th>
                      <th className="border-top-0 pt-0 pb-2">Positon</th>
                      <th className="border-top-0 pt-0 pb-2">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {manpowersAll.data ? (
                      manpowersAll.data.map((value, index) => (
                        <tr key={index}>
                          <td className="align-middle">
                            <Link to={"/manpower/detail/" + value.id}>{value.companyCode === 1 ? value.nrp : "NON SIS"}</Link>
                          </td>
                          <td className="align-middle">{value.name}</td>
                          <td className="align-middle">{value.company}</td>
                          <td className="align-middle">{value.position}</td>
                          {value.status === "AKTIF" ? (
                            <td className="py-1 align-middle">
                              <BadgeSuccess text={"Active"} />
                            </td>
                          ) : (
                            <td className="py-1 align-middle">
                              <BadgeDanger text={value.status} />
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="align-middle">no Data</td>
                        <td className="align-middle"></td>
                        <td className="align-middle"></td>
                        <td className="align-middle"></td>
                        <td className="align-middle"></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* ----------------End Table-------------- */}

              {manpowersAll ? <Pagination1 pageInfo={manpowersAll} nextClick={nextClick1} prevClick={prevClick1} /> : ""}
            </div>
            {/* ----------------End All Tab------------------------------------------------------------------ */}
            {/* ----------------Plant Tab------------------------------------------------------------------ */}
            <div className="tab-pane fade" id="plant">
              <form onSubmit={submitSearch2}>
                <div className="input-group mb-4">
                  <div className="d-flex justify-content-end">
                    <div className="input-group">
                      <input type="text" onChange={(e) => setSearch2Value(e.target.value)} className="form-control px-35px" placeholder="Search" />
                      <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{ zIndex: 1020 }}>
                        <i className="fa fa-search opacity-5"></i>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-outline-default" type="submit">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>

              {/* ----------------Table-------------- */}

              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th className="border-top-0 pt-0 pb-2">NRP</th>
                      <th className="border-top-0 pt-0 pb-2">Name</th>
                      <th className="border-top-0 pt-0 pb-2">Company</th>
                      <th className="border-top-0 pt-0 pb-2">Positon</th>
                      <th className="border-top-0 pt-0 pb-2">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {manpowersPlant ? (
                      manpowersPlant.data.map((value, index) => (
                        <tr key={index}>
                          <td className="align-middle">
                            <a href={"/manpower/detail/" + value.id}>{value.companyCode === 1 ? value.nrp : "NON SIS"}</a>
                          </td>
                          <td className="align-middle">{value.name}</td>
                          <td className="align-middle">{value.company}</td>
                          <td className="align-middle">{value.position}</td>
                          {value.status === "AKTIF" ? (
                            <td className="py-1 align-middle">
                              <BadgeSuccess text={"Active"} />
                            </td>
                          ) : (
                            <td className="py-1 align-middle">
                              <BadgeDanger text={value.status} />
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="align-middle">no Data</td>
                        <td className="align-middle"></td>
                        <td className="align-middle"></td>
                        <td className="align-middle"></td>
                        <td className="align-middle"></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* ----------------End Table-------------- */}

              {manpowersPlant ? <Pagination1 pageInfo={manpowersPlant} nextClick={nextClick2} prevClick={prevClick2} /> : ""}
            </div>
            {/* ----------------End Plant Tab------------------------------------------------------------------ */}

            {/* ----------------Not Active Tab------------------------------------------------------------------ */}
            <div className="tab-pane fade" id="notActive">
              <form onSubmit={submitSearch3}>
                <div className="input-group mb-4">
                  <div className="d-flex justify-content-end">
                    <div className="input-group">
                      <input type="text" onChange={(e) => setSearch3Value(e.target.value)} className="form-control px-35px" placeholder="Search" />
                      <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{ zIndex: 1020 }}>
                        <i className="fa fa-search opacity-5"></i>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-outline-default" type="submit">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>

              {/* ----------------Table-------------- */}

              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th className="border-top-0 pt-0 pb-2">NRP</th>
                      <th className="border-top-0 pt-0 pb-2">Name</th>
                      <th className="border-top-0 pt-0 pb-2">Company</th>
                      <th className="border-top-0 pt-0 pb-2">Positon</th>
                      <th className="border-top-0 pt-0 pb-2">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {manpowersNotActive ? (
                      manpowersNotActive.data.map((value, index) => (
                        <tr key={index}>
                          <td className="align-middle">
                            <a href={"/manpower/detail/" + value.id}>{value.companyCode === 1 ? value.nrp : "NON SIS"}</a>
                          </td>
                          <td className="align-middle">{value.name}</td>
                          <td className="align-middle">{value.company}</td>
                          <td className="align-middle">{value.position}</td>
                          {value.status === "AKTIF" ? (
                            <td className="py-1 align-middle">
                              <BadgeSuccess text={"Active"} />
                            </td>
                          ) : (
                            <td className="py-1 align-middle">
                              <BadgeDanger text={value.status} />
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="align-middle">no Data</td>
                        <td className="align-middle"></td>
                        <td className="align-middle"></td>
                        <td className="align-middle"></td>
                        <td className="align-middle"></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* ----------------End Table-------------- */}

              {manpowersNotActive ? <Pagination1 pageInfo={manpowersNotActive} nextClick={nextClick3} prevClick={prevClick3} /> : ""}
            </div>
            {/* ----------------End Not Active Tab------------------------------------------------------------------ */}
          </div>
        </Card>

        {/* ----------------Top7 Tab------------------------------------------------------------------ */}

        <div className="row mt-5">
          {top7
            ? top7.map((value, index) => (
                <div className="col-xl-6" key={index}>
                  <Card className="mb-3">
                    <CardBody>
                      <div className="d-flex fw-bold small mb-3">
                        <span className="flex-grow-1">{value.judul}</span>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-striped table-borderless mb-2px small text-nowrap">
                          <tbody>
                            {value.data
                              ? value.data.map((dat, i) => (
                                  <tr key={i}>
                                    <td>
                                      <span className="d-flex align-items-center">
                                        <i className="bi bi-circle-fill fs-6px me-2 text-inverse text-opacity-30"></i>
                                        <a href={"/manpower/detail/" + dat.id}>{dat.nrp}</a>
                                      </span>
                                    </td>
                                    <td>{dat.nama}</td>
                                    <td>{dat.parameter}</td>
                                    <td>
                                      <small>{dat.subSec}</small>
                                    </td>
                                  </tr>
                                ))
                              : ""}
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              ))
            : "no Data"}
        </div>
        {/* ----------------End Top7 Tab------------------------------------------------------------------ */}
      </div>
    </>
  );
}

export default Data;
