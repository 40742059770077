
function DiffDate(val){

let d=new Date(val)
let now=new Date()
const c={
    date:d.getDate(),
    month:d.getMonth(),
    year:d.getFullYear()
}

const n={
    date:now.getDate(),
    month:now.getMonth(),
    year:now.getFullYear()
}

let yearAge=n.year-c.year
let monthAge=0
let dateAge=0
if(n.month>=c.month){
    monthAge=n.month-c.month
}else{
    yearAge--
    monthAge=12+n.month-c.month
}

if (n.date >= c.date)  
    dateAge = n.date - c.date;  
  else {  
    monthAge--;  
    dateAge = 31 + n.date - c.date;  

    if (monthAge < 0) {  
      monthAge = 11;  
      yearAge--;  
    }  
  }  
return {
    year:yearAge,
    month:monthAge,
    date:dateAge
    }

}

export default DiffDate