import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";

const parameter = { id: null, question: "", key: "" };

function ModalNewSoalMatch2({ show, onHide, submitClick, id }) {
  const [val, setVal] = useState(parameter);

  function handleChange(mode, v) {
    switch (mode) {
      case "question":
        setVal({ ...val, question: v });
        break;
      case "kunci":
        setVal({ ...val, key: v });
        break;
    }
  }

  function handleSubmit(v) {
    submitClick(v);
  }

  function onShow() {
    setVal({ ...val, id: id });
  }
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setVal(parameter);
          onHide();
        }}
        onShow={() => onShow()}
      >
        <ModalHeader closeButton>
          <h5 className="modal-title">New Multiple Choice Question</h5>
        </ModalHeader>
        <ModalBody>
          <div className="form-group mb-3">
            <label className="form-label">Question</label>
            <textarea onChange={(e) => handleChange("question", e.target.value)} className="form-control" />
            <label className="form-label">Key</label>
            <input onChange={(e) => handleChange("kunci", e.target.value)} className="form-control" />
          </div>
          <div className="alert bg-inverse bg-opacity-15">Edit matching question and this key. key can be containing letter or number base on your image</div>
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={() => handleSubmit(val)} className={val.question != "" && val.key != "" ? "btn btn-outline-theme" : "btn btn-outline-theme disabled"}>
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalNewSoalMatch2;
