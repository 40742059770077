function Select1({ options, onChange }) {
  return (
    <div className="mb-3">
      <label className="form-label">Select Instructor:</label>
      <select onChange={onChange} className="form-select form-select-lg">
        {options.map((v, index) => (
          <option key={index} value={v.value}>
            {v.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Select1;
