import React, { useEffect, useContext, useState } from "react";
import { AppSettings } from "./../../config/app-settings.js";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import Api from "../../config/Api.jsx";
import config from "../../config/config.js";
import axiosInstance from "../../config/axios.js";

function Logingin() {
  const context = useContext(AppSettings);
  const [isAuth, setIsAuth] = useState(false);
  function authCheck() {
    const getHeader = () => {
      const headers = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
        },
      };
      return headers;
    };

    fetch(config.baseURL + "/user", getHeader())
      .then((res) => res.json())
      .then((data) => {
        setIsAuth(true);
      })
      .catch((err) => {
        setIsAuth(false);
      });
  }
  useEffect(() => {
    authCheck();
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);
    context.setAppContentClass("p-0");

    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("");
    };

    // eslint-disable-next-line
  }, []);

  if (isAuth) {
    return <Navigate to={"/kpi"} />;
  }
  return (
    <div className="login">
      <div className="login-content">
        <h1 className="text-center">WELCOME</h1>
        <div className="text-inverse text-opacity-50 text-center mb-4">Please Wait....</div>

        <div className="text-center text-inverse text-opacity-50">Plant People Development Jobsite Maruwai Coal</div>
      </div>
    </div>
  );
}

export default Logingin;
