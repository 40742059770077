import { useState } from "react"
import { Modal, ModalHeader } from "react-bootstrap"

const parameter={id:'',val:'',edit:''}

function ModalEditFile({show,onHide,submitClick,data={}}){
    const attr={
        title:data.hasOwnProperty('title')?data.title:'Edit Value',
        text:data.hasOwnProperty('text')?data.text:'Please edit as you wish :)',
        data:data.hasOwnProperty('data')?data.data:{id:'',val:'',edit:''}
    }
    const [val,setVal]=useState(attr.data)
    const [val2,setVal2]=useState()

    const handleChange=(e)=>{
        setVal2(e.target.value)
        let formData=new FormData
        formData.append('file',e.target.files[0])
        formData.append('id',attr.data.id)
        formData.append('edit',attr.data.edit)
        setVal(formData)
    }
    return (
        <>
        <Modal show={show} onHide={()=>{setVal(parameter);onHide();}} >
        <div className="modal-dialog">
            <div className="modal-content">
                <ModalHeader closeButton>
                    <h5 className="modal-title">{attr.title}</h5>
                </ModalHeader>
                <div className="modal-body">
                    <div className="mb-3">
                        <input type="file" onChange={(e)=>handleChange(e)} className="form-control" />
                    </div>
                    <div className="alert bg-inverse bg-opacity-15">
                        {attr.text}
                    </div>
                  
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={()=>submitClick(val)} className={val2?"btn btn-outline-theme":"btn btn-outline-theme disabled"}>Save changes</button>
                </div>
            </div>
        </div>
        </Modal>
        </>
    )
}

export default ModalEditFile