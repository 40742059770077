import React, { useEffect, useState } from "react";
import { Card, CardBody } from "./../../components/card/card.jsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../config/axios.js";
import { CardHeader } from "react-bootstrap";

function EventDetail() {
  const param = useParams();
  const [eConfig, setEconfig] = useState([]);
  const [eResult, setEresult] = useState([]);

  const navigate = useNavigate();
  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  // ---------------------------------------------------------Generate-------------------------------------------------------------------

  // ---------------------------------------------------------End Generate-------------------------------------------------------------------

  function dateFormat(d) {
    const date = new Date(d);
    return date.toDateString();
  }

  // ---------------------------------------------------------End Generate Event----------------------------------------------------------------
  useEffect(() => {
    axiosInstance.get("/question-event/event-result/" + param.id, getHeader()).then((res) => {
      setEconfig(res.data.data.config);
      setEresult(res.data.data.result);
    });
  }, []);
  return (
    <>
      <div>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#/">Event</a>
          </li>
          <li className="breadcrumb-item active">Result</li>
        </ul>

        <h1 className="page-header">
          Event Result <small></small>
        </h1>

        {/* <hr className="mb-4" /> */}
        <div className="mb-sm-4 mb-3 d-sm-flex">
          <div className="ms-sm-4 mt-sm-0 mt-2">
            <a href="#/" className="text-danger text-opacity-75 text-decoration-none">
              <i className="fas fa-trash-alt fa-fw me-1 text-danger"></i> Delete
            </a>
          </div>
        </div>
        <div className="row gx-4">
          <div className="col-lg-8">
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">Configuration</CardHeader>
              <CardBody>
                {eConfig.length > 0 ? (
                  eConfig.map((val, index) => (
                    <div key={index}>
                      {val.label == "hr" ? (
                        <hr></hr>
                      ) : (
                        <div className="list-group list-group-flush">
                          <div className="list-group-item d-flex align-items-center">
                            <div className="flex-1 text-break">
                              <div className="text-theme">{val.label}</div>
                              <div className={val.style}>{val.value}</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <p>Loading...</p>
                )}
              </CardBody>
            </Card>
          </div>

          <div className="col-lg-4">
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center fw-400 bg-inverse bg-opacity-10">
                <div className="flex-1">
                  <div>Resume</div>
                </div>
              </CardHeader>
              <CardBody>------------</CardBody>
            </Card>
          </div>

          <div className="col-lg-12">
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">Result</CardHeader>
              <CardBody>
                <div className="table-responsive">
                  <table className="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th className="border-top-0 pt-0 pb-2">Name</th>
                        <th className="border-top-0 pt-0 pb-2">Company</th>
                        <th className="border-top-0 pt-0 pb-2">Position</th>
                        <th className="border-top-0 pt-0 pb-2 text-theme">True</th>
                        <th className="border-top-0 pt-0 pb-2 text-danger">False</th>
                        <th className="border-top-0 pt-0 pb-2">Score</th>
                        <th className="border-top-0 pt-0 pb-2">Submited</th>
                        <th className="border-top-0 pt-0 pb-2"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {eResult ? (
                        eResult.map((val, index) => (
                          <tr key={index}>
                            <td className="align-middle">{val.name}</td>
                            <td className="align-middle">{val.company}</td>
                            <td className="align-middle">{val.position}</td>
                            <td className="align-middle">{val.true}</td>
                            <td className="align-middle">{val.false}</td>
                            <td className="align-middle">{val.score}</td>
                            <td className="align-middle">{dateFormat(val.updated_at)}</td>
                            <td className="align-middle">
                              <Link to={"/question/event-result/" + val.id}>Detail</Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="align-middle">No Data</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventDetail;
