function Pagination2({ pageInfo, nextClick, prevClick }) {
  function handleNextClicked() {
    nextClick(pageInfo.next_page_url);
  }

  function handlePrevClicked() {
    prevClick(pageInfo.prev_page_url);
  }
  return (
    <div className="d-md-flex align-items-center mt-3">
      <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
        Showing {pageInfo.from} to {pageInfo.to} of {pageInfo.total} entries
      </div>
      <ul className="pagination mb-0 justify-content-center">
        <li className={!pageInfo.prev_page_url ? "page-item disabled" : "page-item"}>
          <a onClick={() => handlePrevClicked()} className="page-link" href="#/">
            Previous
          </a>
        </li>
        <li className={!pageInfo.next_page_url ? "page-item disabled" : "page-item"}>
          <a onClick={() => handleNextClicked()} className="page-link" href="#/">
            Next
          </a>
        </li>
        {/* <li className="page-item disabled"><a className="page-link" href="#/">{'<<'}</a></li>
                <li className="page-item"><a className="page-link" href="#/">1</a></li>
                <li className="page-item"><a className="page-link" href="#/">..</a></li>
                <li className="page-item"><a className="page-link" href="#/">99</a></li>
                <li className="page-item"><a className="page-link" href="#/">100</a></li>
                <li className="page-item"><a className="page-link" href="#/">101</a></li>
                <li className="page-item disabled"><a className="page-link" href="#/">..</a></li>
                <li className="page-item"><a className="page-link" href="#/">{lastPage}</a></li>
                <li className="page-item"><a className="page-link" href="#/">{'>>'}</a></li> */}
      </ul>
    </div>
  );
}

export default Pagination2;
