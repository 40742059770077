import React, { useEffect, useState } from "react";
import { Card, CardBody } from "./../../components/card/card.jsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../config/axios.js";
import { CardHeader } from "react-bootstrap";
import ImageHandler from "./imageHandler.jsx";

function EventResult() {
  const param = useParams();
  const [soalMc, setSoalMc] = useState([]);
  const [soalTf, setSoalTf] = useState([]);
  const [soalMatch, setSoalMatch] = useState([]);
  const [headResult, setHeadResult] = useState([]);

  const navigate = useNavigate();
  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  // ---------------------------------------------------------Generate-------------------------------------------------------------------

  // ---------------------------------------------------------End Generate-------------------------------------------------------------------

  function dateFormat(d) {
    const date = new Date(d);
    return date.toDateString();
  }

  // ---------------------------------------------------------End Generate Event----------------------------------------------------------------
  useEffect(() => {
    axiosInstance
      .get("/question-event/client-result/" + param.id, getHeader())
      .then((res) => {
        setSoalMc(res.data.data.resMc);
        setSoalTf(res.data.data.resTf);
        setSoalMatch(res.data.data.resMatch);
        setHeadResult(res.data.data.resHead);
      })
      .catch((e) => {});
  }, []);
  return (
    <>
      <div>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#/">RESULT</a>
          </li>
          <li className="breadcrumb-item active">Detail</li>
        </ul>

        <h1 className="page-header">
          Event Result <small></small>
        </h1>

        {/* <hr className="mb-4" /> */}

        <div className="row gx-4">
          <div className="col-lg-8">
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">General</CardHeader>
              <CardBody>
                {headResult.length > 0 ? (
                  headResult.map((val, index) => (
                    <div key={index}>
                      {val.label == "hr" ? (
                        <hr></hr>
                      ) : (
                        <div className="list-group list-group-flush">
                          <div className="list-group-item d-flex align-items-center">
                            <div className="flex-1 text-break">
                              <div className="text-theme">{val.label}</div>
                              <div className={val.style}>{val.value}</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <p>Loading...</p>
                )}
              </CardBody>
            </Card>
          </div>

          <div className="col-lg-4">
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center fw-400 bg-inverse bg-opacity-10">
                <div className="flex-1">
                  <div>Resume</div>
                </div>
              </CardHeader>
              <CardBody>------------</CardBody>
            </Card>
          </div>

          <div className="col-lg-12">
            {soalMc.length > 0 ? (
              <Card className="mb-4">
                <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">Multiple Choice</CardHeader>
                <CardBody>
                  {/* ----------------------------------------------------------------------------------------------------- */}
                  {soalMc.map((soal, index) => (
                    <div key={index}>
                      <div className="list-group list-group-flush">
                        <div className="list-group-item d-flex px-3">
                          <div className="me-3">
                            <div className="fw-400">{index + 1 + "."}</div>
                          </div>
                          {/* ----------------------------------------------------------------------------------------------------- */}
                          <div className="flex-fill">
                            <div className="fw-400 mb-1">{soal.soal.pertanyaan}</div>

                            {soal.soal.imagePath ? (
                              <div className="mb-3">
                                <ImageHandler url={soal.soal.imagePath} />
                              </div>
                            ) : (
                              ""
                            )}

                            {soal.soal.jawaban.length > 0 ? (
                              soal.soal.jawaban.map((jawaban, ind) => (
                                <div key={ind} className="form-group small">
                                  <div className="form-check">
                                    <input type="radio" className="form-check-input" checked={jawaban.trueChoice ? 1 : 0} disabled />
                                    <label className={jawaban.trueChoice ? "form-label text-theme" : "form-label"} htmlFor="customCheck1">
                                      {jawaban.choices}
                                    </label>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p>no Choice Answer</p>
                            )}
                            <p className={soal.answered == soal.kunci ? "text-theme" : "text-danger"}> Your Answer: {soal.answered}</p>
                          </div>
                        </div>
                      </div>
                      <hr className="my-1" />
                    </div>
                  ))}
                  {/* ----------------------------------------------------------------------------------------------------- */}
                </CardBody>
              </Card>
            ) : (
              ""
            )}

            {soalTf.length > 0 ? (
              <Card className="mb-4">
                <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">True or False</CardHeader>
                <CardBody>
                  {/* -----------------------------------------------------SOAL TRUE FALSE--------------------------------------------------- */}

                  {soalTf.map((soal, index) => (
                    <div key={index}>
                      <div className="list-group list-group-flush">
                        <div className="list-group-item d-flex px-3">
                          <div className="me-3">
                            <div className="fw-400">{index + 1 + "."}</div>
                          </div>
                          {/* ----------------------------------------------------------------------------------------------------- */}
                          <div className="flex-fill">
                            <div className="fw-400 mb-1">{soal.soal.pertanyaan}</div>

                            {soal.soal.imagePath ? (
                              <div className="mb-3">
                                <ImageHandler url={soal.soal.imagePath} />
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="form-group small">
                              <div className="form-check">
                                <input type="radio" className="form-check-input" disabled checked={soal.soal.answer ? 1 : 0} />
                                <label className={soal.soal.answer ? "form-label text-theme" : "form-label"}>TRUE</label>
                              </div>
                              <div className="form-check">
                                <input type="radio" className="form-check-input" disabled checked={soal.soal.answer ? 0 : 1} />
                                <label className={!soal.soal.answer ? "form-label text-theme" : "form-label"}>FALSE</label>
                              </div>
                            </div>
                            <p className={soal.answered == soal.kunci ? "text-theme" : "text-danger"}> Your Answer: {soal.answered ? "TRUE" : "FALSE"}</p>
                          </div>
                        </div>
                      </div>
                      <hr className="my-1" />
                    </div>
                  ))}

                  {/* -----------------------------------------------------END SOAL TRUE FALSE--------------------------------------------------- */}
                </CardBody>
              </Card>
            ) : (
              ""
            )}

            {soalMatch.length > 0 ? (
              <Card className="mb-4">
                <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">Matching</CardHeader>
                <CardBody>
                  <>
                    {soalMatch.map((soalMain, index) => (
                      <div key={index}>
                        {
                          soalMain.imagePath ? (
                            // {/* -------------------------------------------------------Soal 1---------------------------------------------------------- */}
                            <div className="mb-3">
                              <Card className="mb-3">
                                <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10">
                                  <span className="flex-grow-1 fw-400">{soalMain.petunjuk}</span>
                                </CardHeader>
                              </Card>

                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <span className="flex-grow-1 fw-400 text-theme">Image</span>
                                  </div>
                                  <div>
                                    <ImageHandler url={soalMain.imagePath} />
                                  </div>
                                </div>

                                {/* ----------------------------------------------End Pertanyaan------------------------------------------------ */}
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <span className="flex-grow-1 fw-400 text-theme">Questions</span>
                                  </div>
                                  <div className="list-group list-group-flush">
                                    {soalMain.soal.map((soal, ind) => (
                                      <div key={ind} className="d-flex px-3 mb-1">
                                        <div className="me-3">
                                          <div className="fw-400">{ind + 1}</div>
                                        </div>
                                        <div className="flex-fill">
                                          <div className={soal.available ? "fw-400" : "fw-400 text-danger"}>{soal.available ? soal.pertanyaan : "<Question was deleted>"}</div>
                                          <div className="small text-inverse text-opacity-50">{soal.kunci} </div>
                                          <div className={soal.kunci.toUpperCase() == soal.answered.toUpperCase() ? "small text-theme" : "small text-danger"}>Your Answer:{soal.answered} </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            // {/* ------------------------------------------------------- End Soal 1---------------------------------------------------------- */}
                            // {/* ------------------------------------------------------- Soal 2---------------------------------------------------------- */}
                            <div className="mb-3">
                              <Card className="mb-3">
                                <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10">
                                  <span className="flex-grow-1 fw-400">{soalMain.petunjuk}</span>
                                </CardHeader>
                              </Card>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <span className="flex-grow-1 fw-400 text-theme">Questions</span>
                                  </div>
                                  <div className="list-group list-group-flush">
                                    {soalMain.soal.map((soal, ind) => (
                                      <div key={ind} className="d-flex px-3 mb-1">
                                        <div className="me-3">
                                          <div className="fw-400">{ind + 1}</div>
                                        </div>
                                        <div className="flex-fill">
                                          <div className={soal.available ? "fw-400" : "fw-400 text-danger"}>{soal.available ? soal.pertanyaan : "<Question was deleted>"}</div>
                                          <div className="small text-inverse text-opacity-50">{soal.kunci} </div>
                                          <div className={soal.kunci == soal.answered ? "small text-theme" : "small text-danger"}>Your Answer:{soal.answered} </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>

                                {/* ----------------------------------------------End Pertanyaan------------------------------------------------ */}
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <span className="flex-grow-1 fw-400 text-theme">Answer Choice</span>
                                  </div>
                                  <div className="list-group list-group-flush">
                                    {soalMain.jawaban.map((jawaban, ind) => (
                                      <div key={ind} className="d-flex px-3 mb-1">
                                        <div className="me-3">
                                          <div className="fw-400">-</div>
                                        </div>
                                        <div className="flex-fill">
                                          <div className="fw-400">{jawaban.jawaban} </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                          // {/* -------------------------------------------------------End Soal 2---------------------------------------------------------- */}
                        }
                      </div>
                    ))}
                  </>
                </CardBody>
              </Card>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default EventResult;
