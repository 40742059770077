import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Card, CardBody, CardHeader } from "./../../components/card/card.jsx";
import BadgeSuccess from "../../components/badgeBorder/successBadge.jsx";
import BadgeDanger from "../../components/badgeBorder/dangerBadge.jsx";
import { Link } from "react-router-dom";
import axiosInstance from "../../config/axios.js";
import ModalConfirm from "../../components/modal/modalConfirm.jsx";
import ModalUploadFile from "../../components/modal/modalUploadFile.jsx";
import ModalLoading from "../../components/modal/modalLoading.jsx";
import ModalInfo from "../../components/modal/modalInfo.jsx";

function ReportDocument() {
  const [id, submitedEvent, setSubmitedEvent, setId, setWizardData] = useOutletContext();
  const [modalUploadFile, setModalUploadFile] = useState({ show: false, title: "Choose Document", text: "Document like absensi, training evaluation, TAF etc.", data: { id: null, val: null } });
  const [modalConfirm, setModalConfirm] = useState({ show: false, title: "Warning", text: "no text", id: null });
  const [modalLoading, setModalLoading] = useState({ show: false, title: "blank", text: "Blank", value: 0 });
  const [modalInfo, setModalInfo] = useState({ show: false, message: "no Message", title: "no title" });
  const [documents, setDocuments] = useState([]);
  const [loadingValue, setLoadingValue] = useState(0);
  const navigate = useNavigate();

  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  function dateFormat(d) {
    const date = new Date(d);
    return date.toDateString();
  }
  function handleUploadFile() {
    setModalUploadFile({ show: true, title: "Choose Document", text: "Document like absensi, training evaluation, TAF etc.", data: { id: id, val: null } });
  }
  function handleSubmitFile(file) {
    setModalUploadFile({ ...modalUploadFile, show: false });
    setModalLoading({ ...modalLoading, title: "Uploading", text: "Please wait uploading is proggress.....", show: true });
    axiosInstance
      .post("/training-uploadDocument", file, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
        },
        onUploadProgress: (pv) => {
          setLoadingValue(Math.round((pv.loaded / pv.total) * 100));
        },
        validateStatus: false,
      })
      .then((res) => {
        setModalLoading({ ...modalLoading, show: false });
        if (res.data.status) {
          axiosInstance
            .get("/training/document/" + id, getHeader())
            .then((res) => setDocuments(res.data.data))
            .catch((e) => {});
        } else {
          setModalInfo({ ...modalInfo, show: true, title: "Warning", text: res.data.message.fileName[0] });
        }
      });
  }
  function handleDeleteFile(id, fileName) {
    setModalConfirm({ ...modalConfirm, show: true, text: "Are you sure want to delete: " + fileName + " ?", id: id });
  }
  function handleDeleteConfirmed() {
    axiosInstance
      .delete("/training-deleteDocument/" + modalConfirm.id, getHeader())
      .then((res) => {
        setModalConfirm({ ...modalConfirm, show: false });
        axiosInstance
          .get("/training/document/" + id, getHeader())
          .then((res) => setDocuments(res.data.data))
          .catch((e) => {});
      })
      .catch((e) => {});
  }
  function handleDownload(e, fileName) {
    setModalLoading({ ...modalLoading, title: "Downloading", text: "Please wait downloading is proggress.....", show: true });
    axiosInstance
      .get("training-document/" + id + "/" + e, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
        },
        onDownloadProgress: (e) => {
          setLoadingValue(Math.round((e.loaded / e.total) * 100));
        },
        responseType: "blob",
      })
      .then((res) => {
        saveFile(res, fileName);
        setModalLoading({ ...modalLoading, show: false });
      })
      .catch((e) => {});
  }

  function saveFile(res, fileName) {
    const href = URL.createObjectURL(res.data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }
  function eventSubmit() {
    navigate("/training/detail/" + id);
  }
  useEffect(() => {
    setWizardData([
      { label: "Event", status: "completed" },
      { label: "Participant", status: "completed" },
      { label: "Document", status: "active" },
      { label: "Media", status: "disabled" },
    ]);
  }, []);

  return (
    <>
      <div className="d-md-flex align-items-center">
        <div className="me-md-auto text-md-left text-center mb-2 mb-md-0"></div>
        <div className="pagination mb-0 justify-content-center">
          <span onClick={() => eventSubmit()} className="btn btn-outline-theme me-2 mb-1">
            <span>Finish</span>
          </span>
        </div>
      </div>
      <hr className="mb-4" />

      <Card className="mb-4">
        <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">Media</CardHeader>
        <CardBody className="card-body pb-2">
          <div className="mb-2">
            <div className="d-block d-lg-flex align-items-center">
              <span onClick={() => handleUploadFile()} className="btn btn-outline-theme me-2 mb-1">
                <i className="fa fa-fw fa-plus"></i>
                <span>Add files...</span>
              </span>
            </div>
          </div>
        </CardBody>
        <table className="table table-card mb-0 fs-13px">
          <thead>
            <tr className="fs-12px">
              <th className="pt-2 pb-2 w-25">FILENAME</th>
              <th className="pt-2 pb-2 w-25">SIZE</th>
              <th className="pt-2 pb-2 w-25">UPLOADED</th>
              <th className="pt-2 pb-2 w-25">ACTION</th>
            </tr>
          </thead>
          <tbody className="files">
            {documents.length > 0 ? (
              documents.map((document, index) => (
                <tr key={index}>
                  <td className="align-middle">{document.fileName}</td>
                  <td className="align-middle">{document.size}</td>
                  <td className="align-middle">{dateFormat(document.updated_at)}</td>
                  <td>
                    <a href="#/" onClick={() => handleDeleteFile(document.id, document.fileName)} className="btn btn-outline-default btn-sm">
                      <i className="fas fa-trash-alt text-danger"></i>
                    </a>
                    <a href="#/" onClick={() => handleDownload(document.id, document.fileName)} className="btn btn-outline-default btn-sm">
                      <i className="fas fa-download text-theme"></i>
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="empty-row">
                <td colSpan="4" className="text-center p-3">
                  <div className="text-gray-300 mb-2">
                    <i className="fa fa-file-archive fa-3x"></i>
                  </div>
                  No file uploaded
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Card>

      <div className="d-md-flex align-items-center">
        <div className="me-md-auto text-md-left text-center mb-2 mb-md-0"></div>
        <div className="pagination mb-0 justify-content-center">
          <span onClick={() => eventSubmit()} className="btn btn-outline-theme me-2 mb-1">
            <span>Finish</span>
          </span>
        </div>
      </div>
      <hr className="mb-4" />

      <ModalUploadFile show={modalUploadFile.show} submitClick={(e) => handleSubmitFile(e)} onHide={() => setModalUploadFile(false)} data={modalUploadFile} />
      <ModalLoading show={modalLoading.show} title={modalLoading.title} text={modalLoading.text} value={loadingValue} onHide={() => setModalLoading({ ...modalLoading, show: false })} />
      <ModalInfo show={modalInfo.show} clicked={() => setModalInfo({ ...modalInfo, show: false })} onHide={() => setModalInfo({ ...modalInfo, show: false })} theme={"danger"} title={modalInfo.title} text={modalInfo.text} />
      <ModalConfirm show={modalConfirm.show} clicked={() => handleDeleteConfirmed()} onHide={() => setModalConfirm({ ...modalConfirm, show: false })} title={modalConfirm.title} text={modalConfirm.text} theme={"danger"} />
    </>
  );
}

export default ReportDocument;
