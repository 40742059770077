

function Pagination1({pageInfo,nextClick,prevClick}){

    const currentPage=pageInfo.currentPage
    const lastPage=pageInfo.lastPage
    return(
        <div className="d-md-flex align-items-center mt-3">
            <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                Showing {pageInfo.currentPage} to {pageInfo.to} of {pageInfo.total} entries
            </div>
            <ul className="pagination mb-0 justify-content-center">
                <li className={currentPage===1?"page-item disabled":"page-item"}><a onClick={prevClick} className="page-link" href="#/">Previous</a></li>
                <li className={currentPage===lastPage?"page-item disabled":"page-item"}><a onClick={nextClick} className="page-link" href="#/">Next</a></li>
                {/* <li className="page-item disabled"><a className="page-link" href="#/">{'<<'}</a></li>
                <li className="page-item"><a className="page-link" href="#/">1</a></li>
                <li className="page-item"><a className="page-link" href="#/">..</a></li>
                <li className="page-item"><a className="page-link" href="#/">99</a></li>
                <li className="page-item"><a className="page-link" href="#/">100</a></li>
                <li className="page-item"><a className="page-link" href="#/">101</a></li>
                <li className="page-item disabled"><a className="page-link" href="#/">..</a></li>
                <li className="page-item"><a className="page-link" href="#/">{lastPage}</a></li>
                <li className="page-item"><a className="page-link" href="#/">{'>>'}</a></li> */}
            </ul>
		</div>
    )
}

export default Pagination1