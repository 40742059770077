import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";

const parameter = { id: "", val: "", edit: "" };

function ModalEditNumber({ show, onHide, submitClick, data = {} }) {
  const attr = {
    title: data.hasOwnProperty("title") ? data.title : "Edit Value",
    text: data.hasOwnProperty("text") ? data.text : "Please edit as you wish :)",
    data: data.hasOwnProperty("data") ? data.data : { id: "", val: "", edit: "" },
  };
  const [val, setVal] = useState(attr.data);

  const handleChange = (e) => {
    setVal({ id: attr.data.id, val: e.target.value, edit: attr.data.edit });
  };
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setVal(parameter);
          onHide();
        }}
      >
        <ModalHeader closeButton>
          <h5 className="modal-title">{attr.title}</h5>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            {/* <label className="form-label">Name</label> */}
            <input type="number" onChange={(e) => handleChange(e)} className="form-control" defaultValue={attr.data.val} />
          </div>
          <div className="alert bg-inverse bg-opacity-15">{attr.text}</div>
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={() => submitClick(val)} className={val.val ? "btn btn-outline-theme" : "btn btn-outline-theme disabled"}>
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalEditNumber;
