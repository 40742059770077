import React, { useEffect, useState, useReducer } from "react";
import { Card, CardBody } from "./../../components/card/card.jsx";
import { NavScrollTo } from "./../../components/nav-scroll-to/nav-scroll-to.jsx";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { CardFooter } from "react-bootstrap";
import axiosInstance from "../../config/axios.js";

function reducer(state, action) {
  switch (action.type) {
    case "changeName": {
      return { ...state, name: action.value };
    }
    case "changeNrp": {
      return { ...state, nrp: action.value };
    }
    case "changeMp": {
      return { ...state, mp: action.value };
    }
    case "changeCompany": {
      return { ...state, company: action.value };
    }
    case "changeFunctional": {
      return { ...state, functional: action.value };
    }
    case "changeSection": {
      return { ...state, section: action.value };
    }
    case "changeJobArea": {
      return { ...state, jobArea: action.value };
    }
    case "changeSubSection": {
      return { ...state, subSection: action.value };
    }
    case "changeStructural": {
      return { ...state, structural: action.value };
    }
    case "changeJoinDate": {
      return { ...state, joinDate: action.value };
    }
    case "changeJoinMaco": {
      return { ...state, joinMaco: action.value };
    }
    case "changePod": {
      return { ...state, pod: action.value };
    }
    case "changeDob": {
      return { ...state, dob: action.value };
    }
    case "changeSpecialist": {
      return { ...state, specialist: action.value };
    }
    case "changePhone1": {
      return { ...state, phone1: action.value };
    }
    case "changePhone2": {
      return { ...state, phone2: action.value };
    }
    case "changeEmail": {
      return { ...state, email: action.value };
    }
    case "changeAbout": {
      return { ...state, about: action.value };
    }
    case "changeRemark": {
      return { ...state, remark: action.value };
    }
  }
}

const initialForm = {
  name: "",
  nrp: "",
  mp: "",
  company: "",
  functional: "",
  section: "",
  jobArea: "",
  subSection: "",
  structural: "",
  joinDate: "",
  joinMaco: "",
  pod: "",
  dob: "",
  grade: "",
  specialist: "",
  phone1: "",
  phone2: "",
  email: "",
  about: "",
  remark: "",
};

function NewManpower() {
  const [state, dispatch] = useReducer(reducer, initialForm);
  const [companyList, setCompanyList] = useState([]);
  const [functionalList, setFunctionalList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [subSection, setSubSection] = useState([]);
  const [structural, setStructural] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState({ status: false, message: {} });
  const navigate = useNavigate();
  let jobArea = [
    { value: "MINING", label: "MINING" },
    { value: "HAULING", label: "HAULING" },
    { value: "PORT", label: "PORT" },
  ];

  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
      validateStatus: false,
    };
    return headers;
  }

  const submitResponse = (data) => {
    if (data.status === "error") {
      setSpinner(false);
      setError({ status: true, message: data.message });
    } else {
      return navigate("/manpower/data");
    }
  };

  const handleSubmit = () => {
    setSpinner(true);
    axiosInstance
      .post("/manpowerv2submit", state, getHeader())
      .then((res) => submitResponse(res.data))
      .catch((e) => {});
  };
  useEffect(() => {
    axiosInstance
      .get("/optionselector/company", getHeader())
      .then((res) => setCompanyList(res.data))
      .catch((e) => {});
    axiosInstance
      .get("/optionselector/functional", getHeader())
      .then((res) => setFunctionalList(res.data))
      .catch((e) => {});
    axiosInstance
      .get("/optionselector/section", getHeader())
      .then((res) => setSectionList(res.data))
      .catch((e) => {});
    axiosInstance
      .get("/optionselector/subSection", getHeader())
      .then((res) => setSubSection(res.data))
      .catch((e) => {});
    axiosInstance
      .get("/optionselector/structural", getHeader())
      .then((res) => setStructural(res.data))
      .catch((e) => {});
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10">
          <div className="row">
            <div className="col-xl-9">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#/">Manpower</a>
                </li>
                <li className="breadcrumb-item active">New</li>
              </ul>

              <h1 className="page-header">Input New Manpower Data</h1>

              <hr className="mb-4" />

              <div id="formControls" className="mb-5">
                <p>
                  Please enter data for new manpower. Make sure the manpower data has never been entered into the database system. To make sure, you can check the <Link to={"/manpower/data"}>manpower</Link> data tab and look for it in the
                  search section.
                </p>
                <Card>
                  <CardBody className="pb-2">
                    <div id="basicRequired" className="mb-5">
                      <h5>Basic Required</h5>
                      <p>This section is minimum required of data</p>
                      <div className="form-group mb-3">
                        <label className="form-label" htmlFor="name">
                          Name <span className="text-danger">*</span>
                        </label>
                        <input type="text" onChange={(e) => dispatch({ type: "changeName", value: e.target.value })} className={error.message.name ? "form-control is-invalid" : "form-control"} id="name" placeholder="Michael Paijo" />
                        {error.message.name ? <div className="invalid-feedback">{error.message.name}</div> : ""}
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-label" htmlFor="nrp">
                          NRP <span className="text-danger">*</span>
                        </label>
                        <input type="text" onChange={(e) => dispatch({ type: "changeNrp", value: e.target.value })} className={error.message.nrp ? "form-control is-invalid" : "form-control"} id="nrp" placeholder="00112233" />
                        {error.message.nrp ? <div className="invalid-feedback">{error.message.nrp}</div> : ""}
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-label" htmlFor="noMp">
                          Mine Permit Number
                        </label>
                        <input type="text" onChange={(e) => dispatch({ type: "changeMp", value: e.target.value })} className="form-control" id="noMp" placeholder="MP/SIS-2109789" />
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-label">
                          Company <span className="text-danger">*</span>
                        </label>
                        <Select onChange={(e) => dispatch({ type: "changeCompany", value: e.value })} className={error.message.company ? "form-control is-invalid" : ""} options={companyList} isSearchable classNamePrefix="react-select" />
                        {error.message.company ? <div className="invalid-feedback">{error.message.company}</div> : ""}
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-label">
                          Functional Position <span className="text-danger">*</span>
                        </label>
                        <Select
                          onChange={(e) => dispatch({ type: "changeFunctional", value: e.value })}
                          className={error.message.functional ? "form-control is-invalid" : ""}
                          options={functionalList}
                          isSearchable
                          classNamePrefix="react-select"
                        />
                        {error.message.functional ? <div className="invalid-feedback">{error.message.functional}</div> : ""}
                      </div>
                      <hr />
                    </div>
                    <div id="advance" className="mb-5">
                      <h5>Advance</h5>
                      <div className="form-group mb-3">
                        <label className="form-label">Section</label>
                        <Select onChange={(e) => dispatch({ type: "changeSection", value: e.value })} options={sectionList} isSearchable classNamePrefix="react-select" />
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-label">Job Area</label>
                        <Select onChange={(e) => dispatch({ type: "changeJobArea", value: e.value })} options={jobArea} isSearchable classNamePrefix="react-select" />
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-label">Sub Section</label>
                        <Select onChange={(e) => dispatch({ type: "changeSubSection", value: e.value })} options={subSection} isSearchable classNamePrefix="react-select" />
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-label">Structural Position</label>
                        <Select onChange={(e) => dispatch({ type: "changeStructural", value: e.value })} options={structural} isSearchable classNamePrefix="react-select" />
                      </div>
                      <hr />
                    </div>
                    <div id="history" className="mb-5">
                      <h5>History</h5>
                      <div className="form-group mb-3">
                        <label className="form-label" htmlFor="joinDate">
                          Join Date
                        </label>
                        <input type="date" onChange={(e) => dispatch({ type: "changeJoinDate", value: e.target.value })} className="form-control" id="joinDate" />
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-label" htmlFor="joinMaco">
                          Join Maco
                        </label>
                        <input type="date" onChange={(e) => dispatch({ type: "changeJoinMaco", value: e.target.value })} className="form-control" id="joinMaco" />
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-label" htmlFor="pob">
                          Place of Birth
                        </label>
                        <input type="text" onChange={(e) => dispatch({ type: "changePod", value: e.target.value })} className="form-control" id="pob" placeholder="Example : Malang" />
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-label" htmlFor="dob">
                          Date of Birth
                        </label>
                        <input type="date" onChange={(e) => dispatch({ type: "changeDob", value: e.target.value })} className="form-control" id="dob" />
                      </div>
                      <hr />
                    </div>
                    <div id="career" className="mb-5">
                      <h5>Career and Development</h5>
                      <div className="form-group mb-3">
                        <label className="form-label" htmlFor="grade">
                          Grade
                        </label>
                        <input type="text" onChange={(e) => dispatch({ type: "changeGrade", value: e.target.value })} className="form-control" id="grade" placeholder="Example : L1" />
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-label" htmlFor="specialist">
                          Specialist
                        </label>
                        <input type="text" onChange={(e) => dispatch({ type: "changeSpecialist", value: e.target.value })} className="form-control" id="specialist" placeholder="Example : DT-RK" />
                      </div>
                      <hr />
                    </div>

                    <div id="contacts" className="mb-5">
                      <h5>Contacts</h5>
                      <div className="form-group mb-3">
                        <label className="form-label" htmlFor="phone1">
                          Phone 1
                        </label>
                        <input type="text" onChange={(e) => dispatch({ type: "changePhone1", value: e.target.value })} className="form-control" id="phone1" placeholder="Example : +6282522345678" />
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-label" htmlFor="phone2">
                          Phone 2
                        </label>
                        <input type="text" onChange={(e) => dispatch({ type: "changePhone2", value: e.target.value })} className="form-control" id="phone2" placeholder="Example : +6282522345678" />
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-label" htmlFor="email">
                          Email
                        </label>
                        <input type="email" onChange={(e) => dispatch({ type: "changeEmail", value: e.target.value })} className="form-control" id="email" />
                      </div>
                      <hr />
                    </div>
                    <div id="additional">
                      <h5>Additional</h5>
                      <div className="form-group mb-3">
                        <label className="form-label" htmlFor="about">
                          About
                        </label>
                        <textarea id="about" onChange={(e) => dispatch({ type: "changeAbout", value: e.target.value })} className="form-control" rows="3"></textarea>
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-label" htmlFor="remark">
                          Remark
                        </label>
                        <textarea id="remark" onChange={(e) => dispatch({ type: "changeRemark", value: e.target.value })} className="form-control" rows="3"></textarea>
                      </div>
                    </div>
                  </CardBody>
                  <CardFooter>
                    {error.status ? (
                      <p className="text-danger">
                        There was data entry error !, please check again.{" "}
                        <a className="text-theme" href="#basicRequired" data-toggle="scroll-to">
                          Goto Top
                        </a>
                      </p>
                    ) : (
                      ""
                    )}
                    {spinner ? (
                      <button type="button" className="btn btn-lg btn-outline-theme" disabled>
                        <i className="spinner-border spinner-border-sm"></i>
                      </button>
                    ) : (
                      <button type="button" onClick={() => handleSubmit()} className="btn btn-lg btn-outline-theme">
                        Submit
                      </button>
                    )}
                  </CardFooter>
                </Card>
              </div>
            </div>
            <div className="col-xl-3">
              <NavScrollTo>
                <nav className="nav text-nowrap">
                  <a className="nav-link" href="#basicRequired" data-toggle="scroll-to">
                    Basic Required
                  </a>
                  <a className="nav-link" href="#advance" data-toggle="scroll-to">
                    Advance
                  </a>
                  <a className="nav-link" href="#history" data-toggle="scroll-to">
                    History
                  </a>
                  <a className="nav-link" href="#career" data-toggle="scroll-to">
                    Career and Development
                  </a>
                  <a className="nav-link" href="#contacts" data-toggle="scroll-to">
                    Contacts
                  </a>
                  <a className="nav-link" href="#additional" data-toggle="scroll-to">
                    Additional
                  </a>
                </nav>
              </NavScrollTo>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewManpower;
