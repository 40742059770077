import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";

const parameter = { id: null, val: "" };

function ModalNewText({ show, onHide, submitClick, title, text, id }) {
  const [val, setVal] = useState(parameter);

  const handleChange = (e) => {
    setVal({ id: id ? id : null, val: e.target.value });
  };
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setVal(parameter);
          onHide();
        }}
      >
        <ModalHeader closeButton>
          <h5 className="modal-title">{title ? title : "Blank Title"}</h5>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            {/* <label className="form-label">Name</label> */}
            <textarea onChange={(e) => handleChange(e)} className="form-control" />
          </div>
          <div className="alert bg-inverse bg-opacity-15">{text ? text : "Blank text"}</div>
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={() => submitClick(val)} className={val.val ? "btn btn-outline-theme" : "btn btn-outline-theme disabled"}>
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalNewText;
