import { useEffect, useReducer, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import axiosInstance from "../../config/axios";

function currentDate() {
  const date = new Date();
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

function reducer(state, action) {
  switch (action.type) {
    case "createDate":
      return { ...state, createDate: action.value };
    case "to":
      return { ...state, to: action.value };
    case "from":
      return { ...state, from: action.value };
    case "subject":
      return { ...state, subject: action.value };
    case "jenisTaf":
      return { ...state, jenisTaf: action.value };
    case "trainingTitle":
      return { ...state, trainingTitle: action.value };
    case "lokasi":
      return { ...state, lokasi: action.value };
    case "start":
      return { ...state, start: action.value };
    case "end":
      return { ...state, end: action.value };
    case "penyelenggara":
      return { ...state, penyelenggara: action.value };
    case "biaya":
      return { ...state, biaya: action.value };
    case "diajukanOleh":
      return { ...state, diajukanOleh: action.value };
    case "disetujuiOleh":
      return { ...state, disetujuiOleh: action.value };
    case "diperiksaOleh":
      return { ...state, diperiksaOleh: action.value };
    case "divHead":
      return { ...state, divHead: action.value };
    case "tafCode":
      return { ...state, tafCode: action.value };
  }
}
let initialValue = {
  createDate: currentDate(),
  to: "People Development Division",
  from: "PPD MACO",
  subject: "Pengajuan Pelatihan Kerja",
  jenisTaf: "Pelatihan",
  trainingTitle: "",
  lokasi: "",
  start: "",
  end: "",
  penyelenggara: "",
  biaya: "-",
  diajukanOleh: "Didit Pramudya",
  disetujuiOleh: "",
  diperiksaOleh: "",
  divHead: "",
  tafCode: "",
};

function ModalGenerateTaf({ show, onHide, id, submitClick }) {
  const [values, dispatch] = useReducer(reducer, initialValue);
  const [peserta, setPeserta] = useState([]);
  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  function initialValue2(data) {
    dispatch({ type: "trainingTitle", value: data.training_title.judul });
    dispatch({ type: "lokasi", value: data.location.lokasiTraining });
    dispatch({ type: "start", value: data.start });
    dispatch({ type: "end", value: data.end });
    dispatch({ type: "penyelenggara", value: data.institution.lembaga });
    dispatch({ type: "tafCode", value: data.tafCode });
  }

  function handleOnShow() {
    axiosInstance
      .get("/plan-training/one-set-for-taf/" + id, getHeader())
      .then((res) => {
        initialValue2(res.data.data);
        setPeserta(res.data.data.peserta);
      })
      .catch((e) => {});
  }

  return (
    <>
      <Modal show={show} onShow={() => handleOnShow()} onHide={() => onHide()}>
        <ModalHeader closeButton>
          <h5 className="modal-title">Generate Training Application Form</h5>
        </ModalHeader>
        <ModalBody>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="kode">
              Create Date
            </label>
            <input onChange={(e) => dispatch({ type: "createDate", value: e.target.value })} type="date" className="form-control" defaultValue={values.createDate} id="kode" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="to">
              To
            </label>
            <input onChange={(e) => dispatch({ type: "to", value: e.target.value })} type="text" className="form-control" defaultValue={values.to} id="to" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="from">
              From
            </label>
            <input onChange={(e) => dispatch({ type: "from", value: e.target.value })} type="text" defaultValue={values.from} className="form-control" id="from" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="subject">
              Subject
            </label>
            <input onChange={(e) => dispatch({ type: "subject", value: e.target.value })} type="text" defaultValue={values.subject} className="form-control" id="subject" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="jenisTaf">
              Jenis TAF
            </label>
            <input onChange={(e) => dispatch({ type: "jenisTaf", value: e.target.value })} type="text" defaultValue={values.jenisTaf} className="form-control" id="jenisTaf" />
          </div>
          <hr />
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="trainingTitle">
              Training Title
            </label>
            <input onChange={(e) => dispatch({ type: "trainingTitle", value: e.target.value })} type="text" defaultValue={values.trainingTitle} disabled className="form-control" id="trainingTitle" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="lokasi">
              Lokasi
            </label>
            <input onChange={(e) => dispatch({ type: "lokasi", value: e.target.value })} type="text" defaultValue={values.lokasi} disabled className="form-control" id="lokasi" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="start">
              Start Date
            </label>
            <input onChange={(e) => dispatch({ type: "start", value: e.target.value })} type="date" defaultValue={values.start} disabled className="form-control" id="start" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="end">
              End Date
            </label>
            <input onChange={(e) => dispatch({ type: "end", value: e.target.value })} type="date" defaultValue={values.end} disabled className="form-control" id="end" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="penyelenggara">
              Penyelenggara
            </label>
            <input onChange={(e) => dispatch({ type: "penyelenggara", value: e.target.value })} type="text" defaultValue={values.penyelenggara} disabled className="form-control" id="penyelenggara" />
          </div>
          <hr />
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="biaya">
              Biaya
            </label>
            <input onChange={(e) => dispatch({ type: "biaya", value: e.target.value })} type="text" defaultValue={values.biaya} className="form-control" id="biaya" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="diajukanOleh">
              Diajukan Oleh
            </label>
            <input onChange={(e) => dispatch({ type: "diajukanOleh", value: e.target.value })} type="text" defaultValue={values.diajukanOleh} className="form-control" id="diajukanOleh" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="disetujuiOleh">
              Disetujui Oleh
            </label>
            <input onChange={(e) => dispatch({ type: "disetujuiOleh", value: e.target.value })} type="text" defaultValue={values.disetujuiOleh} className="form-control" id="disetujuiOleh" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="diperiksaOleh">
              Diperiksa Oleh
            </label>
            <input onChange={(e) => dispatch({ type: "diperiksaOleh", value: e.target.value })} type="text" defaultValue={values.diperiksaOleh} className="form-control" id="diperiksaOleh" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="divHead">
              Div. Head PEDD/Director HR
            </label>
            <input onChange={(e) => dispatch({ type: "divHead", value: e.target.value })} type="text" defaultValue={values.divHead} className="form-control" id="divHead" />
          </div>
        </ModalBody>
        <ModalFooter>
          <button onClick={() => submitClick({ values, peserta })} type="button" className="btn btn-outline-theme">
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalGenerateTaf;
