import React, { useEffect, useReducer, useState } from "react";
import { Card, CardBody, CardHeader } from "./../../components/card/card.jsx";
import { Outlet, useNavigate } from "react-router-dom";
import axiosInstance from "../../config/axios.js";
import { CardFooter } from "react-bootstrap";
import Select from "react-select";
import ModalInfo from "../../components/modal/modalInfo.jsx";

function reducer(state, action) {
  switch (action.type) {
    case "trainingTitle": {
      return { ...state, trainingTitle: action.value };
    }
    case "location": {
      return { ...state, location: action.value };
    }
    case "institution": {
      return { ...state, institution: action.value };
    }
    case "instructor": {
      return { ...state, instructor: action.value };
    }
    case "startDate": {
      return { ...state, startDate: action.value };
    }
    case "endDate": {
      return { ...state, endDate: action.value };
    }
    case "remark": {
      return { ...state, remark: action.value };
    }
  }
}
const initialValue = {
  trainingTitle: "",
  location: "",
  institution: "",
  instructor: "",
  startDate: "",
  endDate: "",
  remark: "",
};

function PlanTrainingNewReport() {
  const [eventData, eventDataDispatch] = useReducer(reducer, initialValue);
  const [listTrainingTitle, setListTrainingTitle] = useState([]);
  const [listLocation, setListLocation] = useState([]);
  const [listInstitution, setListInstitution] = useState([]);
  const [listInstructor, setListInstructor] = useState([]);
  const [invalid, setInvalid] = useState({ errors: {} });
  const [duplicateModal, setDuplicateModal] = useState({ show: false, text: "Blank Text", title: "Blank Title" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  function handleNextButton() {
    setLoading(true);
    axiosInstance
      .post("/plan-training-new/main", eventData, getHeader())
      .then((res) => {
        if (res.data.data.message == "duplicate") {
          setDuplicateModal({ show: true, text: "This plan training is already exist....!", title: "Failed" });
          setLoading(false);
          return;
        }
        if (res.data.data.message == "notValidate") {
          setInvalid(res.data.data);
          setLoading(false);
          return;
        }
        navigate("/training/plan/detail/" + res.data.data.id);
      })
      .catch((e) => {});
  }
  useEffect(() => {
    axiosInstance
      .get("/optionselector/trainingTitle", getHeader())
      .then((e) => setListTrainingTitle(e.data))
      .catch((e) => {});
    axiosInstance
      .get("/optionselector/location", getHeader())
      .then((e) => setListLocation(e.data))
      .catch((e) => {});
    axiosInstance
      .get("/optionselector/institution", getHeader())
      .then((e) => setListInstitution(e.data))
      .catch((e) => {});
    axiosInstance
      .get("/optionselector/instructor", getHeader())
      .then((e) => setListInstructor(e.data))
      .catch((e) => {});
  }, []);
  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="row">
              <div className="col-xl-9">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#/">TRAINING</a>
                  </li>
                  <li className="breadcrumb-item active">NEW</li>
                </ul>

                <h1 className="page-header">Create New Plan Training</h1>

                <hr className="mb-4" />

                {/* ------------------------------------------------------CONTENT------------------------------------------------------- */}
                <div id="formControls" className="mb-5">
                  {/* <h4>Suggestions</h4>
                  <p></p> */}
                  <Card>
                    <CardBody className="pb-2">
                      <form>
                        <div className="row">
                          <div className="col-xl-12">
                            <div className="form-group mb-3">
                              <label className="form-label" htmlFor="exampleFormControlInput1">
                                Training Title
                              </label>
                              <Select options={listTrainingTitle} onChange={(e) => eventDataDispatch({ type: "trainingTitle", value: e.value })} classNamePrefix="react-select" />
                              {invalid.errors.trainingTitle ? <p className="text-danger">{invalid.errors.trainingTitle}</p> : ""}
                            </div>
                            <div className="form-group mb-3">
                              <label className="form-label" htmlFor="exampleFormControlInput1">
                                Location
                              </label>
                              <Select options={listLocation} onChange={(e) => eventDataDispatch({ type: "location", value: e.value })} classNamePrefix="react-select" />
                              {invalid.errors.location ? <p className="text-danger">{invalid.errors.location}</p> : ""}
                            </div>
                            <div className="form-group mb-3">
                              <label className="form-label" htmlFor="exampleFormControlInput1">
                                Institution
                              </label>
                              <Select options={listInstitution} onChange={(e) => eventDataDispatch({ type: "institution", value: e.value })} classNamePrefix="react-select" />
                              {invalid.errors.institution ? <p className="text-danger">{invalid.errors.institution}</p> : ""}
                            </div>
                            <div className="form-group mb-3 ">
                              <label className="form-label" htmlFor="exampleFormControlInput1">
                                Instructor
                              </label>
                              <Select options={listInstructor} onChange={(e) => eventDataDispatch({ type: "instructor", value: e.value })} classNamePrefix="react-select" />
                              {invalid.errors.instructor ? <p className="text-danger">{invalid.errors.instructor}</p> : ""}
                            </div>
                            <div className="form-group mb-3 ">
                              <label className="form-label" htmlFor="exampleFormControlInput1">
                                Start Date
                              </label>
                              <input type="date" onChange={(e) => eventDataDispatch({ type: "startDate", value: e.target.value })} className="form-control" />
                              {invalid.errors.startDate ? <p className="text-danger">{invalid.errors.startDate}</p> : ""}
                            </div>
                            <div className="form-group mb-3 ">
                              <label className="form-label" htmlFor="exampleFormControlInput1">
                                End Date
                              </label>
                              <input type="date" onChange={(e) => eventDataDispatch({ type: "endDate", value: e.target.value })} className="form-control" />
                              {invalid.errors.endDate ? <p className="text-danger">{invalid.errors.endDate}</p> : ""}
                            </div>
                            <div className="form-group mb-3 ">
                              <label className="form-label" htmlFor="exampleFormControlInput1">
                                Remark
                              </label>
                              <textarea onChange={(e) => eventDataDispatch({ type: "remark", value: e.target.value })} className="form-control" />
                            </div>
                          </div>
                        </div>
                      </form>
                    </CardBody>
                    <CardFooter>
                      {loading ? (
                        <div className="spinner-border"></div>
                      ) : (
                        <button onClick={() => handleNextButton()} type="submit" className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3">
                          NEXT
                        </button>
                      )}
                    </CardFooter>
                  </Card>
                </div>
                {/* ------------------------------------------------------END CONTENT------------------------------------------------------- */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalInfo
        show={duplicateModal.show}
        clicked={() => setDuplicateModal({ ...duplicateModal, show: false })}
        theme={"danger"}
        title={duplicateModal.title}
        text={duplicateModal.text}
        onHide={() => setDuplicateModal({ ...duplicateModal, show: false })}
      />
    </>
  );
}

export default PlanTrainingNewReport;
