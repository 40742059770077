import { Modal } from 'react-bootstrap';
function ModalLoadingPage({show,hide}){

    return(
        <>
        <Modal className='modal-cover' show={show} onHide={hide} backdrop="static" keyboard={false}>
            <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h3 className="modal-title">Loading.....</h3>
                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal"></button> */}
                </div>
                <div className="modal-body">
                    <p className="mb-3">
                        Please wait<br />
                        Calculating content......
                    </p>
                    <div className="spinner-grow" role="status">
  <                     span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow" role="status">
  <                     span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow" role="status">
  <                     span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow" role="status">
  <                     span className="visually-hidden">Loading...</span>
                    </div>
                    {/* <div className="row mb-5">
                        <div className="col-md-9">
                            <input type="text" placeholder="Your email address here" className="form-control form-control-lg" name="icon-filter" />
                        </div>
                        <div className="col-md-3">
                            <button type="button" className="btn btn-outline-theme btn-lg btn-block">Continue</button>
                        </div>
                    </div> */}
                    {/* <div className="pt-3">
                        <a href="#/" className="text-muted fs-12px">What is it? Terms and conditions</a>
                    </div> */}
                </div>
            </div>
            </div>
            
        </Modal>

  
        </>
    )
}

export default ModalLoadingPage