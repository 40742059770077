

function TrainingChart1(){
    let themeColor = (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim();
    let themeFont = (getComputedStyle(document.body).getPropertyValue('--bs-body-font-family')).trim();
    let themeFontWeight = (getComputedStyle(document.body).getPropertyValue('--bs-body-font-weight')).trim();
    let gray300 = (getComputedStyle(document.body).getPropertyValue('--bs-gray-300')).trim();
    let orange = (getComputedStyle(document.body).getPropertyValue('--bs-warning')).trim();
    let inverse = (getComputedStyle(document.body).getPropertyValue('--bs-inverse')).trim();
    let inverseRgb = (getComputedStyle(document.body).getPropertyValue('--bs-inverse-rgb')).trim();
    let borderColor = (getComputedStyle(document.body).getPropertyValue('--bs-border-color')).trim();
    return {
            chart: { stacked: true,toolbar: { show: false },zoom:{enabled:false}},
            dataLabels: { enabled: false },
            stroke: { width: [0,0],curve:'smooth' },
            colors: [themeColor, orange],
            grid: { borderColor: borderColor },
            xaxis: {
                type: 'category',
                axisBorder: { show: true, color: gray300, height: 1, width: '100%', offsetX: 0, offsetY: -1 },
                axisTicks: { show: true, borderType: 'solid', color: gray300, height: 6, offsetX: 0, offsetY: 0 },
                labels: { style: { colors: inverse, fontSize: '12px', fontFamily: themeFont, fontWeight: themeFontWeight, cssClass: 'apexcharts-xaxis-label' } }
            },

            tooltip: { fixed: { enabled: true, position: 'topLeft', offsetY: 30, offsetX: 60 } },
            legend: { horizontalAlign: 'left', offsetX: 40, fontFamily: themeFont, labels: { colors: inverse } },


            yaxis:[
                {   
                    min:0,
                    opposite: false,
                    axisTicks: {show: true,},
                    axisBorder: {show: true,color: themeColor},
                    labels: {style: {colors: themeColor,}},
                    // title: {text: "Close",style: {color: themeColor,}}
                }
                ],
    

}

}
export default TrainingChart1