import { useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import axiosInstance from "../../config/axios";
import { fileToBase64 } from "file64";
const initialValue = {
  id: null,
  soal: "",
  img: { edit: false, base64: null },
  answer: "-",
};
function ModalNewSoalTf({ show, onHide, submitClick, id }) {
  const [soalEdited, setSoalEdited] = useState(initialValue);
  const [soal, setSoal] = useState("");
  const [img64, setImg64] = useState({ edit: false, base64: null });
  const [imgSrc, setImgSrc] = useState("");
  const [submitBtn, setSubmitBtn] = useState(false);
  const [warning, setWarning] = useState([]);
  const inputFile = useRef(null);

  function handleClickUploadFile() {
    inputFile.current.click();
  }
  function handleClickDeleteFile() {
    setSubmitBtn(true);
    inputFile.current.value = "";
    setImgSrc("");
    setImg64({
      edit: true,
      base64: "",
    });
    setSoalEdited({
      ...soalEdited,
      id: id,
      img: {
        edit: true,
        base64: "",
      },
    });
  }
  function handleOnShow() {
    setSoal("");
    setImgSrc("");
    setWarning("");
    setSubmitBtn(false);
    inputFile.current.value = "";
    setImg64({ edit: false, base64: null });
    setSoalEdited(initialValue);
  }
  function checkRule(data) {
    let errors = [];
    if (data.answer == "-") {
      errors.push("Jawaban Belum ditentukan");
    }
    if (!data.soal) {
      errors.push("Soal masih kosong");
    }
    return errors;
  }
  function handleSubmit(data) {
    if (checkRule(soalEdited).length > 0) {
      setWarning(checkRule(soalEdited));
      return;
    }
    submitClick(data);
  }
  function handleAnswerChange(val) {
    setSubmitBtn(true);
    setSoalEdited({ ...soalEdited, answer: val });
  }
  async function handleChangeImage(e) {
    setSubmitBtn(true);
    const file = e.target.files[0];
    setImgSrc(URL.createObjectURL(file));
    const base64 = await fileToBase64(file);
    setImg64({
      edit: true,
      base64: base64,
    });
    setSoalEdited({
      ...soalEdited,
      id: id,
      img: {
        edit: true,
        base64: base64,
      },
    });
  }

  function handleChangeSoal(val) {
    setSubmitBtn(true);
    setSoalEdited({ ...soalEdited, soal: val });
  }

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onShow={() => handleOnShow()}
        onHide={() => {
          onHide();
        }}
      >
        <ModalHeader closeButton>
          <h5 className="modal-title">New True or False Question</h5>
        </ModalHeader>
        <ModalBody>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="exampleFormControlInput1">
              Question
            </label>

            <textarea onChange={(e) => handleChangeSoal(e.target.value)} className="form-control mb-3" placeholder="Type text question"></textarea>
            {imgSrc ? <img className="img-thumbnail" src={imgSrc}></img> : ""}
            <input ref={inputFile} onChange={(e) => handleChangeImage(e)} className="form-control" type="file" hidden />
            <div className="mt-sm-0 mb-3 mt-2">
              <a href="#/" onClick={() => handleClickUploadFile()} className="text-inverse me-3 text-opacity-75 text-decoration-none">
                <i className="fa fa-upload fa-fw text-theme"></i> image
              </a>
              {imgSrc ? (
                <a href="#/" onClick={() => handleClickDeleteFile()} className="text-inverse text-opacity-75 text-decoration-none">
                  <i className="fa fa-trash fa-fw text-danger"></i> Delete
                </a>
              ) : (
                ""
              )}
            </div>

            <label className="form-label" htmlFor="exampleFormControlInput1">
              Answer Choice
            </label>

            <div className="form-group small">
              <div className="form-check">
                <input type="radio" className="form-check-input" onChange={(e) => handleAnswerChange(true)} name="answer" />
                <label className="form-label">TRUE</label>
              </div>
              <div className="form-check">
                <input type="radio" className="form-check-input" onChange={(e) => handleAnswerChange(false)} name="answer" />
                <label className="form-label">FALSE</label>
              </div>
            </div>
          </div>
          {warning.length > 0
            ? warning.map((w, index) => (
                <p key={index} className="text-danger">
                  {w}
                </p>
              ))
            : ""}
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={() => handleSubmit(soalEdited)} className={submitBtn ? "btn btn-outline-theme" : "btn btn-outline-theme disabled"}>
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalNewSoalTf;
