import axios from "axios";
import config from "./config";

const axiosInstance2 = axios.create({});

axiosInstance2.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.replace(config.apkUrl + "login");
      // Handle 401 error, e.g., redirect to login or refresh token
    }
    return Promise.reject(error);
  }
);

export default axiosInstance2;
