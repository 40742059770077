import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import Select from "react-select";
import axiosInstance from "../../config/axios.js";

function ModalAddParticipantTraining({ show, onHide, submitClick }) {
  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }
  const offsetValue = {
    id: null,
    participant: "",
    preTest: "",
    postTest: "",
    practice: "",
    result: "",
    remark: "",
  };

  const resultOption = [
    { value: "Pass", label: "Pass" },
    { value: "Fail", label: "Fail" },
  ];
  const [option, setOption] = useState([]);
  const [value, setValue] = useState(offsetValue);
  const getOption = () => {
    setValue(offsetValue);
    axiosInstance
      .get("/optionselector/manpower", getHeader())
      .then((res) => setOption(res.data))
      .catch((e) => {});
  };
  const handleSubmit = () => {
    submitClick(value);
  };
  return (
    <>
      <Modal size="lg" backdrop={"static"} show={show} onShow={() => getOption()} onHide={() => onHide()}>
        <ModalHeader closeButton>
          <h5 className="modal-title">Add Participant Training</h5>
        </ModalHeader>
        <ModalBody>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="participant">
              Participant
            </label>
            <Select id="participant" onChange={(e) => setValue({ ...value, participant: e.value })} options={option} isSearchable classNamePrefix="react-select" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="preTest">
              Pre Test
            </label>
            <input className="form-control" onChange={(e) => setValue({ ...value, preTest: e.target.value })} type="number" id="preTest" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="postTest">
              Post Test
            </label>
            <input className="form-control" onChange={(e) => setValue({ ...value, postTest: e.target.value })} type="number" id="postTest" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="practice">
              Practice
            </label>
            <input className="form-control" onChange={(e) => setValue({ ...value, practice: e.target.value })} type="number" id="practice" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="result">
              Result
            </label>
            <Select id="result" onChange={(e) => setValue({ ...value, result: e.value })} options={resultOption} classNamePrefix="react-select" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="remark">
              Remark
            </label>
            <textarea className="form-control" onChange={(e) => setValue({ ...value, remark: e.target.value })} id="reamrk"></textarea>
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={() => handleSubmit()} className={value.participant && value.result ? "btn btn-outline-theme" : "btn btn-outline-theme disabled"}>
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalAddParticipantTraining;
