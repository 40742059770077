import { useEffect, useState, useReducer } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Select from "react-select";
import axiosInstance from "../../config/axios";
import { Card, CardBody } from "./../../components/card/card.jsx";
import ModalConfirm from "../../components/modal/modalConfirm.jsx";

function reducer(state, action) {
  switch (action.type) {
    case "competencyName": {
      return { ...state, competencyName: action.value };
    }
    case "mpName": {
      return { ...state, mpName: action.value };
    }
    case "instructor": {
      return { ...state, instructor: action.value };
    }
    case "mentorType": {
      return { ...state, mentorType: action.value };
    }
    case "startDate": {
      return { ...state, startDate: action.value };
    }
    case "endDate": {
      return { ...state, endDate: action.value };
    }
    case "startTime": {
      return { ...state, startTime: action.value };
    }
    case "endTime": {
      return { ...state, endTime: action.value };
    }
    case "remark": {
      return { ...state, remark: action.value };
    }
  }
}
const initialValue = {
  competencyName: "",
  mpName: "",
  instructor: "",
  mentorType: "",
  startDate: "",
  endDate: "",
  startTime: "",
  endTime: "",
  remark: "",
};
function MentorReportEvent() {
  const [id, submitedEvent, setSubmitedEvent, setId, setWizardData] = useOutletContext();
  const [eventData, eventDataDispatch] = useReducer(reducer, initialValue);
  const [listCompetencyTitle, setlistCompetencyTitle] = useState([]);
  const [listManpower, setlistManpower] = useState([]);
  const [listInstructor, setlistInstructor] = useState([]);
  const [listMentoringType, setListMentoringType] = useState([]);
  const [modalDuplicateEvent, setModalDuplicateEvent] = useState({ show: false, title: "blank", text: "blank" });
  const [nextBtn, setNextBtn] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [spinnerComp, setSpinnerComp] = useState(false);
  const navigate = useNavigate();

  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  function resetForm() {
    eventDataDispatch({ type: "competencyName", value: "" });
    eventDataDispatch({ type: "mpName", value: "" });
    eventDataDispatch({ type: "instructor", value: "" });
    eventDataDispatch({ type: "mentorType", value: "" });
    eventDataDispatch({ type: "startDate", value: "" });
    eventDataDispatch({ type: "endDate", value: "" });
    eventDataDispatch({ type: "startTime", value: "" });
    eventDataDispatch({ type: "endTime", value: "" });
    eventDataDispatch({ type: "remark", value: "" });
  }

  function eventSubmit() {
    setSpinner(true);
    axiosInstance
      .post("/mentoring-submit", eventData, getHeader())
      .then((res) => (res.data.status ? success(res.data) : failed(res.data)))
      .catch((e) => {});

    function success(data) {
      setId(data.data.id);
      setSubmitedEvent(data.data);
      navigate("/mentoring/report/document");
    }
    function failed(data) {
      setSpinner(false);
      setModalDuplicateEvent({ show: true, title: "Warning", text: "This event already exist" });
    }
  }

  useEffect(() => {
    eventData.competencyName == "" || eventData.startTime == "" || eventData.endTime == "" || eventData.mpName == "" || eventData.instructor == "" || eventData.mentorType == "" || eventData.startDate == "" || eventData.endDate == ""
      ? setNextBtn(false)
      : setNextBtn(true);

    switch (eventData.mentorType) {
      case "NON MANDATORY":
        axiosInstance
          .get("/optionselector/competencyCode/nonMandatory", getHeader())
          .then((e) => {
            setlistCompetencyTitle(e.data);
            setSpinnerComp(false);
          })
          .catch((e) => {});
        break;
      case "MANDATORY":
        axiosInstance
          .get("/optionselector/competencyCode/mandatory/" + eventData.mpName, getHeader())
          .then((e) => {
            setlistCompetencyTitle(e.data);
            setSpinnerComp(false);
          })
          .catch((e) => {});
        break;
      case "VERIFIKASI":
        axiosInstance
          .get("/optionselector/competencyCode/verifikasi/" + eventData.mpName, getHeader())
          .then((e) => {
            setlistCompetencyTitle(e.data);
            setSpinnerComp(false);
          })
          .catch((e) => {});
        break;
      case "MULTISKILL":
        axiosInstance
          .get("/optionselector/competencyCode/multiskill/" + eventData.mpName, getHeader())
          .then((e) => {
            setlistCompetencyTitle(e.data);
            setSpinnerComp(false);
          })
          .catch((e) => {});
        break;

      default:
        break;
    }
  }, [eventData]);

  useEffect(() => {
    setSpinnerComp(true);
  }, [eventData.mentorType, eventData.mpName]);
  useEffect(() => {
    setSpinnerComp(false);
    resetForm();
    setWizardData([
      { label: "Event", status: "active" },
      { label: "Document", status: "disabled" },
      { label: "Media", status: "disabled" },
    ]);
    axiosInstance
      .get("/optionselector/competencyCode", getHeader())
      .then((e) => setlistCompetencyTitle(e.data))
      .catch((e) => {});
    axiosInstance
      .get("/optionselector/manpower", getHeader())
      .then((e) => setlistManpower(e.data))
      .catch((e) => {});
    axiosInstance
      .get("/optionselector/instructor", getHeader())
      .then((e) => setlistInstructor(e.data))
      .catch((e) => {});
    axiosInstance
      .get("/optionselector/mentoringType", getHeader())
      .then((e) => setListMentoringType(e.data))
      .catch((e) => {});
  }, []);
  return (
    <>
      <div className="d-md-flex align-items-center">
        <div className="me-md-auto text-md-left text-center mb-2 mb-md-0"></div>
        <div className="pagination mb-0 justify-content-center">
          {spinner ? (
            <div className="spinner-border"></div>
          ) : (
            <span onClick={() => eventSubmit()} className={nextBtn ? "btn btn-outline-theme me-2 mb-1" : "btn btn-outline-theme me-2 mb-1 disabled"}>
              <span>Next</span>
              <i className="fas fa-angle-double-right"></i>
            </span>
          )}
        </div>
      </div>
      <hr className="mb-4" />

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="row">
              <div className="col-xl-12">
                <Card>
                  <CardBody>
                    <div className="form-group mb-3">
                      <label className="form-label text-theme" htmlFor="mentorType">
                        Mentoring Type<span className="text-danger">*</span>
                      </label>
                      <Select id="mentorType" options={listMentoringType} onChange={(e) => eventDataDispatch({ type: "mentorType", value: e.value })} classNamePrefix="react-select" />
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label text-theme" htmlFor="mpName">
                        Mechanic Name<span className="text-danger">*</span>
                      </label>
                      <Select id="mpName" options={listManpower} onChange={(e) => eventDataDispatch({ type: "mpName", value: e.value })} classNamePrefix="react-select" />
                    </div>
                    {spinnerComp ? (
                      <div className="spinner-border"></div>
                    ) : (
                      <div className="form-group mb-3">
                        <label className="form-label text-theme" htmlFor="title">
                          Competency Name<span className="text-danger">*</span>
                        </label>
                        <Select id="title" options={listCompetencyTitle} onChange={(e) => eventDataDispatch({ type: "competencyName", value: e.value })} classNamePrefix="react-select" />
                      </div>
                    )}
                    <div className="form-group mb-3">
                      <label className="form-label text-theme" htmlFor="instructor">
                        Instructor<span className="text-danger">*</span>
                      </label>
                      <Select id="instructor" options={listInstructor} onChange={(e) => eventDataDispatch({ type: "instructor", value: e.value })} classNamePrefix="react-select" />
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label text-theme" htmlFor="startDate">
                        Start Date<span className="text-danger">*</span>
                      </label>
                      <input type="date" onChange={(e) => eventDataDispatch({ type: "startDate", value: e.target.value })} className="form-control" id="startDate" />
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label text-theme" htmlFor="endDate">
                        End Date<span className="text-danger">*</span>
                      </label>
                      <input type="date" onChange={(e) => eventDataDispatch({ type: "endDate", value: e.target.value })} className="form-control" id="endDate" />
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label text-theme" htmlFor="startTime">
                        Start Time<span className="text-danger">*</span>
                      </label>
                      <input type="time" onChange={(e) => eventDataDispatch({ type: "startTime", value: e.target.value })} className="form-control" id="startTime" />
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label text-theme" htmlFor="endTime">
                        End Time<span className="text-danger">*</span>
                      </label>
                      <input type="time" onChange={(e) => eventDataDispatch({ type: "endTime", value: e.target.value })} className="form-control" id="endTime" />
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label text-theme" htmlFor="remark">
                        Remark
                      </label>
                      <textarea onChange={(e) => eventDataDispatch({ type: "remark", value: e.target.value })} className="form-control" id="remark"></textarea>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr className="mb-4" />
      <div className="d-md-flex align-items-center">
        <div className="me-md-auto text-md-left text-center mb-2 mb-md-0"></div>
        <div className="pagination mb-0 justify-content-center">
          {spinner ? (
            <div className="spinner-border"></div>
          ) : (
            <span onClick={() => eventSubmit()} className={nextBtn ? "btn btn-outline-theme me-2 mb-1" : "btn btn-outline-theme me-2 mb-1 disabled"}>
              <span>Next</span>
              <i className="fas fa-angle-double-right"></i>
            </span>
          )}
        </div>
      </div>
      <ModalConfirm
        clicked={() => setModalDuplicateEvent({ ...modalDuplicateEvent, show: false })}
        theme={"danger"}
        show={modalDuplicateEvent.show}
        title={modalDuplicateEvent.title}
        text={modalDuplicateEvent.text}
        onHide={() => setModalDuplicateEvent({ ...modalDuplicateEvent, show: false })}
      />
    </>
  );
}

export default MentorReportEvent;
