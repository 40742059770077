import React, { useEffect, useReducer, useState } from "react";
import { Card, CardBody, CardHeader } from "./../../components/card/card.jsx";
import { Outlet, useNavigate } from "react-router-dom";
import axiosInstance from "../../config/axios.js";
import { CardFooter } from "react-bootstrap";

const stepsInitialValue = [
  { label: "Event", status: "disabled" },
  { label: "Participant", status: "disabled" },
  { label: "Document", status: "disabled" },
  { label: "Media", status: "disabled" },
];

function MentoringReport() {
  const [wizardData, setWizardData] = useState(stepsInitialValue);
  const [id, setId] = useState();
  const [submitedEvent, setSubmitedEvent] = useState();

  return (
    <>
      <div>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#/">MENTORING</a>
          </li>
          <li className="breadcrumb-item active">New Report</li>
        </ul>

        <h1 className="page-header">Create New Mentoring Report</h1>
        <hr className="mb-4" />

        <div className="mb-5">
          <Card>
            <CardHeader>
              <div className="nav-wizards-container">
                <nav className="nav nav-wizards-1 mb-2">
                  {wizardData.map((value, index) => (
                    <div className="nav-item col" key={index}>
                      <a className={"nav-link " + value.status} href="#/">
                        <div className="nav-no">{index + 1}</div>
                        <div className="nav-text">{value.label}</div>
                      </a>
                    </div>
                  ))}
                </nav>
              </div>
            </CardHeader>
            <CardBody>
              <Outlet context={[id, submitedEvent, setSubmitedEvent, setId, setWizardData]} />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default MentoringReport;
