import { useEffect, useReducer, useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import axiosInstance from "../../config/axios";

function currentDate() {
  const date = new Date();
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

function reducer(state, action) {
  switch (action.type) {
    case "eventTitle":
      return { ...state, eventTitle: action.value };
    case "prePost":
      return { ...state, prePost: action.value };
    case "share":
      return { ...state, share: action.value };
    case "release":
      return { ...state, release: action.value };
    case "randomMc":
      return { ...state, randomMc: action.value };
    case "randomTf":
      return { ...state, randomTf: action.value };
    case "randomMatch":
      return { ...state, randomMatch: action.value };
    case "balanced":
      return { ...state, balanced: action.value };
    case "scoreMc":
      return { ...state, scoreMc: action.value };
    case "scoreTf":
      return { ...state, scoreTf: action.value };
    case "scoreMatch":
      return { ...state, scoreMatch: action.value };
    case "limitMc":
      return { ...state, limitMc: action.value };
    case "limitTf":
      return { ...state, limitTf: action.value };
    case "validHour":
      return { ...state, validHour: action.value };
    case "validMinutes":
      return { ...state, validMinutes: action.value };
  }
}
const initialValue = {
  eventTitle: "",
  prePost: "post",
  share: false,
  release: true,
  randomMc: false,
  randomTf: false,
  randomMatch: false,
  balanced: true,
  scoreMc: 0,
  scoreTf: 0,
  scoreMatch: 0,
  limitMc: 0,
  limitTf: 0,
  validHour: 0,
  validMinutes: 15,
};

function ModalGenerateEvent({ show, onHide, id, submitClick }) {
  const [values, dispatch] = useReducer(reducer, initialValue);
  const [totalScore, setTotalScore] = useState(0);
  const [qtyMc, setQtyMc] = useState(0);
  const [qtyTf, setQtyTf] = useState(0);
  const [qtyMatch, setQtyMatch] = useState(0);
  const [maxLimMc, setMaxLimMc] = useState(0);
  const [maxLimTf, setMaxLimTf] = useState(0);
  const [balancedStatus, setBalancedStatus] = useState(true);
  const [disabled, setDisabled] = useState(false);

  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  function handleChangeSoalMc(val) {
    dispatch({ type: "scoreMc", value: val });
    setTotalScore(parseInt(val) + parseInt(values.scoreTf) + parseInt(values.scoreMatch));
  }
  function handleChangeSoalTf(val) {
    dispatch({ type: "scoreTf", value: val });
    setTotalScore(parseInt(values.scoreMc) + parseInt(val) + parseInt(values.scoreMatch));
  }
  function handleChangeSoalMatch(val) {
    dispatch({ type: "scoreMatch", value: val });
    setTotalScore(parseInt(values.scoreMc) + parseInt(values.scoreTf) + parseInt(val));
  }

  function initialValue2(data) {
    dispatch({ type: "eventTitle", value: initialValue.eventTitle });
    dispatch({ type: "prePost", value: initialValue.prePost });
    dispatch({ type: "share", value: initialValue.share });
    dispatch({ type: "release", value: initialValue.release });
    dispatch({ type: "randomMc", value: initialValue.randomMc });
    dispatch({ type: "randomTf", value: initialValue.randomTf });
    dispatch({ type: "randomMatch", value: initialValue.randomMatch });
    dispatch({ type: "balanced", value: initialValue.balanced });
    dispatch({ type: "scoreMc", value: initialValue.scoreMc });
    dispatch({ type: "scoreTf", value: initialValue.scoreTf });
    dispatch({ type: "scoreMatch", value: initialValue.scoreMatch });
    dispatch({ type: "limitMc", value: initialValue.limitMc });
    dispatch({ type: "limitTf", value: initialValue.limitTf });
    dispatch({ type: "validHour", value: initialValue.validHour });
    dispatch({ type: "validMinutes", value: initialValue.validMinutes });
  }

  function questionDataPreset(data) {
    setQtyMc(data.qtyMc);
    setQtyTf(data.qtyTf);
    setQtyMatch(data.qtyMatch);

    const qMc = data.qtyMc > 0 ? 1 : 0;
    const qTf = data.qtyTf > 0 ? 1 : 0;
    const qMatch = data.qtyMatch > 0 ? 1 : 0;

    if (qMc + qTf + qMatch > 1) {
      setBalancedStatus(false);
    } else {
      setBalancedStatus(true);
    }
    dispatch({ type: "limitMc", value: data.qtyMc });
    setMaxLimMc(data.qtyMc);
    dispatch({ type: "limitTf", value: data.qtyTf });
    setMaxLimTf(data.qtyTf);
  }
  function handleOnShow() {
    initialValue2();
    setDisabled(true);
    axiosInstance
      .get("/question-event/preset/" + id, getHeader())
      .then((res) => questionDataPreset(res.data.data))
      .catch((e) => {});
    setTotalScore(values.scoreMc + values.scoreTf + values.scoreMatch);
  }

  function handleHide() {
    initialValue2();
    onHide();
  }
  function hanldleChangePrePost(mode, val) {
    if (mode == "post" && val) {
      dispatch({ type: "prePost", value: "post" });
    }
    if (mode == "pre" && val) {
      dispatch({ type: "prePost", value: "pre" });
    }
  }
  useEffect(() => {
    const sc = () => {
      if (values.balanced) {
        return true;
      }
      return totalScore == 100 ? true : false;
    };
    setDisabled(values.eventTitle && sc() && values.validHour + values.validMinutes ? false : true);
  }, [values, totalScore]);

  return (
    <>
      <Modal show={show} size="lg" onShow={() => handleOnShow()} onHide={() => handleHide()}>
        <ModalHeader closeButton>
          <h5 className="modal-title">Create Your Event</h5>
        </ModalHeader>
        <ModalBody>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="title">
              Event Title
            </label>
            <input type="text" className="form-control" onChange={(e) => dispatch({ type: "eventTitle", value: e.target.value })} placeholder="Post Test PM/PK PC9100-8" id="title" />
          </div>
          <div className="form-check mb-2">
            <input onChange={(e) => hanldleChangePrePost("pre", e.target.checked)} className="form-check-input" name="type" type="radio" id="pre" />
            <label className="form-check-label" htmlFor="pre">
              Pre Test
            </label>
          </div>
          <div className="form-check mb-3">
            <input onChange={(e) => hanldleChangePrePost("post", e.target.checked)} className="form-check-input" name="type" type="radio" defaultChecked={true} id="post" />
            <label className="form-check-label" htmlFor="post">
              Post Test
            </label>
          </div>
          <div className="form-check form-switch mb-2">
            <input type="checkbox" onChange={(e) => dispatch({ type: "share", value: e.target.checked })} className="form-check-input" defaultChecked={values.share} id="share" />
            <label className="form-check-label" htmlFor="share">
              Share to all
            </label>
          </div>
          <div className="form-check form-switch mb-3">
            <input type="checkbox" onChange={(e) => dispatch({ type: "release", value: e.target.checked })} className="form-check-input" defaultChecked={values.release} id="release" />
            <label className="form-check-label" htmlFor="release">
              Release Result
            </label>
          </div>
          <div className="form-check form-switch mb-2">
            <input type="checkbox" onChange={(e) => dispatch({ type: "randomMc", value: e.target.checked })} className="form-check-input" disabled={qtyMc ? false : true} defaultChecked={values.randomMc} id="randomMc" />
            <label className="form-check-label" htmlFor="randomMc">
              Random Multiple Choice Questions
            </label>
          </div>
          <div className="form-check form-switch mb-2">
            <input type="checkbox" onChange={(e) => dispatch({ type: "randomTf", value: e.target.checked })} className="form-check-input" disabled={qtyTf ? false : true} defaultChecked={values.randomTf} id="randomTf" />
            <label className="form-check-label" htmlFor="randomTf">
              Random True False Questions
            </label>
          </div>
          <div className="form-check form-switch mb-2">
            <input type="checkbox" onChange={(e) => dispatch({ type: "randomMatch", value: e.target.checked })} className="form-check-input" disabled={qtyMatch ? false : true} defaultChecked={values.randomMatch} id="randomMatch" />
            <label className="form-check-label" htmlFor="randomMatch">
              Random Matching Questions
            </label>
          </div>
          <hr />
          <div className="form-check mb-3">
            <input onChange={(e) => dispatch({ type: "balanced", value: e.target.checked })} className="form-check-input" disabled={balancedStatus} type="checkbox" defaultChecked id="balanced" />
            <label className="form-check-label" htmlFor="balanced">
              Balanced Score
            </label>
          </div>
          {values.balanced ? (
            <div></div>
          ) : (
            <div>
              <label className="form-check-label">Total Score</label>
              <div className="progress">
                <div className={totalScore < 100 || totalScore > 100 ? "progress-bar bg-danger" : "progress-bar"} style={{ width: totalScore + "%" }}>
                  {totalScore}%
                </div>
              </div>
              <div className="small text-danger text-opacity-50 mb-2">Make sure the total score is 100%</div>
              <label className="form-check-label" htmlFor="scoreMc">
                Multiple Choice Score:{values.scoreMc}%
              </label>
              <input type="range" onChange={(e) => handleChangeSoalMc(parseInt(e.target.value))} disabled={!qtyMc ? true : false} className="form-range" defaultValue={values.scoreMc} id="scoreMc" />
              <label className="form-check-label" htmlFor="scoreTf">
                True False Score:{values.scoreTf}%
              </label>
              <input type="range" onChange={(e) => handleChangeSoalTf(parseInt(e.target.value))} disabled={!qtyTf ? true : false} className="form-range" defaultValue={values.scoreTf} id="scoreTf" />
              <label className="form-check-label" htmlFor="scoreMatch">
                Matching Score:{values.scoreMatch}%
              </label>
              <input type="range" onChange={(e) => handleChangeSoalMatch(parseInt(e.target.value))} disabled={!qtyMatch ? true : false} className="form-range" defaultValue={values.scoreMatch} id="scoreMatch" />
            </div>
          )}
          <hr />
          <label className="form-check-label" htmlFor="limitMcid">
            Limit Questions (Multiple Choice) : {values.limitMc}
          </label>
          <input type="range" className="form-range" onChange={(e) => dispatch({ type: "limitMc", value: e.target.value })} max={maxLimMc} defaultValue={maxLimMc} id="limitMcid" />
          <label className="form-check-label" htmlFor="limitTf">
            Limit Questions (True False) : {values.limitTf}
          </label>
          <input type="range" className="form-range" onChange={(e) => dispatch({ type: "limitTf", value: e.target.value })} max={maxLimTf} defaultValue={maxLimTf} id="limitTf" />
          <hr />
          <div className="mb-2">
            <label className="form-check-label text-theme" htmlFor="balanced">
              This Event Valid Until:
            </label>
          </div>
          <label className="form-check-label" htmlFor="limitMcid">
            Hour : {values.validHour} h
          </label>
          <input type="range" className="form-range" onChange={(e) => dispatch({ type: "validHour", value: parseInt(e.target.value) })} max={4} defaultValue={0} id="limitMcid" />
          <label className="form-check-label" htmlFor="limitTf">
            Minutes : {values.validMinutes} m
          </label>

          <input type="range" className="form-range" onChange={(e) => dispatch({ type: "validMinutes", value: parseInt(e.target.value) })} min={0} max={60} defaultValue={15} id="limitTf" />
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={() => submitClick(values)} disabled={disabled} className="btn btn-outline-theme">
            Create
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalGenerateEvent;
