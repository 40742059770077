import { useEffect, useReducer, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";

function reducer(state, action) {
  switch (action.type) {
    case "kode":
      return { ...state, kode: action.value };
    case "judul":
      return { ...state, judul: action.value };
    case "durasi":
      return { ...state, durasi: action.value };
    case "tanggal":
      return { ...state, tanggal: action.value };
    case "tanggalAssessment":
      return { ...state, tanggalAssessment: action.value };
    case "lokasi":
      return { ...state, lokasi: action.value };
    case "instructor":
      return { ...state, instructor: action.value };
  }
}

let initialValue = {
  kode: "",
  judul: "",
  durasi: "",
  tanggal: "",
  tanggalAssessment: "",
  lokasi: "",
  instructor: "",
};

function ModalAmiReport({ show, onHide, submitClick, data }) {
  const [values, dispatch] = useReducer(reducer, initialValue);
  const [initval, setInitVal] = useState(initialValue);
  function handleOnshow() {
    dispatch({ type: "tanggal", value: data.tanggal });
    dispatch({ type: "durasi", value: "3 Jam" });
    dispatch({ type: "tanggalAssessment", value: data.tanggal });
    dispatch({ type: "lokasi", value: data.lokasi });
    dispatch({ type: "instructor", value: data.instructor });
  }

  return (
    <>
      <Modal show={show} onShow={() => handleOnshow()} onHide={() => onHide()}>
        <ModalHeader closeButton>
          <h5 className="modal-title">Will anything be changed?</h5>
        </ModalHeader>
        <ModalBody>
          <div className="row mb-3">
            <label className="col-sm-2 col-form-label" htmlFor="kode">
              Kode
            </label>
            <div className="col-sm-10">
              <input onChange={(e) => dispatch({ type: "kode", value: e.target.value })} type="text" className="form-control" id="kode" />
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-2 col-form-label" htmlFor="judul">
              Judul
            </label>
            <div className="col-sm-10">
              <input onChange={(e) => dispatch({ type: "judul", value: e.target.value })} type="text" className="form-control" id="judul" />
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-2 col-form-label" htmlFor="durasi">
              Durasi
            </label>
            <div className="col-sm-10">
              <input value={values.durasi} onChange={(e) => dispatch({ type: "durasi", value: e.target.value })} type="text" className="form-control" id="durasi" />
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-2 col-form-label" htmlFor="tanggal">
              Tanggal
            </label>
            <div className="col-sm-10">
              <input value={values.tanggal} onChange={(e) => dispatch({ type: "tanggal", value: e.target.value })} type="date" className="form-control" id="tanggal" />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-2 col-form-label" htmlFor="tanggalAss">
              Tanggal Ass.
            </label>
            <div className="col-sm-10">
              <input value={values.tanggalAssessment} onChange={(e) => dispatch({ type: "tanggalAssessment", value: e.target.value })} type="date" className="form-control" id="tanggalAss" />
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-2 col-form-label" htmlFor="lokasi">
              Lokasi
            </label>
            <div className="col-sm-10">
              <input value={values.lokasi} onChange={(e) => dispatch({ type: "lokasi", value: e.target.value })} type="text" className="form-control" id="lokasi" />
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-2 col-form-label" htmlFor="Instructor">
              Instructor
            </label>
            <div className="col-sm-10">
              <input value={values.instructor} onChange={(e) => dispatch({ type: "instructor", value: e.target.value })} type="text" className="form-control" id="Instructor" />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button onClick={() => submitClick(values)} type="button" className="btn btn-outline-theme">
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalAmiReport;
