import Chart from 'react-apexcharts';

function getServerChartOptions(a) {
    var borderColor = (getComputedStyle(document.body).getPropertyValue('--bs-border-color')).trim();
    var bodyColor = (getComputedStyle(document.body).getPropertyValue('--bs-body-color')).trim();
    var inverseRgb = (getComputedStyle(document.body).getPropertyValue('--bs-inverse-rgb')).trim();
    var themeColor = (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim();
  var themeFont = (getComputedStyle(document.body).getPropertyValue('--bs-body-font-family')).trim();
      
    return {

      chart: { toolbar: { show: false },zoom:{enabled:false} },

         plotOptions: { bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded' } },
      dataLabels: { enabled: false },
          grid: { show: true, borderColor: borderColor },
          stroke: { show: false },
      colors: ['rgba('+ inverseRgb +', .15)', themeColor],
          legend: { fontFamily: themeFont, labels: { colors: bodyColor } },
          xaxis: {
              categories: a,
              labels: { show: false },
              axisBorder: { show: true, color: borderColor, height: 1, width: '100%', 	offsetX: 0, offsetY: -1 },
              axisTicks: { show: true, borderType: 'solid', color: borderColor, height: 6, offsetX: 0, offsetY: 0 },
          },
          yaxis: { 
              labels: { style: { colors: bodyColor, fontSize: '12px', fontFamily: themeFont, fontWeight: 400, cssClass: 'apexcharts-xaxis-label' } }
          },
          fill: { opacity: .65 },
          tooltip: { y: { formatter: function (val) { return val + " %" } } }
    };
}

function ApexChart({type,height,xLable,series}){

    return (
    
        <Chart type={type} height={height} options={ getServerChartOptions(xLable)} series={series} />
    )
}

export default ApexChart