import { useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import axiosInstance from "../../config/axios";
import { fileToBase64 } from "file64";

function ModalEditImageMatch2({ show, onHide, submitClick, id }) {
  const [img64, setImg64] = useState({ edit: false, base64: null });
  const [imgSrc, setImgSrc] = useState("");
  const [submitBtn, setSubmitBtn] = useState(false);
  const inputFile = useRef(null);
  function handleClickUploadFile() {
    inputFile.current.click();
  }
  function handleOnShow() {
    setImgSrc("");
    setSubmitBtn(false);
    setImg64({ edit: false, base64: null });
  }

  function handleSubmit() {
    const data = {
      id: id,
      file: img64,
    };

    submitClick(data);
  }

  async function handleChangeImage(e) {
    setSubmitBtn(true);
    const file = e.target.files[0];
    setImgSrc(URL.createObjectURL(file));
    const base64 = await fileToBase64(file);
    setImg64({
      edit: true,
      base64: base64,
    });
  }

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onShow={() => handleOnShow()}
        onHide={() => {
          onHide();
        }}
      >
        <ModalHeader closeButton>
          <h5 className="modal-title">Change Image</h5>
        </ModalHeader>
        <ModalBody>
          <div className="form-group mb-3">
            {imgSrc ? <img className="img-thumbnail" src={imgSrc}></img> : ""}
            <input ref={inputFile} onChange={(e) => handleChangeImage(e)} className="form-control" type="file" hidden />
            <div className="mt-sm-0 mb-3 mt-2">
              <a href="#/" onClick={() => handleClickUploadFile()} className="text-inverse me-3 text-opacity-75 text-decoration-none">
                <i className="fa fa-upload fa-fw text-theme"></i> image
              </a>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={() => handleSubmit()} className={submitBtn ? "btn btn-outline-theme" : "btn btn-outline-theme disabled"}>
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalEditImageMatch2;
