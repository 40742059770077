import { useEffect, useState, useReducer } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Select from "react-select";
import axiosInstance from "../../config/axios";
import { Card, CardBody } from "./../../components/card/card.jsx";
import ModalConfirm from "../../components/modal/modalConfirm.jsx";

function reducer(state, action) {
  switch (action.type) {
    case "trainingTitle": {
      return { ...state, trainingTitle: action.value };
    }
    case "location": {
      return { ...state, location: action.value };
    }
    case "institution": {
      return { ...state, institution: action.value };
    }
    case "instructor": {
      return { ...state, instructor: action.value };
    }
    case "startDate": {
      return { ...state, startDate: action.value };
    }
    case "endDate": {
      return { ...state, endDate: action.value };
    }
    case "remark": {
      return { ...state, remark: action.value };
    }
  }
}
const initialValue = {
  trainingTitle: "",
  location: "",
  institution: "",
  instructor: "",
  startDate: "",
  endDate: "",
  remark: "",
};
function ReportEvent() {
  const [id, submitedEvent, setSubmitedEvent, setId, setWizardData] = useOutletContext();
  const [eventData, eventDataDispatch] = useReducer(reducer, initialValue);
  const [listTrainingTitle, setListTrainingTitle] = useState([]);
  const [listLocation, setListLocation] = useState([]);
  const [listInstitution, setListInstitution] = useState([]);
  const [listInstructor, setListInstructor] = useState([]);
  const [modalDuplicateEvent, setModalDuplicateEvent] = useState({ show: false, title: "blank", text: "blank" });
  const [nextBtn, setNextBtn] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();

  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  function resetForm() {
    eventDataDispatch({ type: "trainingTitle", value: "" });
    eventDataDispatch({ type: "location", value: "" });
    eventDataDispatch({ type: "institution", value: "" });
    eventDataDispatch({ type: "instructor", value: "" });
    eventDataDispatch({ type: "startDate", value: "" });
    eventDataDispatch({ type: "endDate", value: "" });
    eventDataDispatch({ type: "remark", value: "" });
  }

  function eventSubmit() {
    setSpinner(true);
    axiosInstance
      .post("/trainingSubmit/newEventData", eventData, getHeader())
      .then((res) => (res.data.status ? success(res.data) : failed(res.data)))
      .catch((e) => {});

    function success(data) {
      setId(data.data.id);
      setSubmitedEvent(data.data);
      navigate("/training/report/participant");
    }
    function failed(data) {
      setSpinner(false);
      setModalDuplicateEvent({ show: true, title: "Warning", text: "This event already exist" });
    }
  }

  useEffect(() => {
    eventData.trainingTitle == "" || eventData.location == "" || eventData.institution == "" || eventData.instructor == "" || eventData.startDate == "" || eventData.endDate == "" ? setNextBtn(false) : setNextBtn(true);
  }, [eventData]);
  useEffect(() => {
    resetForm();
    setWizardData([
      { label: "Event", status: "active" },
      { label: "Participant", status: "disabled" },
      { label: "Document", status: "disabled" },
      { label: "Media", status: "disabled" },
    ]);
    axiosInstance
      .get("/optionselector/trainingTitle", getHeader())
      .then((e) => setListTrainingTitle(e.data))
      .catch((e) => {});
    axiosInstance
      .get("/optionselector/location", getHeader())
      .then((e) => setListLocation(e.data))
      .catch((e) => {});
    axiosInstance
      .get("/optionselector/institution", getHeader())
      .then((e) => setListInstitution(e.data))
      .catch((e) => {});
    axiosInstance
      .get("/optionselector/instructor", getHeader())
      .then((e) => setListInstructor(e.data))
      .catch((e) => {});
  }, []);
  return (
    <>
      <div className="d-md-flex align-items-center">
        <div className="me-md-auto text-md-left text-center mb-2 mb-md-0"></div>
        <div className="pagination mb-0 justify-content-center">
          {spinner ? (
            <div className="spinner-border"></div>
          ) : (
            <span onClick={() => eventSubmit()} className={nextBtn ? "btn btn-outline-theme me-2 mb-1" : "btn btn-outline-theme me-2 mb-1 disabled"}>
              <span>Next</span>
              <i className="fas fa-angle-double-right"></i>
            </span>
          )}
        </div>
      </div>
      <hr className="mb-4" />

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="row">
              <div className="col-xl-12">
                <Card>
                  <CardBody>
                    <div className="form-group mb-3">
                      <label className="form-label text-theme" htmlFor="title">
                        Training Title<span className="text-danger">*</span>
                      </label>
                      <Select id="title" options={listTrainingTitle} onChange={(e) => eventDataDispatch({ type: "trainingTitle", value: e.value })} classNamePrefix="react-select" />
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label text-theme" htmlFor="location">
                        Location<span className="text-danger">*</span>
                      </label>
                      <Select id="location" options={listLocation} onChange={(e) => eventDataDispatch({ type: "location", value: e.value })} classNamePrefix="react-select" />
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label text-theme" htmlFor="institution">
                        Institution<span className="text-danger">*</span>
                      </label>
                      <Select id="institution" options={listInstitution} onChange={(e) => eventDataDispatch({ type: "institution", value: e.value })} classNamePrefix="react-select" />
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label text-theme" htmlFor="instructor">
                        Instructor<span className="text-danger">*</span>
                      </label>
                      <Select id="instructor" options={listInstructor} onChange={(e) => eventDataDispatch({ type: "instructor", value: e.value })} classNamePrefix="react-select" />
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label text-theme" htmlFor="startDate">
                        Start Date<span className="text-danger">*</span>
                      </label>
                      <input type="date" onChange={(e) => eventDataDispatch({ type: "startDate", value: e.target.value })} className="form-control" id="startDate" />
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label text-theme" htmlFor="endDate">
                        End Date<span className="text-danger">*</span>
                      </label>
                      <input type="date" onChange={(e) => eventDataDispatch({ type: "endDate", value: e.target.value })} className="form-control" id="endDate" />
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label text-theme" htmlFor="remark">
                        Remark
                      </label>
                      <textarea onChange={(e) => eventDataDispatch({ type: "remark", value: e.target.value })} className="form-control" id="remark"></textarea>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr className="mb-4" />
      <div className="d-md-flex align-items-center">
        <div className="me-md-auto text-md-left text-center mb-2 mb-md-0"></div>
        <div className="pagination mb-0 justify-content-center">
          {spinner ? (
            <div className="spinner-border"></div>
          ) : (
            <span onClick={() => eventSubmit()} className={nextBtn ? "btn btn-outline-theme me-2 mb-1" : "btn btn-outline-theme me-2 mb-1 disabled"}>
              <span>Next</span>
              <i className="fas fa-angle-double-right"></i>
            </span>
          )}
        </div>
      </div>
      <ModalConfirm
        clicked={() => setModalDuplicateEvent({ ...modalDuplicateEvent, show: false })}
        theme={"danger"}
        show={modalDuplicateEvent.show}
        title={modalDuplicateEvent.title}
        text={modalDuplicateEvent.text}
        onHide={() => setModalDuplicateEvent({ ...modalDuplicateEvent, show: false })}
      />
    </>
  );
}

export default ReportEvent;
