

function BadgeSuccess({text}){
    return (
        <>
        <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"><i className="fa fa-circle fs-9px fa-fw me-5px"></i> {text}</span>
        </>
    )
}

export default BadgeSuccess