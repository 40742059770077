const config = {
  // mainURL: "http://127.0.0.1:8000", //dev
  mainURL: "https://singspro.ppdmaco.com", //prod

  baseURL: "https://singspro.ppdmaco.com/api", //dev

  // apkUrl: "http://localhost:3000/", //dev
  apkUrl: "https://ppdmaco.com/",
};

export default config;
